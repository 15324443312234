import React, { useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ButtonTooltip, DefaultModal, Icon, LongCard, DatatableEmployeeName } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { HRVacationsRequest } from '../../requests/dashboards';
import VacationDataTable from '../Shared/Vacation/VacationDataTable';
import columns from '../Shared/Vacation/approveColumns';
import availableVacationColumns from '../Shared/Vacation/availableVacationColumns';
import VacationAvailableDataTable from '../Shared/Vacation/VacationAvailableDataTable';

const VacationIndex = ({ moreData, setMoreData, todayMoment = null, twoMonthsAgo = null }) => {
  const [cards, setCards] = useState({});
  const [customParams, setCustomParams] = useState({ vacationsAvailableType: 'legal' });
  const [activeCard, setActiveCard] = useState('totalLegalVacationsAvailable');
  const [defaultStartDate, setDefaultStartDate] = useState(twoMonthsAgo);
  const [defaultEndDate, setDefaultEndDate] = useState(todayMoment);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchDashboardCards = () => {
    const request = async () => {
      HRVacationsRequest({
        dispatch,
        successCallback: response => {
          setCards(camelCaseEmptyStringRecursive(response.data));
        }
      });
    };
    request();
  };

  const handleClick = card => {
    switch (card) {
      case 'pending':
        setCustomParams({ status: 'pending' });
        setActiveCard('pending');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        break;
      case 'totalProgressiveVacationsAvailable':
        setCustomParams({ vacationsAvailableType: 'progressive' });
        setActiveCard('totalProgressiveVacationsAvailable');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        break;
      case 'rejected':
        setCustomParams({ status: 'rejected' });
        setActiveCard('rejected');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        break;
      case 'onVacation':
        setCustomParams({ status: 'approved' });
        setActiveCard('onVacation');
        setDefaultStartDate(moment().startOf('day'));
        setDefaultEndDate(moment().endOf('day'));
        break;
      case 'differencePreviousYear':
        setCustomParams({ status: 'approved' });
        setActiveCard('differencePreviousYear');
        setDefaultStartDate(moment().startOf('year'));
        setDefaultEndDate(moment().endOf('day'));
        break;
      default:
        setCustomParams({ vacationsAvailableType: 'legal' });
        setActiveCard('totalLegalVacationsAvailable');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        break;
    }
    setMoreData(!moreData);
  };

  const redirectToEmployee = (employee, tab) => {
    history.push(`/employees/${employee.id}${tab ? `?tab=${tab}` : ''}`);
  };

  const openModal = tab => {
    if (cards.twoPeriodsAcumulated) {
      const tableBody =
        cards.twoPeriodsAcumulated.amount > 0 ? (
          cards.twoPeriodsAcumulated.employees.map((body, index) => (
            <tr key={`employee-${index.toString()}`}>
              <td className="employee-name">
                <DatatableEmployeeName
                  item={body}
                  fileName="fileInfo"
                  name="fullName"
                  fileUrl="fileUrl"
                  onClick={() => redirectToEmployee(body, tab)}
                />
              </td>
              <td>
                <ButtonTooltip
                  onClick={() => redirectToEmployee(body, tab)}
                  variant="circle-primary"
                  className="btn-circle"
                  size="sm"
                  text="Ver"
                >
                  <Icon icon="eye" />
                </ButtonTooltip>
              </td>
            </tr>
          ))
        ) : (
          <p>No se encontraron trabajadores</p>
        );
      const table = (
        <Table responsive>
          <tbody>{tableBody}</tbody>
        </Table>
      );
      setModalBody(table);
      setModalShow(true);
    }
  };

  useEffect(fetchDashboardCards, [moreData]);

  return (
    <>
      <Row className="mt-4 mb-3">
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.totalLegalVacationsAvailable}
            label="Días de vacaciones legales acumulados"
            active={activeCard === 'totalLegalVacationsAvailable'}
            onClick={() => handleClick('totalLegalVacationsAvailable')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="warning"
            title={cards.pending}
            label="Días por autorizar"
            active={activeCard === 'pending'}
            onClick={() => handleClick('pending')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="danger"
            title={cards.rejected}
            label="Días Rechazados"
            active={activeCard === 'rejected'}
            onClick={() => handleClick('rejected')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="primary"
            title={cards.totalProgressiveVacationsAvailable}
            label="Días de vacaciones progresivas acumuladas"
            active={activeCard === 'totalProgressiveVacationsAvailable'}
            onClick={() => handleClick('totalProgressiveVacationsAvailable')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.employeesOnVacation}
            label="Trabajadores en vacaciones"
            active={activeCard === 'onVacation'}
            onClick={() => handleClick('onVacation')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.daysDifferencePreviousYear ? `${cards.daysDifferencePreviousYear}%` : '-'}
            label="Días tomados a la fecha respecto al año pasado"
            active={activeCard === 'differencePreviousYear'}
            onClick={() => handleClick('differencePreviousYear')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="danger"
            title={cards.twoPeriodsAcumulated?.amount}
            label="Trabajadores con más de dos periodos acumulados"
            onClick={() => openModal('vacations')}
          />
        </Col>
      </Row>
      {'totalLegalVacationsAvailable totalProgressiveVacationsAvailable'.includes(activeCard) ? (
        <VacationAvailableDataTable
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          columns={availableVacationColumns}
          moreData={moreData}
          setMoreData={setMoreData}
          withImport
          customParams={{ ...customParams, filter_vacation_exception: 'administrative_days' }}
        />
      ) : (
        <VacationDataTable
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          columns={columns}
          moreData={moreData}
          withImport
          setMoreData={setMoreData}
          customParams={{
            ...customParams,
            filter_vacation_exception: 'administrative_days, administrative_days_openning_balance'
          }}
        />
      )}
      <DefaultModal
        body={modalBody}
        handleClose={() => setModalShow(false)}
        show={modalShow}
        modalSize="lg"
        title="Trabajadores con más de dos periodos acumulados"
        withConfirm={false}
      />
    </>
  );
};

export default VacationIndex;
