import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import ShiftAssignmentsForm from './ShiftAssignmentsForm';
import { sendAlert } from '../../actions/utils';
import { createMassiveShiftAssignmentsRequest } from '../../requests/shiftAssignment';
import { Icon, LinkBtn } from '../../components';

const basicShiftAssignment = () => ({
  assignedShiftId: '',
  changeInCard: false,
  contractIds: [],
  contracts: [],
  createdById: '',
  currentShiftId: '',
  date: '',
  observation: '',
  shiftEnd: '',
  shiftStart: ''
});

const ShiftAssignmentsNew = () => {
  const [shiftAssignment, setShiftAssignment] = useState(basicShiftAssignment);
  const dispatch = useDispatch();
  const history = useHistory();

  const validateLength = shiftAssignmentValues => {
    if (!shiftAssignmentValues.contractIds.length) {
      dispatch(sendAlert({ kind: 'error', message: 'Debes seleccionar al menos un trabajador' }));
      return true;
    }
    return false;
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
  };

  const handleSuccessCreate = () => {
    const message = 'Turnos asignados con éxito';
    history.push(`/shift_assignments`);
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const handleCreate = shiftAssignmentValues => {
    if (validateLength(shiftAssignmentValues.shiftAssignment)) {
      return;
    }
    const params = snakecaseKeys(shiftAssignmentValues);
    createMassiveShiftAssignmentsRequest({
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: error => {
        handleFailureRequest(error);
      }
    });
  };

  useEffect(() => {
    setShiftAssignment(basicShiftAssignment);
  }, [setShiftAssignment]);

  return (
    <>
      <Row className="m-top mb-2">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle" block to="/shift_assignments">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="m-top mb-3 mt-0">Asignación de Turno</h2>
        </Col>
      </Row>
      <ShiftAssignmentsForm shiftAssignment={shiftAssignment} formRequest={handleCreate} />
    </>
  );
};
export default ShiftAssignmentsNew;
