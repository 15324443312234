import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useAbility } from '@casl/react';
import { ComponentDataTable, DefaultModal, LinkBtn } from '../../components';
import { useAuthorization } from '../../services/hooks';
import ShiftAssignmentFilters from './ShiftAssignmentsFilters';
import {
  debounceIndexShiftsAssignmentRequest,
  deleteShiftAssignmentRequest,
  exportShiftAssignmentsRequest,
  massiveDestroyShiftsAssignmentsRequest
} from '../../requests/shiftAssignment';
import columns from './ShiftAssignmentsColumns';
import { AbilityContext } from '../../config/abilityContext';
import { sendAlert } from '../../actions/utils';
import ShiftAssignmentsMassiveActions from './ShiftAssignmentsMassiveActions';
import { downloadFile } from '../../services/utils';

const ShiftAssignmentsIndex = () => {
  const ability = useAbility(AbilityContext);
  const { currentCompany } = useSelector(state => state.auth);
  const currentCompanyId = currentCompany.id;
  const [paramsFilters, setParamsFilters] = useState(null);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [shiftAssignments, setShiftAssignments] = useState([]);
  const [amount, setAmount] = useState(0);
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalAction, setModalAction] = useState(() => null);
  const [selectedCount, setSelectedCount] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const dispatch = useDispatch();

  const sortColumnCase = name => {
    switch (name) {
      case 'employee_rut':
        return { sort_employee_rut: name };
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      case 'current_shift_name':
        return { sort_current_shift_name: name };
      case 'assigned_shift_name':
        return { sort_assigned_shift_name: name };
      default:
        return { sort_column: name };
    }
  };

  const handleIndexRequest = myParams => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexShiftsAssignmentRequest({
        dispatch,
        params: {
          display_length: 15,
          filter_by_company: currentCompanyId,
          ...myParams
        },
        successCallback: response => {
          const { data } = response.data;
          setOnRequest(false);
          setAmount(response.data.metadata.amount);
          setShiftAssignments(data);
        }
      });
    request();
  };

  const handleFilterAdvance = params => {
    setParamsFilters(params);
    setMoreData(!moreData);
  };

  const handleSuccessRemove = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Asignación de turno eliminada correctamente' }));
    setMoreData(!moreData);
    setDefaultModalShow(false);
    setOnRequest(false);
    setClearSelectedRows(!clearSelectedRows);
  };

  const removeShiftAssignment = item => {
    setOnRequest(true);
    deleteShiftAssignmentRequest(item.id, {
      dispatch,
      successCallback: handleSuccessRemove,
      callback: () => {
        setDefaultModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const handleMassiveFailureRequest = error => {
    const { response } = error;
    setDefaultModalShow(false);
    setOnRequest(false);
    setMoreData(!moreData);
    setClearSelectedRows(!clearSelectedRows);
    setSelectedRows([]);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const masiveDeleteShiftAssignment = () => {
    const shiftSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveDestroyShiftsAssignmentsRequest({
      params: { ids: shiftSelected },
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleMassiveFailureRequest,
      callback: () => {
        setDefaultModalShow(false);
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const massiveExportShiftAssignments = () => {
    const recordsSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    exportShiftAssignmentsRequest({
      params: {
        filter_ids: recordsSelected,
        ...paramsFilters
      },
      dispatch,
      successCallback: response => {
        setDefaultModalShow(false);
        downloadFile(response);
        setClearSelectedRows(!clearSelectedRows);
        setSelectedRows([]);
        setOnRequest(false);
      },
      failureCallback: handleMassiveFailureRequest,
      callback: () => {
        setDefaultModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleMassAction = action => {
    const notRemovedRegisters = selectedRows.filter(asItem => {
      const currentDate = new Date();
      const [day, month, year] = asItem.shift_start.split('/');
      const itemDate = new Date(year, month - 1, day);
      return itemDate < currentDate;
    });

    switch (action) {
      case 'massive_destroy':
        setModalTitle('Eliminar Asignación de Turnos');
        setDefaultModalShow(true);
        setModalBody(
          <>
            <p>¿Estás seguro que deseas eliminar las asignaciones de Turno?</p>
            <br />
            <p>Nota: solo se eliminarán las asignaciones de turno que no se hayan ejecutado</p>
            {notRemovedRegisters.length > 0 && (
              <>
                <br />
                <p>Las siguientes asignaciones de turno no se eliminarán:</p>
                <ul>
                  {notRemovedRegisters.map(item => (
                    <li key={item.id}>{item.full_name}</li>
                  ))}
                </ul>
              </>
            )}
          </>
        );
        setModalAction(() => masiveDeleteShiftAssignment);
        break;
      case 'export':
        setDefaultModalShow(true);
        setModalTitle('Exportar Asignación de Turnos');
        setModalBody('¿Estás seguro que quieres exportar las asignaciones de turnos en formato Excel?');
        setModalAction(() => massiveExportShiftAssignments);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'destroy':
        setModalTitle('Eliminar Asignación de Turnos');
        setDefaultModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar la asignación de turno?');
        setModalAction(() => removeShiftAssignment);
        setModalItem(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <Row className="mt-4 mb-5">
        <Col>
          <h2 className="m-top mb-3 mt-3">Asignación de Turno</h2>
        </Col>
        {useAuthorization('create', 'ShiftAssignment') && (
          <Col md={3}>
            <LinkBtn variant="primary" className="mt-2" block to="/shift_assignments/new">
              Nueva Asignación
            </LinkBtn>
          </Col>
        )}
      </Row>
      <ShiftAssignmentFilters formRequest={handleFilterAdvance} />
      {paramsFilters && (
        <Row>
          <Col md={12}>
            <ComponentDataTable
              clearSelectedRows={clearSelectedRows}
              columns={columns(handleActions, ability)}
              data={shiftAssignments}
              handleSortCase={sortColumnCase}
              massActions={
                <ShiftAssignmentsMassiveActions
                  model="ShiftAssignment"
                  handleClick={handleMassAction}
                  disabled={!selectedCount}
                />
              }
              moreData={moreData}
              onRequest={onRequest}
              onSelectedRowsChange={handleSelectedRows}
              preName="shift_assignments"
              resourceRequest={params => {
                handleIndexRequest({ ...params, ...paramsFilters });
              }}
              selectableRows
              totalRows={amount}
              withMassActions
            />

            <DefaultModal
              title={modalTitle}
              body={modalBody}
              show={defaultModalShow}
              handleClose={() => setDefaultModalShow(false)}
              handleConfirm={() => modalAction(modalItem)}
              disabled={onRequest}
              variantBtnClose="outline-info"
              variantBtnSave="primary"
            />
          </Col>
        </Row>
      )}
    </>
  );
};
export default ShiftAssignmentsIndex;
