import React, { useCallback, useEffect } from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { FormikSelect } from '../../../../components';
import { shortOvertimeFactors } from '../FormOptions';
import TimeInput from './TimeInput';

export default function TableBodyForm({ attendance, dateIndex, index }) {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const calculatedOvertimeFactor = getIn(
    values,
    `overtimeGroup[weekData][${dateIndex}][${index}][calculatedOvertime][factor]`
  );
  const overtimeHours = getIn(values, `overtimeGroup[weekData][${dateIndex}][${index}][calculatedOvertime][hours]`);
  const overtimeMinutes = getIn(values, `overtimeGroup[weekData][${dateIndex}][${index}][calculatedOvertime][minutes]`);

  const hoursLate = getIn(values, `overtimeGroup[weekData][${dateIndex}][${index}][hoursLate][parsedTime]`);
  const earlyDeparture = getIn(values, `overtimeGroup[weekData][${dateIndex}][${index}][earlyDeparture][parsedTime]`);
  const breakTimeExcess = getIn(values, `overtimeGroup[weekData][${dateIndex}][${index}][breakTimeExcess][parsedTime]`);

  const handleTimeTotal = useCallback(() => {
    const [hoursLateHours, hoursLateMinutes] = hoursLate.split(':');
    const [earlyDepartureHours, earlyDepartureMinutes] = earlyDeparture.split(':');
    const [breakTimeExcessHours, breakTimeExcessMinutes] = breakTimeExcess.split(':');

    const totalTime = {
      hours: Number(hoursLateHours) + Number(earlyDepartureHours) + Number(breakTimeExcessHours),
      minutes: Number(hoursLateMinutes) + Number(earlyDepartureMinutes) + Number(breakTimeExcessMinutes)
    };
    totalTime.hours += Math.floor(totalTime.minutes / 60);
    totalTime.minutes %= 60;
    setFieldValue(
      `overtimeGroup[weekData][${dateIndex}][${index}][totalNonWorked][hours]`,
      `${totalTime.hours.toString().padStart(2, '0')}`
    );
    setFieldValue(
      `overtimeGroup[weekData][${dateIndex}][${index}][totalNonWorked][minutes]`,
      `${totalTime.minutes.toString().padStart(2, '0')}`
    );
    setFieldValue(
      `overtimeGroup[weekData][${dateIndex}][${index}][totalNonWorked][parsedTime]`,
      `${totalTime.hours.toString().padStart(2, '0')}:${totalTime.minutes.toString().padStart(2, '0')}:00`
    );
  }, [hoursLate, earlyDeparture, breakTimeExcess, dateIndex, index, setFieldValue]);

  useEffect(handleTimeTotal, [hoursLate, earlyDeparture, breakTimeExcess]);

  return (
    <tr>
      <td className="text-center align-middle">{attendance.parsedDate}</td>
      <td className="text-center align-middle">
        <TimeInput name={`overtimeGroup[weekData][${dateIndex}][${index}][beginJourney]`} />
      </td>
      <td className="text-center align-middle">
        <TimeInput name={`overtimeGroup[weekData][${dateIndex}][${index}][endJourney]`} />
      </td>
      <td className="text-center align-middle">
        <TimeInput name={`overtimeGroup[weekData][${dateIndex}][${index}][calculatedOvertime]`} />
      </td>
      <td className="text-center align-middle">
        <Field name={`overtimeGroup[weekData][${dateIndex}][${index}][calculatedOvertime][factor]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              placeholder=""
              isDisabled={!overtimeHours && !overtimeMinutes}
              options={shortOvertimeFactors}
              defaultValue={calculatedOvertimeFactor}
              onChange={data => {
                setFieldValue(field.name, data ? data.value : '');
              }}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="mb-1"
            />
          )}
        </Field>
      </td>
      <td className="text-center align-middle">
        <TimeInput name={`overtimeGroup[weekData][${dateIndex}][${index}][hoursLate]`} />
      </td>
      <td className="text-center align-middle">
        <TimeInput name={`overtimeGroup[weekData][${dateIndex}][${index}][earlyDeparture]`} />
      </td>
      <td className="text-center align-middle">
        <TimeInput name={`overtimeGroup[weekData][${dateIndex}][${index}][breakTimeExcess]`} />
      </td>
      <td className="text-center align-middle">
        <TimeInput readOnlyInput disabled name={`overtimeGroup[weekData][${dateIndex}][${index}][totalNonWorked]`} />
      </td>
      <td className="text-center align-middle">
        <TimeInput readOnlyInput disabled name={`overtimeGroup[weekData][${dateIndex}][${index}][compensatedHours]`} />
      </td>
    </tr>
  );
}
