import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import memoize from 'memoize-one';
import { Button, Col, Row } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { debounceLegalreportWorkdayReport } from '../../../../requests/legalreports';
import { DataTableDT, DefaultModal, Icon, SimpleCenteredModal } from '../../..';
import { WorkdaysFilterDataTable } from '../../../LegalReport';
import { downloadFile } from '../../../../services/utils';
import WorkDayReportFilters from './Filters';
import WorkdayReportToPrint from '../../../../screens/Legalreports/PrintComponent/WorkdayReportToPrint';
import { sendAlert } from '../../../../actions/utils';
import ReportActions from '../../../../screens/Legalreports/ReportActions';
import './Style.scss';

const columns = memoize(() => [
  {
    name: 'Nombre',
    selector: 'name',
    cell: item => <>{item.full_name}</>,
    sortable: false,
    grow: '2'
  },
  {
    name: 'Cédula de identidad (RUT)',
    selector: 'national_identification',
    sortable: false,
    grow: '1'
  }
]);

export const WorkdayExpander = props => {
  const { data, setDocumentModalBody, setDocumentModalShow } = props;
  const { attendances, is_article_22: isArticle22, employee_id: employeeId } = data;

  return (
    <WorkdaysFilterDataTable
      attendances={attendances}
      isArticle22={isArticle22}
      employeeId={employeeId}
      setDocumentModalBody={setDocumentModalBody}
      setDocumentModalShow={setDocumentModalShow}
    />
  );
};

const WorkdayReportSupervisorDataTable = ({ rrhh }) => {
  const [amount, setAmount] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [dataPrint, setDataPrint] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [tile, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [parameters, setParameters] = useState([]);
  const [filters, setFilters] = useState();
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [documentModalBody, setDocumentModalBody] = useState('');
  const [dateRange, setDateRange] = useState([]);

  const dispatch = useDispatch();
  const componentRef = useRef();

  const printInfo = useReactToPrint({
    content: () => componentRef.current
  });

  const handleFailureRequest = error => {
    const message = error?.response?.data?.message || 'TRABAJADOR NO ENCONTRADO';
    dispatch(sendAlert({ kind: 'error', message }));
  };

  const handleRequest = (params, format = null, type = 'json') => {
    const responseType = params.type === '.docx' ? 'blob' : type;
    const responseFormat = format === '.print' ? '.json' : format;
    const customParams = {
      ...params
    };
    setOnRequest(true);
    debounceLegalreportWorkdayReport({
      dispatch,
      params: {
        display_start: 0,
        display_length: 15,
        ...customParams,
        sort_direction: 'asc',
        sort_column: 'name',
        is_dt: false,
        paginate: format === null
      },
      format: responseFormat,
      responseType,
      successCallback: response => {
        const { data } = response;
        if (format === '.xlsx' || format === '.docx' || format === '.pdf') {
          downloadFile(response);
        } else if (format === '.print') {
          setSubmitting(false);
          setDataPrint(data.data);
          printInfo();
        } else {
          setAmount(data.metadata.amount);
          setEmployees(data.data);
        }
      },
      failureCallback: handleFailureRequest,
      callback: () => {
        setOnRequest(false);
        setSubmitting(false);
      }
    });
  };

  const handleMassAction = action => {
    switch (action) {
      case 'download_excel':
        setTitle('Exportar Reporte de Jornada Diaria');
        setBody('¿Estás seguro que quieres exportar el reporte de jornada diaria en formato Excel?');
        setParameters([{ ...filters, type: '.xlsx' }, '.xlsx', 'text']);
        setModalShow(true);
        break;
      case 'download_docx':
        setTitle('Exportar Reporte de Jornada Diaria');
        setBody('¿Estás seguro que quieres exportar el reporte de jornada diaria en formato Word?');
        setParameters([{ ...filters, type: '.docx' }, '.docx', 'text']);

        setModalShow(true);
        break;
      case 'download_pdf':
        setTitle('Exportar Reporte de Jornada Diaria');
        setBody('¿Estás seguro que quieres exportar el reporte de jornada diaria en formato PDF?');
        setParameters([{ ...filters, type: '.pdf' }, '.pdf', 'text']);
        setModalShow(true);
        break;
      case 'print':
        setTitle('Imprimir Reporte de Jornada Diaria');
        setBody('¿Estás seguro que quieres imprimir el reporte de jornada diaria?');
        setParameters([{ ...filters, type: '.print' }, '.print', 'text']);
        setModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };
  const handleFilterAdvance = async params => {
    setFilters(params);
    setDateRange(params.range_date_workday);
    setSubmitting(true);
    switch (params.type) {
      case null:
        setSearchFirstStart(true);
        setMoreData(!moreData);
        setShowFilter(false);
        break;
      case '.docx':
        setTitle('Exportar Reporte de Jornada Diaria');
        setBody('¿Estás seguro que quieres exportar el reporte de jornada diaria en formato Word?');
        setParameters([params, '.docx', 'text']);
        setModalShow(true);
        break;
      case '.xlsx':
        setTitle('Exportar Reporte de Jornada Diaria');
        setBody('¿Estás seguro que quieres exportar el reporte de jornada diaria en formato Excel?');
        setParameters([params, '.xlsx', 'text']);
        setModalShow(true);
        break;
      case '.pdf':
        setTitle('Exportar Reporte de Jornada Diaria');
        setBody('¿Estás seguro que quieres exportar el reporte de jornada diaria en formato PDF?');
        setParameters([params, '.pdf', 'text']);
        setModalShow(true);
        break;
      case '.print':
        setTitle('Imprimir Reporte de Jornada Diaria');
        setBody('¿Estás seguro que quieres imprimir el reporte de jornada diaria?');
        setParameters([params, '.print', 'text']);
        setModalShow(true);
        break;
      default:
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'branch_office_address':
        return { sort_branch_offices: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <div className={showFilter ? '' : 'd-none'}>
        <WorkDayReportFilters filterRRHH={rrhh} formRequest={handleFilterAdvance} isSubmit={isSubmitting} />
      </div>
      <div className={showFilter ? 'd-none' : ''}>
        <Row>
          <Col md={6} className="mt-3">
            <Button variant="primary" onClick={() => setShowFilter(true)}>
              <Icon icon="chevron-back" width={15} />
              <span className="arial-dt">Volver al filtro</span>
            </Button>
          </Col>
        </Row>
        <DataTableDT
          onRequest={onRequest}
          columns={columns()}
          moreData={moreData}
          data={employees}
          responsive
          totalRows={amount}
          handleSortCase={sortColumnCase}
          withMassActions
          massActions={<ReportActions disabled={onRequest} handleClick={handleMassAction} dates={dateRange} />}
          resourceRequest={params => {
            handleRequest({ ...params, ...filters });
          }}
          expandableRows
          expandableRowExpanded={() => true}
          expandableRowsComponent={
            <WorkdayExpander setDocumentModalBody={setDocumentModalBody} setDocumentModalShow={setDocumentModalShow} />
          }
          searchFirstStart={searchFirstStart}
        />
      </div>
      <div style={{ display: 'none' }}>
        <WorkdayReportToPrint data={dataPrint} ref={componentRef} />
      </div>
      <DefaultModal
        title={tile}
        body={body}
        show={modalShow}
        handleClose={() => {
          setModalShow(false);
          setSubmitting(false);
        }}
        handleConfirm={() => {
          handleRequest(...parameters);
          setModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        body={documentModalBody}
        onHide={() => setDocumentModalShow(false)}
        show={documentModalShow}
        size="xl"
      />
    </>
  );
};

export default WorkdayReportSupervisorDataTable;
