import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Button } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import { ComponentDataTable } from '../../../../components';
import {
  debounceIndexAttendanceGroupsRequest,
  updateAttendanceGroupRequest
} from '../../../../requests/attendanceGroups';
import Columns from './Columns';
import MassiveActions from './MassiveActions';
import AttendanceMovementsExpander from './AttendanceMovementsExpander';
import { camelCaseEmptyStringRecursive, downloadFile } from '../../../../services/utils';
import { sendAlert } from '../../../../actions/utils';
import style from './attendanceMovements.module.scss';
import { exportAttendanceMovementsRequest } from '../../../../requests/attendanceMovements';

export default function AttendancesMovementDetail({
  employeeIds,
  setSimpleModalShow,
  payrollProcessId,
  setUpdateMainTable
}) {
  const [initialValues, setInitialValues] = useState({
    attendanceGroups: []
  });
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();

  const handleRequest = () => {
    setOnRequest(true);
    debounceIndexAttendanceGroupsRequest({
      dispatch,
      params: {
        payroll_process: payrollProcessId,
        filter_by_employee_id: employeeIds,
        sort_movements: 'start_date',
        sortByAttribute: 'asc',
        with_detail: true,
        paginate: false
      },
      successCallback: response => {
        setInitialValues({ attendanceGroups: camelCaseEmptyStringRecursive(response.data.data) });
        setAmount(response.data.metadata.amount);
      },
      callback: () => {
        setOnRequest(false);
      }
    });
  };

  const handleDownloadAttendanceDetails = format => {
    const formatParams = {
      word: '.docx',
      pdf: '.pdf',
      excel: '.xlsx'
    };
    const downloadFormat = formatParams[format];
    const attendanceGroupIds = initialValues.attendanceGroups.map(group => group.id);
    exportAttendanceMovementsRequest({
      dispatch,
      params: {
        attendance_group_ids: attendanceGroupIds,
        sort_employees: 'full_name',
        sort_direction: 'asc'
      },
      responseType: format === 'word' ? 'blob' : 'text',
      format: downloadFormat,
      successCallback: response => {
        downloadFile(response);
        setMoreData(oldValue => !oldValue);
      }
    });
  };

  const handleConfirm = values => {
    const newParams = snakecaseKeys(values);
    updateAttendanceGroupRequest({
      dispatch,
      params: {
        ...newParams
      },
      successCallback: () => {
        setSimpleModalShow(false);
        setUpdateMainTable(oldValue => !oldValue);
        dispatch(sendAlert({ message: 'Movimientos de asistencia guardados correctamente', kind: 'success' }));
      }
    });
  };
  /*
    useEffect(() => {
      handleRequest();
    }, [handleRequest])
   */
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleConfirm} enableReinitialize>
        {({ values }) => (
          <Form>
            <MassiveActions handleDownloadAttendanceDetails={handleDownloadAttendanceDetails} />
            <ComponentDataTable
              customSpinnerClass={`containerSpinnerLoad position-absolute h-100 w-100 ${style['custom-min--height']}`}
              customClassModule={style['custom-data-table']}
              onRequest={onRequest}
              columns={Columns()}
              data={values.attendanceGroups}
              totalRows={amount}
              moreData={moreData}
              resourceRequest={handleRequest}
              withSearch={false}
              expandableRows
              expandableRowExpanded={() => false}
              expandableRowsComponent={<AttendanceMovementsExpander setSimpleModalShow={setSimpleModalShow} />}
              pagination={false}
              paginationServer={false}
              customDivStyles={{ marginBottom: '-30px' }}
            />
            <div className="modal-footer">
              <Button className="btn" variant="outline-primary" onClick={() => setSimpleModalShow(false)}>
                Cancelar
              </Button>
              <Button type="submit" className="btn" variant="primary">
                Guardar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
