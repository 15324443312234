import React from 'react';
import { BasicDropdown, Icon } from '../../../components';

const AttendanceGroupMassiveActions = ({ handleClick, disabled, onlyShow }) => {
  const actions = () => {
    const authorizedActions = [];
    if (!onlyShow) {
      authorizedActions.push(
        {
          icon: <Icon icon="pencil" className="text-warning" height="16px" width="16px" style={{ marginRight: 5 }} />,
          key: 'edit',
          props: {
            onClick: () => handleClick('edit'),
            disabled
          },
          title: 'Editar'
        },
        {
          icon: <Icon icon="refresh" height="16px" width="16px" style={{ marginRight: 5 }} />,
          key: 'refresh',
          props: {
            onClick: () => handleClick('process'),
            disabled
          },
          title: 'Procesar'
        },
        {
          key: 'destroy',
          title: `Eliminar`,
          icon: <Icon icon="trash" height="16px" color="red" width="16px" style={{ marginRight: 5 }} />,
          props: {
            onClick: () => handleClick('destroy'),
            disabled
          }
        }
      );
    }
    authorizedActions.push({
      key: 'download',
      title: `Descargar`,
      icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => handleClick('export')
      }
    });

    return authorizedActions;
  };

  return (
    <div className="d-flex mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default AttendanceGroupMassiveActions;
