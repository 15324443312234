import { Field } from 'formik';
import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { FormikCheckBox, FormikNumber } from '../../../components';

const Colation = ({ currentTab, disabled, errors, onChangeTab, setFieldValue, touched, values }) => {
  const { agreedSchedule, collationTime, flexibleShift } = values.shift;

  return (
    <Card className="overflow-visible">
      <Col className={`${currentTab === 'colation' ? 'top-header-green' : 'top-header-light-gray'}`}>
        <Accordion.Toggle
          as={Col}
          eventKey="2"
          className="card-header-title-dt"
          onClick={() => onChangeTab('colation')}
        >
          Colación
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="2">
        <Card.Body className="div-content">
          <Row>
            <Col xs={12} md={3} className="d-flex align-items-center">
              <Field name="shift[imputableColation]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    custom
                    field={field}
                    label="Colación Imputable Jornada"
                    tooltipSize="23"
                    disabled={disabled}
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={3} className="d-flex align-items-center">
              <Field name="shift[lunchCheck]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    custom
                    field={field}
                    label="Marcar colación"
                    tooltipSize="23"
                    tooltipText="Indica si deben marcar el ingreso y salida de colación"
                    disabled={disabled}
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={3} className="d-flex align-items-center">
              <Field name="shift[agreedSchedule]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    custom
                    field={field}
                    label="Colación Horario pactado"
                    tooltipSize="23"
                    tooltipText="Indica que deben marcar el ingreso y salida de colación en cada uno de los días seleccionados"
                    disabled={collationTime || flexibleShift || disabled}
                    onClick={() => {
                      setFieldValue(field.name, field.value === 'true' || field.value === true);
                      setFieldValue('shift[lunchTime]', 0);
                    }}
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={3} className="d-flex align-items-center">
              <Field name="shift[collationTime]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    custom
                    field={field}
                    label="Colación Tiempo Pactado"
                    tooltipSize="23"
                    tooltipText="Indica si deben marcar el ingreso y salida de colación"
                    disabled={agreedSchedule || disabled}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            {collationTime && (
              <Col xs={6} md={2} className="d-flex align-items-center">
                <Field name="shift[parsedLunchTime]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      abbr
                      rightAddon="min"
                      placeholder="0"
                      label="Colación"
                      fieldName="shift[lunchTime]"
                      value={values.shift.lunchTime}
                      tooltipSize="21"
                      tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled={disabled}
                    />
                  )}
                </Field>
              </Col>
            )}
            {(errors?.shift?.agreedSchedule || errors?.shift?.collationTime) && (
              <p className="text-danger">
                Debes seleccionar una de las opciones de marcado (Colación Horario pactado o Colación Tiempo Pactado)
              </p>
            )}
            <hr />
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
export default Colation;
