import { Field, useFormikContext } from 'formik';
import React from 'react';

import InputMask from '../../../../components/Utils/Input/InputMask';

const TimeInput = ({ name, disabled, readOnlyInput }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Field name={`${name}[parsedTime]`} className="mb-0">
      {({ field }) => (
        <>
          <InputMask
            {...field}
            margin="mb-0"
            mask="99:99"
            readOnlyInput={readOnlyInput}
            disabled={disabled}
            pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
            onChange={event => {
              if (readOnlyInput) return;
              const { value } = event.target;
              const hours = value.split(':')[0];
              const minutes = value.split(':')[1];
              setFieldValue(`${name}[hours]`, +hours);
              setFieldValue(`${name}[minutes]`, +minutes);
              setFieldValue(field.name, `${hours}:${minutes}`);
            }}
            value={field.value}
          />
        </>
      )}
    </Field>
  );
};

export default TimeInput;
