import React, { useState } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import ShiftDataTable from './ShiftDataTable';
import { useAuthorization } from '../../services/hooks';
import { exportShiftRequest } from '../../requests/shifts';
import { Icon, DefaultModal } from '../../components';
import './style.scss';
import { downloadFile } from '../../services/utils';

const ShiftIndex = () => {
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalBody, setDefaultModalBody] = useState({});
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [modalAction, setModalAction] = useState(() => function empty() {});
  const [params, setParams] = useState({});
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setDefaultModalShow(false);
  };

  const handleSuccessExport = response => {
    setDefaultModalShow(false);
    downloadFile(response);
  };

  const handleDownloadShift = shiftParams => {
    exportShiftRequest({
      dispatch,
      params: shiftParams,
      successCallback: handleSuccessExport,
      failureCallback: handleFailureRequest
    });
  };

  const handleExport = shiftType => {
    switch (shiftType) {
      case 'monthly':
        setDefaultModalTitle('Exportar turnos mensuales');
        setDefaultModalBody('¿Estás seguro que deseas descargar los Turnos Mensuales?');
        setParams({ shift_type: 'monthly' });
        break;
      case 'rotative':
        setDefaultModalTitle('Exportar turnos rotativos');
        setDefaultModalBody('¿Estás seguro que deseas descargar los Turnos Rotativos?');
        setParams({ shift_type: 'rotative' });
        break;
      default:
        setDefaultModalTitle('Exportar turnos semanales');
        setDefaultModalBody('¿Estás seguro que deseas descargar los Turnos Semanales');
        setParams({ shift_type: 'weekly' });
    }
    setDefaultModalShow(true);
    setModalAction(() => handleDownloadShift);
  };

  return (
    <>
      <Row className="mt-4 mb-5">
        <Col>
          <h2 className="m-top mb-3 mt-3">Turnos</h2>
        </Col>
        {useAuthorization('create', 'Shift') && (
          <>
            <Col md={2}>
              <Dropdown alignRight className="shift-dropdown z-index-1">
                <Dropdown.Toggle className="dropdown-no-arrow mt-3 w-100">
                  Exportar
                  <Icon style={{ marginLeft: 25 }} width={23} icon="chevron-down" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleExport('weekly')}>Semanal</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleExport('monthly')}>Mensual</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleExport('rotative')}>Rotativo</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={2}>
              <Dropdown alignRight className="shift-dropdown z-index-1">
                <Dropdown.Toggle className="dropdown-no-arrow mt-3 w-100">
                  Nuevo
                  <Icon style={{ marginLeft: 25 }} width={23} icon="chevron-down" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/shifts/new">
                    {' '}
                    Semanal{' '}
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/shifts/new?shiftType=monthly">
                    {' '}
                    Mensual{' '}
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/shifts/new?shiftType=rotative">
                    {' '}
                    Rotativo{' '}
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/shifts/new?shiftType=hours">
                    {' '}
                    Por Horas{' '}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </>
        )}
      </Row>
      <ShiftDataTable moreData={moreData} setMoreData={setMoreData} />
      <DefaultModal
        title={defaultModalTitle}
        body={defaultModalBody}
        show={defaultModalShow}
        handleClose={() => setDefaultModalShow(false)}
        handleConfirm={() => modalAction(params)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default ShiftIndex;
