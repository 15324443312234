import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import FormikSelect from '../Utils/Select/FormikSelect';
import EmployeeCard from '../Utils/Cards/EmployeeCard';
import { searchEmployeeRequest, indexEmployeesRequest } from '../../requests/employees';
import { delayMethod } from '../../services/utils';

const EmployeeSearchModal = ({ customParams, handleClose, setFieldValue, setFieldTouched, values }) => {
  const dispatch = useDispatch();
  const [selector, setSelector] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { shiftId } = customParams;
  const searchEmployeesParams = () => {
    const request = async () =>
      searchEmployeeRequest({
        params: {
          sort_column: 'name',
          paginate: false,
          advance: false
        },
        dispatch,
        successCallback: response => setSelector(camelCaseRecursive(response.data.data))
      });
    request();
  };

  const fetchEmployees = async params => {
    indexEmployeesRequest({
      params: {
        ...params,
        ...customParams,
        is_dt: false,
        sort_column: 'name',
        paginate: false
      },
      dispatch,
      successCallback: response => {
        const filteredContracts = response.data.data.filter(item => item.last_shift_id !== shiftId);
        setEmployees(filteredContracts);
        setFieldValue(
          'selected',
          filteredContracts.map(item => item.id)
        );
      }
    });
  };

  const fetchEmployeesFilter = searchValues => {
    if (searchValues === '') return setEmployees([]);
    const params = {
      filter_active_job_titles: [],
      filter_active_branch_offices: [],
      filter_active_job_managements: [],
      filter_active_syndicates: [],
      filter_active_cost_centers: []
    };
    searchValues.forEach(value => params[value.queryValue].push(value.value.split('-')[1]));
    return fetchEmployees(params);
  };

  const setNewSelected = id => {
    const { selected } = values;
    if (selected.includes(id))
      setFieldValue(
        'selected',
        selected.filter(item => item !== id)
      );
    else setFieldValue('selected', [...selected, id]);
  };

  useEffect(searchEmployeesParams, []);

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Field name="employees">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Buscar Empleados"
                  isMulti
                  options={selector}
                  onChange={data => {
                    fetchEmployeesFilter(data || '');
                    setFieldValue(field.name, data ? data.value : '');
                  }}
                  setFieldTouched={() => setFieldTouched(field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="selected-employee-cards">
          {employees.map(employee => (
            <Col md={4} sm={6} xs={12} className="mt-3 mb-0" key={`employee-${employee.id}`}>
              <EmployeeCard
                onClick={() => setNewSelected(employee.id)}
                employee={employee}
                shiftId={shiftId}
                selected={values.selected.includes(employee.id)}
              />
            </Col>
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-info" onClick={() => delayMethod(handleClose)}>
          Cancelar
        </Button>
        <Button type="submit" variant="primary" disabled={values.selected?.length === 0 || values.employees === ''}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = () => {
  return { employees: [], selected: [] };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values.selected);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(EmployeeSearchModal);
