import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import InformesReportFilters from '../Legalreports/Filters/InformesReportFilters';
import { ComponentDataTable, DefaultModal } from '../../components';
import columns from './Columns';
import ability from '../../config/ability';
import ReportActions from '../Legalreports/ReportActions';
import Expander from './Expander';
import { downloadFile } from '../../services/utils';
import { debounceAbsenceReport } from '../../requests/absenceReport';
import { sendAlert } from '../../actions/utils';

const TotalizedAbsenceReportDataTable = ({ rrhh, currentEmployee }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [amount, setAmount] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [modalState, setModalState] = useState({
    show: false,
    title: '',
    body: ''
  });

  const handleFilterAdvance = async params => {
    setFilters({ ...params });
    setDateRange(params.range_date_workday);
    setIsSubmitting(true);
    switch (params.type) {
      case null:
        setSearchFirstStart(true);
        setMoreData(!moreData);
        break;
      case '.xlsx':
        setParameters([params, '.xlsx', 'text']);
        setModalState({
          title: 'Exportar Reporte de Registro de Ausencia',
          body: '¿Estás seguro que quieres exportar el reporte de registro de ausencia en formato Excel?',
          show: true
        });
        break;
      default:
        break;
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'download_excel':
        setModalState({
          title: 'Exportar Reporte de Registro de Ausencia',
          body: '¿Estás seguro que quieres exportar el reporte de registro de ausencia en formato Excel?',
          show: true
        });
        setParameters([{ ...filters, type: '.xlsx' }, '.xlsx', 'text']);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const printInfo = () => {};

  const handleFailureRequest = error => {
    const message = error?.response?.data?.message || 'TRABAJADOR NO ENCONTRADO';
    dispatch(sendAlert({ kind: 'error', message }));
  };

  const handleRequest = (params, format = null, type = 'json') => {
    const responseType = params.type === '.docx' ? 'blob' : type;
    const responseFormat = format === '.print' ? '.json' : format;
    const customParams = {
      ...params,
      employee_id: rrhh ? params.employee_id : currentEmployee
    };
    setOnRequest(true);
    if (dateRange.length === 0) {
      setOnRequest(false);
      setIsSubmitting(false);
      return;
    }
    debounceAbsenceReport({
      dispatch,
      params: {
        display_start: 0,
        display_length: 15,
        ...customParams,
        sort_direction: 'asc',
        sort_column: 'name',
        is_dt: false,
        paginate: format === null
      },
      format: responseFormat,
      responseType,
      successCallback: response => {
        const { data } = response;
        if (format === '.xlsx' || format === '.docx' || format === '.pdf') {
          downloadFile(response);
        } else if (format === '.print') {
          setIsSubmitting(false);
          printInfo();
        } else {
          setAmount(data.metadata.amount);
          setEmployees(data.data);
        }
      },
      failureCallback: handleFailureRequest,
      callback: () => {
        setOnRequest(false);
        setIsSubmitting(false);
      }
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'branch_office_address':
        return { sort_branch_offices: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <InformesReportFilters
        filterRRHH={rrhh}
        formRequest={params => handleFilterAdvance(params)}
        isSubmit={isSubmitting}
        onlyPreview
      />

      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        moreData={moreData}
        data={employees}
        responsive
        totalRows={amount}
        handleSortCase={sortColumnCase}
        withMassActions={ability.can('export', 'AttendanceRecordReport')}
        massActions={
          <ReportActions
            attrr
            disabled={onRequest}
            handleClick={handleMassAction}
            dates={dateRange}
            showDatePeriod={false}
          />
        }
        resourceRequest={params => {
          handleRequest({ ...params, ...filters });
        }}
        expandableRows
        expandableRowExpanded={() => true}
        expandableRowsComponent={<Expander />}
        searchFirstStart={searchFirstStart}
      />
      <DefaultModal
        title={modalState.title}
        body={modalState.body}
        show={modalState.show}
        handleClose={() => {
          setModalState(oldState => ({ ...oldState, show: false }));
          setIsSubmitting(false);
        }}
        handleConfirm={() => {
          handleRequest(...parameters);
          setModalState(oldState => ({ ...oldState, show: false }));
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default TotalizedAbsenceReportDataTable;
