import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { debounceIndexEmployeesRequest } from '../../requests/employees';
import { ComponentDataTable, SimpleCenteredModal } from '../index';
import { AbilityContext } from '../../config/abilityContext';
import { debounceIndexAttendanceRequest } from '../../requests/attendances';
import { debounceTodayAbsencesReport } from '../../requests/todayAbsences';

const DailyAttendanceDataTable = ({ tab, employeesBelongBoss, moreData, columns }) => {
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const today = moment().format('DD-MM-YYYY');

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setEmployees(data);
    setAmount(metadata.amount);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const queryParams = { ...params, is_dt: false, active: true };

    const request = async () =>
      debounceIndexEmployeesRequest({
        dispatch,
        params: queryParams,
        successCallback: handleSuccessIndex
      });
    request();
  };

  const handleAttendanceReportRequest = params => {
    setOnRequest(true);
    const customParams = {
      filter_by_employee: employeesBelongBoss,
      date_range: [today, today],
      filterKey: 'All',
      ...params
    };
    debounceTodayAbsencesReport({
      dispatch,
      params: customParams,
      format: '.datatable',
      successCallback: response => {
        setEmployees(response.data.data);
        setAmount(response?.data?.metadata?.amount || 0);
        setOnRequest(false);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleAttendanceSuccess = response => {
    const { data } = response.data;
    if (response && response.data && response.data.data && response.data.data.length > 0) {
      setModalBody(
        <div>
          <hr />
          {data.map(
            attendance =>
              attendance.translated_time_type === 'Entrada' && (
                <div className="info-box" key={attendance.id}>
                  <p className="info">
                    <span>Fecha:</span> {attendance.date}
                  </p>
                  <p className="info">
                    <span>Turno:</span> {attendance.shift && attendance.shift.name}
                  </p>
                  <p className="info">
                    <span>Hora:</span> {attendance.complete_parsed_time}
                  </p>
                  <p className="info">
                    <span>Tipo:</span> {attendance.translated_time_type}
                  </p>
                  <hr />
                </div>
              )
          )}
        </div>
      );
      setModalTitle(data[0].employee_full_name);
    } else {
      setModalBody();
      setModalTitle('Aún no se ha registrado la asistencia.');
    }
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const handleAttendanceRequest = item => {
    const request = async () =>
      debounceIndexAttendanceRequest({
        dispatch,
        params: {
          filter_by_employee: item.records[0].employee_id,
          date_time_from: today,
          date_time_to: today
        },
        successCallback: handleAttendanceSuccess
      });
    request();
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        if (tab === 'team') {
          handleAttendanceRequest(item);
        } else {
          history.push(`/employees/${item.id}`);
        }
        break;
      default:
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      case 'cost_center':
        return { sort_cost_center: name };
      default:
        return { sort_column: name };
    }
  };

  const resourceRequest = tab === 'team' ? handleAttendanceReportRequest : handleIndexRequest;

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleActions, ability.can('show', 'Employee'))}
        data={employees}
        totalRows={amount}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        withSearch={tab !== 'team'}
        resourceRequest={resourceRequest}
        onRowClicked={item => handleActions(item, 'show')}
        pointerOnHover
        preName="dailyAttendance"
        query="filtered_cost_center"
        withMassActions
      />
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={handleCloseModal} />
    </>
  );
};

export default DailyAttendanceDataTable;
