import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { debounceIndexEmployeesRequest } from '../../requests/employees';
import { FormikRangePicker, FormikSelect } from '../../components';
import { debounceIndexBranchOfficesRequest } from '../../requests/branchOffices';
import { debounceIndexShiftsRequest } from '../../requests/shifts';
import { debounceIndexJobTitleRequest } from '../../requests/jobTitles';

const ALL_SELECTED_LABEL = 'Todos';

const ShiftAssignmentsFilters = props => {
  const { errors, touched, setFieldValue, values } = props;
  const [employees, setEmployees] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState('');
  const [jobTitles, setJobTitles] = useState([]);
  const [selectedJobTitles, setSelectedJobTitles] = useState('');
  const [loading, setLoading] = useState();
  const [placeholder, setPlaceholder] = useState('Seleccione colaboradores');
  const [branchOffices, setBranchOffices] = useState([]);
  const [selectedBranchOffice, setSelectedBranchOffice] = useState([]);
  const dispatch = useDispatch();
  const fetchEmployees = () => {
    setLoading(true);
    setPlaceholder('Cargando colaboradores...');
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'name',
        is_dt: false,
        paginate: false,
        filter_active_branch_offices: selectedBranchOffice,
        filter_job_title: selectedJobTitles,
        filter_active_shifts: selectedShift
      },
      successCallback: response => {
        const dataToGroupSearch = [{ id: 'Todos', label: 'Todos', value: 'Todos' }, ...response.data.data];
        setEmployees(response.data.data.length > 0 ? dataToGroupSearch : []);
        setLoading(false);
        setPlaceholder('Seleccione colaboradores');
      }
    });
  };

  const cleanEmployeesSelect = () => {
    setEmployees([]);
  };

  const fetchBranchOffices = () => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        paginate: false
      },
      successCallback: response => {
        cleanEmployeesSelect();
        setBranchOffices(response.data.data);
      }
    });
  };

  const fetchShifts = () => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        paginate: false
      },
      successCallback: response => setShifts(response.data.data)
    });
  };

  const fetchJobTitles = () => {
    debounceIndexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        paginate: false
      },
      successCallback: response => {
        setJobTitles(response.data.data);
      }
    });
  };

  const fetchData = () => {
    fetchEmployees();
    fetchShifts();
    fetchJobTitles();
    fetchBranchOffices();
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.value === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.value === ALL_SELECTED_LABEL;
  };

  const handleEmployeesSearch = (field, data) => {
    switch (field) {
      case 'filter_active_branch_offices':
        setSelectedBranchOffice(data);
        break;
      case 'filter_job_title':
        setSelectedJobTitles(data);
        break;
      case 'filter_active_shifts':
        setSelectedShift(data);
        break;
      default:
        break;
    }
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (newData.length && newData.includes(ALL_SELECTED_LABEL)) {
      newData.push('');
      return;
    }

    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
    handleEmployeesSearch(field, newData);
  };

  useEffect(fetchData, []);
  useEffect(() => {
    fetchEmployees();
    // eslint-disable-next-line
  }, [selectedBranchOffice, selectedJobTitles, selectedShift]);

  return (
    <Form>
      <Card>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt">Rango de Fechas o Colaborador(es)</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="date_range">
                      {({ field }) => (
                        <FormikRangePicker
                          {...field}
                          abbr
                          startDateName="date_from"
                          endDateName="date_to"
                          startDate={values.date_from}
                          endDate={values.date_to}
                          showClearDates
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="employees">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Nombres y Apellidos"
                          isMulti
                          options={employees}
                          placeholder={placeholder}
                          onChange={data => {
                            handleNewMulti(data || [], 'filter_employee_ids', true);
                            setFieldValue(field.name, data || []);
                            setFieldValue('employee', '');
                            setFieldValue('filter_id', '');
                          }}
                          isOptionDisabled={handleDisabledSelection}
                          isClearable
                          isDisabled={loading}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt">Búsqueda Grupal</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={4} xs={12} className="mt-2">
                    <Field name="branch_offices">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Lugar de Prestación de Servicios"
                          menuPlacement="top"
                          placeholder="Seleccione Lugar de Prestación de Servicios"
                          options={branchOffices}
                          isClearable
                          isDisabled={loading}
                          onChange={data => {
                            const value = data ? data.value : '';
                            const id = data ? data.id : '';
                            setFieldValue(field.name, value);
                            handleEmployeesSearch('filter_active_branch_offices', id);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={4} xs={12} className="mt-2">
                    <Field name="shifts">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Turnos"
                          menuPlacement="top"
                          placeholder="Seleccione Turno"
                          options={shifts}
                          isClearable
                          isDisabled={loading}
                          onChange={data => {
                            const value = data ? data.value : '';
                            const id = data ? data.id : '';
                            setFieldValue(field.name, value);
                            handleEmployeesSearch('filter_active_shifts', id);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={4} xs={12} className="mt-2">
                    <Field name="job_title">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Cargos"
                          menuPlacement="top"
                          placeholder="Seleccione Cargo"
                          options={jobTitles}
                          isClearable
                          isDisabled={loading}
                          onChange={data => {
                            const value = data ? data.value : '';
                            const id = data ? data.id : '';
                            setFieldValue(field.name, value);
                            handleEmployeesSearch('filter_job_title', id);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
      <Row className="d-flex justify-content-end mt-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" disabled={loading}>
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  date_range: Yup.array().when('filter_offday', {
    is: true,
    then: Yup.array().rangedate(true)
  })
});

const setInitialValues = () => {
  return {
    date_from: '',
    date_to: '',
    date_range: []
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: false
})(ShiftAssignmentsFilters);
