import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Button, Accordion, Card } from 'react-bootstrap';
import moment from 'moment';
import {
  BasicTooltip,
  FormikCheckBox,
  FormikDatePicker,
  FormikInput,
  FormikMaterialUiTimePicker,
  Icon
} from '../../components';
import './style.scss';
import GeneralData from './Accordion/GeneralData';
import ToleranceTab from './Accordion/Tolerance';
import Colation from './Accordion/Colation';
import Flexibility from './Accordion/Flexibility';
import Assignment from './Accordion/Assignment';

const ShiftRotative = ({ errors, touched, setFieldValue, onHide, shift, action, values, disabled }) => {
  const [activeDays, setActiveDays] = useState([]);
  const [accordion, setAccordion] = useState('general');
  const [initialActiveDays, setInitialActiveDays] = useState([]);
  const { agreedSchedule, exceptionalWorkday, flexibleShift, shiftType, shiftExtension } = values.shift;
  const [initialDisabledDays, setInitialDisabledDays] = useState([]);

  const setInitialShiftDaysAttributes = () => {
    let vinitialActiveDays = [];
    let vinitialDisabledDays = [];
    let enabledItemsCount = 0;
    if (action === 'new') {
      vinitialActiveDays = [...Array(7)].map(() => ({
        active: true,
        endLunch: '15:00',
        endTime: '18:00',
        send: true,
        startLunch: '14:00',
        startTime: '09:00',
        status: 'enabled'
      }));
      enabledItemsCount = vinitialActiveDays.length;
    } else {
      vinitialActiveDays = shift.shiftDaysAttributes
        .filter(item => item.status === 'enabled')
        .map(item => ({ ...item, send: true }));
      vinitialDisabledDays = shift.shiftDaysAttributes
        .filter(item => item.status === 'disabled')
        .map(item => ({ ...item, send: true }));
      setInitialDisabledDays(vinitialDisabledDays);
      enabledItemsCount = vinitialActiveDays.filter(item => item.status === 'enabled').length;
    }
    setInitialActiveDays(vinitialActiveDays);
    setActiveDays(vinitialActiveDays);
    setFieldValue('shift[activeDays]', enabledItemsCount);
  };

  useEffect(setInitialShiftDaysAttributes, [shift]);

  const handleChangeTab = tab => {
    setAccordion(tab);
  };

  const onClickDayCard = (item, index, element) => {
    if (element._dispatchListeners[0] === undefined) {
      const vActiveDays = activeDays;
      vActiveDays[index] = { ...activeDays[index], active: !item.active };
      setFieldValue(`shift[shiftDaysAttributes][${index}]`, vActiveDays[index]);
      setActiveDays(vActiveDays);
    }
  };

  const handleDaysChange = daysCount => {
    setFieldValue('shift[activeDays]', daysCount);
    let daysValues = [];
    let daysArray = initialActiveDays.slice(0, daysCount);
    if (daysCount > initialActiveDays.length) {
      daysArray = daysArray.concat([...Array(daysCount - initialActiveDays.length)]);
    }

    if (daysCount === 0) {
      setFieldValue(`shift[shiftDaysAttributes]`, []);
    } else {
      const countInitialActiveDays = initialActiveDays.length;
      const lastInitialActiveDay = initialActiveDays[countInitialActiveDays - 1];
      const lastDate = moment(lastInitialActiveDay.date, 'YYYY-MM-DD');
      daysValues = daysArray.map(item => {
        let dayOutput = [];
        if (item) {
          dayOutput = { ...item };
        } else {
          const fieldVal = {
            active: true,
            date: lastDate.add(1, 'days').format('YYYY-MM-DD'),
            endLunch: '15:00',
            endTime: '18:00',
            send: true,
            startLunch: '14:00',
            startTime: '09:00',
            status: 'enabled'
          };
          dayOutput = fieldVal;
        }
        return dayOutput;
      });
    }
    daysValues = initialDisabledDays.concat(daysValues);
    setFieldValue(`shift[shiftDaysAttributes]`, daysValues);
    setActiveDays(daysValues);
    initialActiveDays.slice(daysCount).map((item, index) => {
      const vValues = values.shift.shiftDaysAttributes;
      vValues[daysValues.length + index] = { ...item, send: false };
      return true;
    });
  };

  return (
    <Form>
      <Accordion defaultActiveKey="0">
        <GeneralData
          currentTab={accordion}
          disabled={disabled}
          errors={errors}
          exceptionalWorkday={exceptionalWorkday}
          extensionDefaultValue={shiftExtension}
          onChangeTab={handleChangeTab}
          shiftType={shiftType}
          setFieldValue={setFieldValue}
          touched={touched}
        />
        <Flexibility
          currentTab={accordion}
          disabled={disabled}
          errors={errors}
          getIn={getIn}
          onChangeTab={handleChangeTab}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
        />
        <ToleranceTab
          currentTab={accordion}
          disabled={disabled}
          errors={errors}
          flexibleShift={flexibleShift}
          onChangeTab={handleChangeTab}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
        />
        <Colation
          currentTab={accordion}
          disabled={disabled}
          errors={errors}
          onChangeTab={handleChangeTab}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
        />
        <Card className="overflow-visible">
          <Col className={`${accordion === 'distribution' ? 'top-header-green' : 'top-header-light-gray'}`}>
            <Accordion.Toggle
              as={Col}
              eventKey="4"
              className="card-header-title-dt"
              onClick={() => setAccordion('distribution')}
            >
              Distribución
            </Accordion.Toggle>
          </Col>
          <Accordion.Collapse eventKey="4">
            <Card.Body className="div-content">
              <Row>
                <Col md={4} className="rotative-proportion-card">
                  <Row>
                    <Col md={12}>
                      <p className="text-uppercase text-center">Días</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}>
                      <Icon
                        width={24}
                        className="as-button mt-1"
                        icon="remove"
                        onClick={() =>
                          disabled || (values.shift.activeDays > 0 && handleDaysChange(values.shift.activeDays - 1))
                        }
                      />
                    </Col>
                    <Col xs={8}>
                      <Field name="shift[activeDays]" style={{ textAlign: 'center' }}>
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label={false}
                            readOnly
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col xs={2}>
                      <Icon
                        width={24}
                        className="as-button mt-1"
                        icon="add"
                        onClick={() => disabled || handleDaysChange(values.shift.activeDays + 1)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={2}>
                  <BasicTooltip text="Número de días totales en este turno rotativo">
                    <Icon icon="help-circle" width={22} color="grey" />
                  </BasicTooltip>
                </Col>
                <Col md={6}>
                  <Field name="shift[startDate]">
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        abbr
                        isOutsideRange={() => false}
                        label="Fecha de Inicio"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3} xs={3} className="d-flex align-items-center">
                  <Field name="shift[autorenew]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        custom
                        field={field}
                        label="Renovar automáticamente"
                        disabled={disabled}
                        onClick={() => {
                          setFieldValue(field.name, field.value === 'true' || field.value === true);
                        }}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              {activeDays.length > 0 && <h3 className="text-uppercase mt-4 mb-3">Días Rotativos</h3>}
              {agreedSchedule ? (
                <Row>
                  {activeDays.map((item, index) =>
                    item.active ? (
                      <Col
                        xs={5}
                        sm={2}
                        md={2}
                        key={`day-${index + 1}`}
                        className="shift-card-container-rotative mt-3"
                        onClick={e => disabled || onClickDayCard(item, index, e)}
                      >
                        <Row>
                          <Col md={12}>
                            <Icon
                              className="text-primary"
                              style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                              width={18}
                              icon="checkmark-circle"
                            />
                            <p className="text-uppercase shift-card-title">Día {index + 1}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="align-items-center" md={12}>
                            <p className="text-uppercase shift-card-label">Desde</p>
                            <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                              {({ field }) => (
                                <FormikMaterialUiTimePicker
                                  {...field}
                                  abbr
                                  defaultValue="09:00"
                                  onChange={time => setFieldValue(field.name, time)}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  disabled={disabled}
                                />
                              )}
                            </Field>
                          </Col>
                          <Col md={12}>
                            <p className="text-uppercase shift-card-label">Hasta</p>
                            <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                              {({ field }) => (
                                <FormikMaterialUiTimePicker
                                  {...field}
                                  abbr
                                  defaultValue="18:00"
                                  onChange={time => setFieldValue(field.name, time)}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  disabled={disabled}
                                />
                              )}
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <p className="text-uppercase shift-card-title">Colación</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="align-items-center" md={12}>
                            <p className="text-uppercase shift-card-label">Desde</p>
                            <Field name={`shift[shiftDaysAttributes][${index}][startLunch]`}>
                              {({ field }) => (
                                <FormikMaterialUiTimePicker
                                  {...field}
                                  abbr
                                  defaultValue="14:00"
                                  onChange={time => setFieldValue(field.name, time)}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  disabled={disabled}
                                />
                              )}
                            </Field>
                          </Col>
                          <Col md={12}>
                            <p className="text-uppercase shift-card-label">Hasta</p>
                            <Field name={`shift[shiftDaysAttributes][${index}][endLunch]`}>
                              {({ field }) => (
                                <FormikMaterialUiTimePicker
                                  {...field}
                                  abbr
                                  defaultValue="15:00"
                                  onChange={time => setFieldValue(field.name, time)}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  disabled={disabled}
                                />
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      <Col
                        key={`day-${activeDays.length + index + 1}`}
                        xs={5}
                        sm={2}
                        md={2}
                        className="shift-card-container-rotative-free mt-3"
                        onClick={e => disabled || onClickDayCard(item, index, e)}
                      >
                        <p className="text-uppercase shift-card-title text-center mt-4">Día {index + 1}</p>
                        <p className="text-uppercase text-center mb-4">Libre</p>
                      </Col>
                    )
                  )}
                </Row>
              ) : (
                <Row>
                  {activeDays.map((item, index) =>
                    item.active ? (
                      <Col
                        xs={5}
                        sm={2}
                        md={2}
                        key={`day-${index + 1}`}
                        className="shift-card-container-rotative mt-3"
                        onClick={e => disabled || onClickDayCard(item, index, e)}
                      >
                        <Row>
                          <Col md={12}>
                            <Icon
                              className="text-primary"
                              style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                              width={18}
                              icon="checkmark-circle"
                            />
                            <p className="text-uppercase shift-card-title">Día {index + 1}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3 align-items-center" md={12}>
                            <p className="text-uppercase shift-card-label">Desde</p>
                            <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                              {({ field }) => (
                                <FormikMaterialUiTimePicker
                                  {...field}
                                  abbr
                                  defaultValue="09:00"
                                  onChange={time => setFieldValue(field.name, time)}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  disabled={flexibleShift || disabled}
                                />
                              )}
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3" md={12}>
                            <p className="text-uppercase shift-card-label">Hasta</p>
                            <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                              {({ field }) => (
                                <FormikMaterialUiTimePicker
                                  {...field}
                                  abbr
                                  defaultValue="18:00"
                                  onChange={time => setFieldValue(field.name, time)}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                  disabled={flexibleShift || disabled}
                                />
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      <Col
                        key={`day-${activeDays.length + index + 1}`}
                        xs={5}
                        sm={2}
                        md={2}
                        className="shift-card-container-rotative-free mt-3"
                        onClick={e => disabled || onClickDayCard(item, index, e)}
                      >
                        <p className="text-uppercase shift-card-title text-center mt-4">Día {index + 1}</p>
                        <p className="text-uppercase text-center mb-4">Libre</p>
                      </Col>
                    )
                  )}
                </Row>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Assignment
          currentTab={accordion}
          disabled={disabled}
          errors={errors}
          getIn={getIn}
          onChangeTab={handleChangeTab}
          setFieldValue={setFieldValue}
          shift={shift}
          touched={touched}
        />
      </Accordion>
      <Row className="d-flex justify-content-end my-3">
        <Col md={3}>
          <Button type="submit" variant="primary" block onClick={onHide}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { shiftDaysAttributes, shiftExtension } = props.shift;
  const shiftDaysLength = shiftDaysAttributes.length;
  const autorenew = shiftDaysLength === 0 ? true : props.shift.autorenew;

  const defaultShiftDay = {
    date: '',
    startTime: '09:00',
    endTime: '18:00',
    startLunch: '14:00',
    endLunch: '15:00',
    send: true,
    active: true
  };

  const shiftDays = shiftDaysLength
    ? shiftDaysAttributes.map(item => ({ ...item, send: true }))
    : Array.from({ length: 7 }, () => ({ ...defaultShiftDay }));

  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: shiftDays,
      shiftType: 'rotative',
      shiftExtension: shiftExtension || '',
      autorenew
    }
  };
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape(
    {
      lunchCheck: Yup.boolean(),
      workHolidays: Yup.boolean(),
      tolerance: Yup.number().when('flexibleShift', {
        is: val => val !== 'true',
        then: Yup.number()
          .required('Debes ingresar una tolerancia')
          .min(0, 'Debe ser mayor o igual a 0')
      }),
      lunchTime: Yup.number().when('collationTime', {
        is: val => val,
        then: Yup.number()
          .required('Debes ingresar un número')
          .min(0, 'Debe ser mayor o igual a 0 '),
        otherwise: Yup.number().nullable()
      }),
      initialTime: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar un horario'),
        otherwise: Yup.string().nullable()
      }),
      maximumStartTime: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar tiempo máximo de inicio')
      }),
      workHours: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar cantidad de horas a trabajar')
      }),
      collationTime: Yup.bool().when('agreedSchedule', {
        is: aSchedule => aSchedule === false,
        then: Yup.bool().oneOf([true], 'Debes seleccionar una opción')
      }),
      agreedSchedule: Yup.bool().when('collationTime', {
        is: colTime => colTime === false,
        then: Yup.bool().oneOf([true], 'Debes seleccionar una opción')
      }),
      name: Yup.string()
        .required('Debes ingresar un nombre')
        .max(80, 'Máximo 80 caracteres'),
      acronym: Yup.string()
        .required('Debes ingresar las siglas del turno')
        .max(5, 'Máximo 5 caracteres'),
      description: Yup.string()
        .required('Debes ingresar la descripción del turno')
        .max(80, 'Máximo 80 caracteres'),
      shiftExtension: Yup.string().required('Debes seleccionar una extensión'),
      startDate: Yup.string().required('Debes ingresar fecha de inicio'),
      shiftDaysAttributes: Yup.array()
        .when('agreedSchedule', {
          is: val => val,
          then: Yup.array().of(
            Yup.object({
              endLunch: Yup.string().when('active', {
                is: val => val,
                then: Yup.string()
                  .required('Debes ingresar hora de término')
                  .test(
                    'is-greater',
                    'La hora de término debe ser mayor o igual que la hora de inicio',
                    function isGreaterTest(value) {
                      const { startLunch } = this.parent;
                      return startLunch && value >= startLunch;
                    }
                  ),
                otherwise: Yup.string().nullable()
              }),
              startLunch: Yup.string().when('active', {
                is: val => val,
                then: Yup.string().required('Debes ingresar hora de inicio'),
                otherwise: Yup.string().nullable()
              })
            })
          )
        })
        .of(
          Yup.object().shape({
            groups: Yup.array().notRequired(),
            endTime: Yup.string().when('active', {
              is: val => val,
              then: Yup.string()
                .required('Debes ingresar hora de término')
                .notOneOf([Yup.ref('startTime'), null], 'Las horas de inicio y termino no pueden ser iguales'),
              otherwise: Yup.string().nullable()
            }),
            startTime: Yup.string().when('active', {
              is: val => val,
              then: Yup.string().required('Debes ingresar hora de inicio'),
              otherwise: Yup.string().nullable()
            }),
            send: Yup.boolean()
          })
        )
    },
    [['agreedSchedule', 'collationTime']]
  )
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftRotative);
