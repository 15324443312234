import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import memoize from 'memoize-one';
import { useAbility } from '@casl/react';
import { useReactToPrint } from 'react-to-print';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { downloadFile } from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import InformesReportFilters from '../Legalreports/Filters/InformesReportFilters';
import ReportActions from '../Legalreports/ReportActions';
import { debounceGenerateReport } from '../../requests/attendaceRecordTotal';
import AttendanceRecordTotalFilterDataTable from './AttendanceRecordTotalFilterDataTable';

const columns = memoize(() => [
  {
    name: 'Nombre',
    selector: 'name',
    cell: item => <>{item.full_name}</>,
    sortable: false,
    grow: '1.5'
  },
  {
    name: 'Cédula de identidad (RUT)',
    selector: 'national_identification',
    sortable: false,
    grow: '1'
  },
  {
    name: 'Area',
    selector: 'employee_job_management',
    sortable: false,
    grow: '1'
  },
  {
    name: 'Lugar de prestación de servicios',
    selector: 'employee_branch',
    sortable: false,
    grow: '2'
  }
]);

export const WorkdayExpander = props => {
  const { data, setDocumentModalBody, setDocumentModalShow } = props;
  const { attendances, attendances_total: attendancesTotal } = data;

  return (
    <AttendanceRecordTotalFilterDataTable
      attendances={attendances}
      attendancesTotal={attendancesTotal}
      setDocumentModalBody={setDocumentModalBody}
      setDocumentModalShow={setDocumentModalShow}
    />
  );
};

const AttendanceRecordTotalDataTable = ({ rrhh }) => {
  const [amount, setAmount] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [tile, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [parameters, setParameters] = useState([]);
  const [filters, setFilters] = useState();
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [documentModalBody, setDocumentModalBody] = useState('');
  const [dateRange, setDateRange] = useState([]);

  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);
  const componentRef = useRef();
  const { currentEmployee } = useSelector(state => state.auth);

  const printInfo = useReactToPrint({
    content: () => componentRef.current
  });

  const handleFailureRequest = error => {
    const message = error?.response?.data?.message || 'TRABAJADOR NO ENCONTRADO';
    dispatch(sendAlert({ kind: 'error', message }));
  };

  const handleRequest = (params, format = null, type = 'json') => {
    const responseType = params.type === '.docx' ? 'blob' : type;
    const responseFormat = format === '.print' ? '.json' : format;
    const customParams = {
      ...params,
      employee_id: rrhh ? params.employee_id : currentEmployee
    };
    setOnRequest(true);
    if (dateRange.length > 0) {
      debounceGenerateReport({
        dispatch,
        params: {
          display_start: 0,
          display_length: 15,
          ...customParams,
          sort_direction: 'asc',
          sort_column: 'name',
          is_dt: false,
          paginate: format === null
        },
        format: responseFormat,
        responseType,
        successCallback: response => {
          const { data } = response;
          if (format === '.xlsx' || format === '.docx' || format === '.pdf') {
            downloadFile(response);
          } else if (format === '.print') {
            setSubmitting(false);
            printInfo();
          } else {
            setAmount(data.metadata.amount);
            setEmployees(data.data);
          }
        },
        failureCallback: handleFailureRequest,
        callback: () => {
          setOnRequest(false);
          setSubmitting(false);
        }
      });
    } else {
      setOnRequest(false);
      setSubmitting(false);
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'download_excel':
        setTitle('Exportar Reporte de Registro de Asistencia');
        setBody('¿Estás seguro que quieres exportar el reporte de registro de asistencia en formato Excel?');
        setParameters([{ ...filters, type: '.xlsx' }, '.xlsx', 'text']);
        setModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };
  const handleFilterAdvance = async params => {
    setFilters(params);
    setDateRange(params.range_date_workday);
    setSubmitting(true);
    switch (params.type) {
      case null:
        setSearchFirstStart(true);
        setMoreData(!moreData);
        break;
      case '.xlsx':
        setTitle('Exportar Reporte de Registro de Asistencia');
        setBody('¿Estás seguro que quieres exportar el reporte de registro de asistencia en formato Excel?');
        setParameters([params, '.xlsx', 'text']);
        setModalShow(true);
        break;
      default:
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'branch_office_address':
        return { sort_branch_offices: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <InformesReportFilters filterRRHH={rrhh} formRequest={handleFilterAdvance} isSubmit={isSubmitting} onlyPreview />

      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        moreData={moreData}
        data={employees}
        responsive
        totalRows={amount}
        handleSortCase={sortColumnCase}
        withMassActions={ability.can('export', 'AttendanceTotalReport')}
        massActions={
          <ReportActions
            attrr
            disabled={onRequest}
            handleClick={handleMassAction}
            dates={dateRange}
            showDatePeriod={false}
          />
        }
        resourceRequest={params => {
          handleRequest({ ...params, ...filters });
        }}
        expandableRows
        expandableRowExpanded={() => true}
        expandableRowsComponent={
          <WorkdayExpander setDocumentModalBody={setDocumentModalBody} setDocumentModalShow={setDocumentModalShow} />
        }
        searchFirstStart={searchFirstStart}
      />
      <DefaultModal
        title={tile}
        body={body}
        show={modalShow}
        handleClose={() => {
          setModalShow(false);
          setSubmitting(false);
        }}
        handleConfirm={() => {
          handleRequest(...parameters);
          setModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      <SimpleCenteredModal
        body={documentModalBody}
        onHide={() => setDocumentModalShow(false)}
        show={documentModalShow}
        size="xl"
      />
    </>
  );
};

export default AttendanceRecordTotalDataTable;
