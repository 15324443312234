import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import memoize from 'memoize-one';
import { useAbility } from '@casl/react';
import { Col, Button, Row } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { legalreportShiftReport } from '../../requests/legalreports';
import { DataTableDT, DefaultModal, Icon } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { ShiftFilterDataTable } from '../../components/LegalReport';
import { downloadFile } from '../../services/utils';
import ShiftChangeFilter from './Filters/NewLegalReportFiltersDt';
import PrintableShiftReport from '../../components/LegalReport/PrintableShiftReport';
import { sendAlert } from '../../actions/utils';
import ReportActions from './ReportActions';

const columns = memoize(() => [
  {
    name: 'Nombre',
    selector: 'employee_name',
    sortable: true,
    grow: '2'
  },
  {
    name: 'Cédula de Identidad (RUT)',
    selector: 'employee_rut',
    sortable: true,
    grow: '1'
  }
]);

export const MyExpander = props => {
  const { data } = props;
  return <ShiftFilterDataTable shiftLogs={data.shift_log} />;
};

export const LoadingTranslation = () => {
  return <h3>Cargando...</h3>;
};

const ShiftReportDataTable = ({ rrhh }) => {
  const [onRequest, setOnRequest] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState(0);
  const [filters, setFilters] = useState([]);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [moreData, setMoreData] = useState(false);
  const componentRef = useRef();
  const { currentEmployee } = useSelector(state => state.auth);
  const [parameters, setParameters] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [dateRange, setDateRange] = useState([]);

  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const printInfo = useReactToPrint({
    content: () => componentRef.current
  });

  const defaultParams = {
    display_start: 0,
    display_length: 15,
    sort_direction: 'desc',
    sort_column: 'created_at',
    active: true,
    is_dt: false,
    active_contracts: true
  };

  const handleSuccessRequest = (response, format) => {
    const { data } = response;
    switch (format) {
      case '.xlsx':
      case '.docx':
      case '.pdf':
        downloadFile(response);
        break;
      case '.print':
        setSubmitting(false);
        setEmployees(data.data);
        printInfo();
        break;
      default:
        setAmount(data.metadata.amount);
        setEmployees(data.data);
        break;
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'download_excel':
        setModalTitle('Exportar Reporte de Modificación de Turnos');
        setModalBody('¿Estás seguro que quieres exportar el reporte de modificación de turno en formato Excel?');
        setParameters([{ ...filters, type: '.xlsx' }, '.xlsx', 'text']);
        setModalShow(true);
        break;
      case 'download_docx':
        setModalTitle('Exportar Reporte de Modificación de Turnos');
        setModalBody('¿Estás seguro que quieres exportar el reporte de modificación de turnos en formato Word?');
        setParameters([{ ...filters, type: '.docx' }, '.docx', 'text']);
        setModalShow(true);
        break;
      case 'download_pdf':
        setModalTitle('Exportar Reporte de Modificación de Turnos');
        setModalBody('¿Estás seguro que quieres exportar el reporte de modificación de turnos en formato PDF?');
        setParameters([{ ...filters, type: '.pdf' }, '.pdf', 'text']);
        setModalShow(true);
        break;
      case 'print':
        setModalTitle('Imprimir Reporte de Modificación de Turnos');
        setModalBody('¿Estás seguro que quieres imprimir el reporte de modificación de turnos?');
        setParameters([{ ...filters, type: '.print' }, '.print', 'text']);
        setModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleFailureRequest = error => {
    const message = error?.response?.data?.message || 'TRABAJADOR NO ENCONTRADO';
    dispatch(sendAlert({ kind: 'error', message }));
  };

  const handleRequest = (params, format, type = 'json') => {
    const responseType = params.type === '.docx' ? 'blob' : type;
    const responseFormat = format === '.print' ? '.json' : format;
    const customParams = {
      ...params,
      range_date: params.range_date_workday,
      filter_employee_id: rrhh ? params.filter_employee_id : currentEmployee
    };
    setOnRequest(true);
    legalreportShiftReport({
      dispatch,
      params: {
        ...defaultParams,
        ...customParams
      },
      format: responseFormat,
      responseType,
      successCallback: response => handleSuccessRequest(response, format),
      failureCallback: handleFailureRequest,
      callback: () => {
        setOnRequest(false);
        setSubmitting(false);
      }
    });
  };

  const handleFilters = async params => {
    setFilters(params);
    setDateRange(params.range_date_workday);
    setSubmitting(true);

    switch (params.type) {
      case null:
        setSearchFirstStart(true);
        setMoreData(!moreData);
        setShowFilter(false);
        break;
      case '.docx':
        setModalTitle('Exportar Reporte de Modificación de Turnos');
        setModalBody('¿Estás seguro que quieres exportar el reporte de modificación de turnos en formato Word?');
        setParameters([params, '.docx', 'text']);
        setModalShow(true);
        break;
      case '.xlsx':
        setModalTitle('Exportar Reporte de Modificación de Turnos');
        setModalBody('¿Estás seguro que quieres exportar el reporte de modificación de turno en formato Excel?');
        setParameters([params, '.xlsx', 'text']);
        setModalShow(true);
        break;
      case '.pdf':
        setModalTitle('Exportar Reporte de Modificación de Turnos');
        setModalBody('¿Estás seguro que quieres exportar el reporte de modificación de turnos en formato PDF?');
        setParameters([params, '.pdf', 'text']);
        setModalShow(true);
        break;
      case '.print':
        setModalTitle('Imprimir Reporte de Modificación de Turnos');
        setModalBody('¿Estás seguro que quieres imprimir el reporte de modificación de turnos?');
        setParameters([params, '.print', 'text']);
        setModalShow(true);
        break;
      default:
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'branch_office_address':
        return { sort_branch_offices: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <div className={showFilter ? '' : 'd-none'}>
        <ShiftChangeFilter isArial filterRRHH={rrhh} formRequest={handleFilters} isSubmit={isSubmitting} />
      </div>
      <div className={showFilter ? 'd-none' : ''}>
        <Row>
          <Col md={6} className="mt-3">
            <Button variant="primary" onClick={() => setShowFilter(true)}>
              <Icon icon="chevron-back" width={15} />
              <span className="arial-dt">Volver al filtro</span>
            </Button>
          </Col>
        </Row>
        <DataTableDT
          onRequest={onRequest}
          columns={columns()}
          moreData={moreData}
          data={employees}
          totalRows={amount}
          handleSortCase={sortColumnCase}
          withMassActions={ability.can('index', 'ShiftReport')}
          resourceRequest={params => {
            handleRequest({ ...params, ...filters });
          }}
          massActions={<ReportActions disabled={onRequest} handleClick={handleMassAction} dates={dateRange} isArial />}
          expandableRows
          expandableRowExpanded={() => true}
          expandableRowsComponent={<MyExpander />}
          searchFirstStart={searchFirstStart}
        />
      </div>
      <div style={{ display: 'none' }}>
        <PrintableShiftReport rows={employees} ref={componentRef} />
      </div>
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => {
          setModalShow(false);
          setSubmitting(false);
        }}
        handleConfirm={() => {
          handleRequest(...parameters);
          setModalShow(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default ShiftReportDataTable;
