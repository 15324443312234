import React, { useEffect } from 'react';
import OffdaysReportDataTable from './OffdaysReport';

const OffdaysReportIndex = ({ rrhh }) => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <OffdaysReportDataTable rrhh={rrhh} />
    </>
  );
};

export default OffdaysReportIndex;
