import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Button, Row, Col, Table } from 'react-bootstrap';
import '../../../services/yupCustomMethods';
import {
  ButtonTooltip,
  DatatableEmployeeName,
  FormikDatePicker,
  FormikNumber,
  FormikSelect,
  Icon,
  Loader,
  SimpleCenteredModal
} from '../../../components';
import EmployeeSearchModalPayroll from '../../../components/Employee/EmployeeSearchModalPayroll';
import { overtimeDiscountsWithoutCalculatedOvertimeValues } from './FormOptions';
import { payrollEmployeesRequest } from '../../../requests/payrolls';

const basicAbsence = () => ({
  hourValue: '',
  startDate: '',
  hours: '',
  minutes: '',
  employeeId: ''
});

const OvertimeDiscountsMovementsForm = props => {
  const { setFieldValue, values, isSubmitting, payroll, overtimeGroup = {}, action: _action } = props;
  const [employees, setEmployees] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const { overtimeDiscountsMovement } = values;
  const payrollStartDate = moment(payroll?.start_date, 'YYYY-MM-DD');
  const payrollEndDate = moment(payroll?.end_date, 'YYYY-MM-DD').set({ hour: 23, minute: 59 });
  const [isDisabled, setIsDisabled] = useState(false);

  const fetchEmployees = () => {
    if (_action === 'edit' && overtimeGroup?.employee) {
      const firstMovement = overtimeGroup?.overtimeDiscountsMovements[0];
      const { employee } = firstMovement;
      setEmployees(employee ? [employee] : []);
      setFieldValue('employeeSelector', employee ? employee.id : []);
      return;
    }
    setLoading(true);
    setIsDisabled(true);
    if (payroll) {
      payrollEmployeesRequest({
        dispatch,
        params: {
          filter_payroll_process: payroll.id
        },
        successCallback: response => {
          const uniqueEmployees = response.data.data.filter(
            (item, index, self) => index === self.findIndex(t => t.id === item.id)
          );
          const dataEmployees = [{ label: 'Todos', value: 'all_employees' }, ...uniqueEmployees];
          setEmployees(uniqueEmployees.length > 0 ? dataEmployees : []);
          setFieldValue('employeeSelector', dataEmployees);
          setLoading(false);
          setIsDisabled(false);
        }
      });
    }
  };

  useEffect(fetchEmployees, [payroll]);

  const addAllEmployees = () => {
    const vSelector = employees.filter(selected => selected.id !== undefined);
    const employeesToAdd = vSelector.map(employee => ({ ...basicAbsence(), employeeId: employee.value, employee }));
    const vAbsences = [...overtimeDiscountsMovement, ...employeesToAdd];
    setFieldValue('overtimeDiscountsMovement', vAbsences);
  };

  const handleSelector = () => {
    const { employeeSelector } = props.values;
    const vSelector = employees.filter(selected => selected.value !== employeeSelector);
    if (vSelector.length === employees.length) {
      return;
    }
    if (employeeSelector === 'all_employees') {
      addAllEmployees();
    } else {
      const employeeToAdd = employees.find(selected => selected.value === employeeSelector);
      const absencesLength = overtimeDiscountsMovement.length;

      setFieldValue(`overtimeDiscountsMovement[${absencesLength}]`, {
        ...basicAbsence(),
        employeeId: employeeToAdd.value,
        employee: {
          ...employeeToAdd
        }
      });
      setEmployees(employees);
    }
  };

  const removeFromTable = (indexSelected, action) => {
    if (action === 'destroy') {
      const updatedMovements = [...overtimeDiscountsMovement]
        .map((movement, index) => {
          if (index === indexSelected) {
            return { ...movement, _destroy: true };
          }
          return movement;
        })
        .filter(movement => !(movement._destroy && !movement?.id));
      setFieldValue('overtimeDiscountsMovement', updatedMovements);
    }
  };

  const handleSearch = selectedEmployees => {
    const vSelector = employees.filter(selected => !selectedEmployees.includes(selected.value));
    setModalShow(false);
    if (vSelector.length === employees.length) return;
    const employeesToAdd = employees
      .filter(selected => selectedEmployees.includes(selected.value))
      .map(employee => ({ ...basicAbsence(), employeeId: employee.value, employee }));
    setFieldValue('overtimeDiscountsMovement', [...values.overtimeDiscountsMovement, ...employeesToAdd]);
    setEmployees(vSelector);
  };

  const { onHide, submitVariant, errors, touched, setFieldTouched } = props;
  const btnMessage = _action === 'new' ? 'Crear' : 'Guardar';
  return (
    <Form>
      <div className="info-box ml-0">
        <h4 className="text-uppercase">Agregar Trabajadores</h4>
        <p className="info">
          <span className="full-width">Seleccione trabajadores para incluir en el proceso</span>
        </p>
      </div>
      <Row className="align-items-center mb-3 mb-md-0">
        <Col md={5}>
          <Field name="employeeSelector">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Generar Para"
                placeholder="Seleccionar Trabajador"
                options={employees}
                defaultValue={_action === 'edit' ? field.value : 'all_employees'}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {loading ? (
          <Col md={2} xl={2}>
            <Loader
              containerStyle={{ height: '35px' }}
              loaderSize={30}
              message="Cargando trabajadores..."
              messageStyle={{ fontSize: '14px' }}
            />
          </Col>
        ) : (
          <Col xs={6} md={2} xl={2}>
            <Button variant="primary" onClick={handleSelector} style={{ marginTop: '5px' }} disabled={isDisabled}>
              Agregar
            </Button>
          </Col>
        )}
        {_action === 'new' && (
          <Col>
            <ButtonTooltip
              variant="circle-primary"
              className="advance-search"
              text="Búsqueda Avanzada"
              onClick={() => setModalShow(true)}
            >
              <Icon className="w-100 h-100" icon="people-circle" />
            </ButtonTooltip>
          </Col>
        )}
      </Row>
      <Row>
        <Table responsive>
          <thead>
            <tr>
              <th>TRABAJADOR</th>
              <th className="text-center" style={{ minWidth: '200px' }}>
                ITEM
              </th>
              <th className="text-center" style={{ maxWidth: '70px' }}>
                DÍA
              </th>
              <th className="text-center" style={{ maxWidth: '70px' }}>
                Monto o Cantidad
              </th>
            </tr>
          </thead>
          <tbody>
            {overtimeDiscountsMovement.map((absence, index) => {
              return (
                !absence._destroy && (
                  <tr key={`overtimeDiscountsMovement-${index.toString()}`}>
                    <td className="employee-name">
                      <DatatableEmployeeName
                        item={absence.employee}
                        fileName="file_info"
                        name={_action === 'edit' ? 'fullName' : 'full_name'}
                        fileUrl="file_url"
                      />
                    </td>
                    <td>
                      <Field name={`overtimeDiscountsMovement[${index}][hourValue]`}>
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            placeholder="Seleccionar"
                            options={overtimeDiscountsWithoutCalculatedOvertimeValues}
                            defaultValue={absence.hourValue}
                            onChange={data => {
                              setFieldValue(field.name, data ? data.value : '');
                            }}
                            setFieldTouched={() => setFieldTouched(field.name)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            margin="mb-0"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name={`overtimeDiscountsMovement[${index}][startDate]`}>
                        {({ field }) => (
                          <FormikDatePicker
                            {...field}
                            isOutsideRange={day => day.isAfter(payrollEndDate) || day.isBefore(payrollStartDate)}
                            placeholder="dd/mm/aaaa"
                            onDateChange={date => {
                              const vDate = date !== null ? date.format('DD/MM/YYYY') : '';
                              setFieldValue(field.name, vDate);
                            }}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            margin="mb-0"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name={`overtimeDiscountsMovement[${index}][hours]`}>
                        {({ field }) => (
                          <FormikNumber
                            {...field}
                            rightAddon="hrs"
                            placeholder="1"
                            maxLength={2}
                            fieldName={`overtimeDiscountsMovement[${index}][hours]`}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            margin="mb-0"
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <ButtonTooltip
                        onClick={() => removeFromTable(index, 'destroy')}
                        variant="circle-danger"
                        className="btn-circle"
                        size="sm"
                        text="Eliminar"
                      >
                        <Icon icon="trash" />
                      </ButtonTooltip>
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </Table>
      </Row>
      <Row className="d-flex justify-content-end m-t-10 m-b-30">
        <Col md={3} xl={2}>
          <Button type="submit" className="mb-4" disabled={isSubmitting} variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={
          <EmployeeSearchModalPayroll
            customParams={{
              filter_payroll_process: payroll.id
            }}
            payrollValidIds={payroll?.payroll_employee_ids || []}
            handleClose={() => setModalShow(false)}
            formRequest={handleSearch}
          />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Form>
  );
};

const setInitialValues = ({ overtimeGroup = {}, action }) => {
  const movements = overtimeGroup?.overtimeDiscountsMovements || [];
  return {
    overtimeDiscountsMovement: action === 'edit' ? movements : [],
    employeeSelector: 'all_employees'
  };
};

const validationSchema = Yup.object().shape({
  overtimeDiscountsMovement: Yup.array().of(
    Yup.object().shape({
      startDate: Yup.date()
        .required('Debes seleccionar una fecha de inicio')
        .formatdate(),
      hourValue: Yup.string().required('Debes seleccionar un tipo de horas'),
      hours: Yup.number()
        .required('Debes ingresar la cantidad de horas')
        .positive('Debe ser mayor a 0')
        .max(23, 'Debe ser menor a 24 horas')
    })
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(OvertimeDiscountsMovementsForm);
