import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Button, Accordion, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ComponentDataTable, DefaultModal, Icon, ImportModal, SimpleCenteredModal } from '../../../components';
import '../AttendanceMovements/style.scss';
import { sendAlert } from '../../../actions/utils';
import { showPayrollProcessRequest } from '../../../requests/payrollProcesses';
import { camelCaseEmptyStringRecursive, downloadFile } from '../../../services/utils';
import {
  createOvertimeDiscountsMovementsRequest,
  importOvertimeDiscountsMovementsRequest,
  importTemplateOvertimeDiscountsMovements,
  preImportOvertimeDiscountsMovementsRequest
} from '../../../requests/overtimeDiscountsMovements';
import { indexOvertimeRequest } from '../../../requests/overtimes';
import ImportOvertimeDiscountsMovements from './ImportOvertimeDiscountsMovements';
import OvertimeDiscountsMovementsFilter from './OvertimeDiscountsMovementsFilter';
import { debounceLegalreportWorkdayReport } from '../../../requests/legalreports';
import { indexPermissionsRequest } from '../../../requests/permissions';
import {
  debounceIndexOvertimeDiscountsGroupsRequest,
  destroyOvertimeDiscountsGroupRequest,
  exportOvertimeDiscountsGroupsRequest,
  massiveDestroyOvertimeDiscountsGroupsRequest,
  processOvertimeDiscountsGroupsRequest
} from '../../../requests/overtimeGroups';
import ColumnsGroups from './ColumnsGroups';
import MassiveActionsGroup from './MassiveActionsGroup';
import { makeDiscountsMovements, makeOvertimeMovements, makePermissionsMovements } from './helpers';
import OvertimeDiscountsMovementsDatesEdit from './OvertimeDiscountsMovementsDatesEdit';

const OvertimeDiscountIndex = props => {
  const history = useHistory();
  const { id, onlyShow } = props;
  const { currentCompany } = useSelector(state => state.auth);
  const { companyModules } = currentCompany;
  const attendanceManagement = companyModules?.includes('attendance_management');
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalBody, setSimpleModalBody] = useState({});
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [modalItem, setModalItem] = useState([]);
  const [overtimes, setOvertimes] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);

  const [modalAction, setModalAction] = useState(() => null);
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [moreData, setMoreData] = useState(false);
  const [filters, setFilters] = useState();
  const [size, setSize] = useState('lg');
  const [option, setOption] = useState('');
  const dispatch = useDispatch();
  const [payrollDates, setPayrollDates] = useState([]);
  const [payrollProcess, setPayrollProcess] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const payrollStartDate = moment(payrollDates[0]).format('DD-MM-YYYY');
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [amount, setAmount] = useState(0);
  const importAction = attendanceManagement ? 'attendance' : 'permissions';

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
  };

  const handleShowRequest = () => {
    setOnRequest(true);
    showPayrollProcessRequest(id, {
      dispatch,
      service: 'payroll',
      successCallback: res => {
        setPayrollProcess(res.data);
        setPayrollDates([res.data.start_date, res.data.end_date]);
        setOnRequest(false);
      }
    });
  };

  const handleModalClose = () => {
    setSimpleModalShow(false);
    setModalShow(false);
  };

  const handleSuccessIndex = response => {
    setOvertimes(response.data.data);
    setAmount(response.data.metadata.amount);
    setOnRequest(false);
    setClearSelectedRows(false);
  };

  const handleSuccessImport = message => {
    setSimpleModalShow(false);
    setOnRequest(false);
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const handleRequest = params => {
    const customParams = params;
    delete customParams.date_to;
    delete customParams.date_from;
    setOnRequest(true);
    const request = async () =>
      debounceIndexOvertimeDiscountsGroupsRequest({
        dispatch,
        params: {
          payroll_process: id,
          ...customParams
        },
        successCallback: handleSuccessIndex
      });

    request();
  };

  const handleFailureRequest = (error, title) => {
    const { response } = error;
    const message = response?.data?.message;
    setSimpleModalShow(false);
    setDefaultModalTitle(title);
    setDefaultModalBody(message);
    setDefaultModalShow(true);
    setOnRequest(false);
  };

  const handleExport = itemsReceived => {
    setOnRequest(true);
    const customFilters = filters;
    delete customFilters?.date_to;
    delete customFilters?.date_from;
    const downloadFilter = itemsReceived.length > 0 ? { filter_id: itemsReceived } : customFilters;
    exportOvertimeDiscountsGroupsRequest({
      dispatch,
      params: {
        payroll_process: id,
        sort_employees: 'full_name',
        sort_direction: 'asc',
        ...downloadFilter
      },
      successCallback: response => {
        downloadFile(response);
        setOnRequest(false);
        setModalShow(false);
        setClearSelectedRows(oldValue => !oldValue);
      },
      failureCallback: error => handleFailureRequest(error, 'Error al exportar'),
      callback: () => {
        setOnRequest(false);
        setModalItem([]);
      }
    });
  };

  const handleFilter = async params => {
    setFilters(params);
    setMoreData(!moreData);
  };

  const handleOvertimeDiscountsMovements = filter => {
    const types = filter.filter_type;
    const { formated_employees: employeeFormated } = filter;
    const search = ['early_departure_hours', 'hours_late', 'calculated_overtime', 'break_time_excess'];
    const searchTypes = types.some(item => search.includes(item));

    return new Promise(resolve => {
      if (types.length === 0 || searchTypes) {
        const responseType = null;
        const type = 'json';
        const start = filter.date_from;
        const end = filter.date_to;
        const dates = [];

        debounceLegalreportWorkdayReport({
          dispatch,
          params: {
            sort_direction: 'asc',
            sort_column: 'name',
            active: true,
            active_contracts: true,
            date_from_workday: start,
            date_to_workday: end,
            range_date_workday: `${start},${end}`,
            is_dt: false,
            paginate: false,
            type,
            filter_ids: filter.filter_by_employee
          },
          responseType,
          successCallback: response => {
            const { data } = response.data;
            let result = [];

            data.forEach(record => {
              const employee = employeeFormated.find(emp => emp.id === record.employee_id);
              record.attendances.forEach(week => {
                week.attendances.forEach(attendances => {
                  const searchEarly = ['early_departure_hours'];
                  const filterSearchEarly = types.some(item => searchEarly.includes(item));
                  if (attendances?.anomalies_pgr_departure_time > 0 && (types.length === 0 || filterSearchEarly)) {
                    result = [
                      ...result,
                      {
                        employee: { ...employee },
                        attendance: attendances,
                        type: 'SA'
                      }
                    ];
                  }

                  const searchLate = ['hours_late'];
                  const filterSearchLate = types.some(item => searchLate.includes(item));
                  if (attendances?.anomalies_pgr_arrival_time > 0 && (types.length === 0 || filterSearchLate)) {
                    result = [
                      ...result,
                      {
                        employee: { ...employee },
                        attendance: attendances,
                        type: 'AT'
                      }
                    ];
                  }

                  const searchCalculated = ['calculated_overtime'];
                  const filterSearchCalculated = types.some(item => searchCalculated.includes(item));
                  if (attendances?.hours_extra_time > 0 && (types.length === 0 || filterSearchCalculated)) {
                    result = [
                      ...result,
                      {
                        employee: { ...employee },
                        attendance: attendances,
                        calculated_overtime: true,
                        type: 'HE'
                      }
                    ];
                  }

                  const searchExcess = ['break_time_excess'];
                  const filterExcessSearch = types.some(item => searchExcess.includes(item));
                  if (
                    attendances?.shift_break_time > 0 &&
                    attendances?.attendance_break_time > attendances?.shift_break_time &&
                    (types.length === 0 || filterExcessSearch)
                  ) {
                    result = [
                      ...result,
                      {
                        employee: { ...employee },
                        attendance: attendances,
                        type: 'BE'
                      }
                    ];
                  }
                  if (dates.find(date => date.date === attendances.date) === undefined) {
                    dates.push({ week_number: +attendances.week, date: attendances.date });
                  }
                });
              });
            });
            resolve({ overtimeMovements: result, allDates: dates });
          }
        });
      } else {
        resolve({ overtimeMovements: [], allDates: [] });
      }
    });
  };

  const handleImportOvertimes = filter => {
    const types = filter.filter_type;
    const searchValues = ['fifty', 'hundred', 'hundred_and_fifty'];
    const searchTypes = types.some(item => searchValues.includes(item));
    const filteredValues = types.filter(value => searchValues.includes(value));
    const request = () => {
      const { dateFrom, dateTo, filterByEmployee } = camelCaseEmptyStringRecursive(filter);
      const myParams = {
        date_from: dateFrom,
        date_to: dateTo,
        filter_type: filteredValues,
        filter_by_employee: filterByEmployee,
        sort_column: 'date',
        sort_direction: 'asc'
      };

      return new Promise((resolve, reject) => {
        if (types.length === 0 || searchTypes) {
          indexOvertimeRequest({
            dispatch,
            params: myParams,
            successCallback: response => {
              const { formated_employees: employeeFormated } = filter;
              const { data } = response.data;
              const vOvertimes = [];
              data.forEach(overtime => {
                const employee = employeeFormated.find(emp => emp.id === overtime.employee.id);
                vOvertimes.push({
                  ...overtime,
                  employee: { ...employee }
                });
              });
              resolve(vOvertimes);
            },
            failureCallback: error => {
              reject(error);
            }
          });
        } else {
          resolve([]);
        }
      });
    };

    const handleImportPermissions = params => {
      const myParams = {
        start_date_between: [params.date_from, params.date_to],
        date_from: params.date_from,
        filter_permission_type: 'without_pay',
        filter_for_hours: true,
        sort_column: 'start_date',
        sort_direction: 'asc',
        status: 'approved',
        filter_employee: params.filter_by_employee
      };

      const searchPermissions = ['without_pay'];
      const searchTypesPermissions = types.some(item => searchPermissions.includes(item));
      return new Promise((resolve, reject) => {
        if (types.length === 0 || searchTypesPermissions) {
          indexPermissionsRequest({
            dispatch,
            params: myParams,
            successCallback: response => {
              const { formated_employees: employeeFormated } = params;
              const { data } = response.data;
              const permissions = [];
              data.forEach(permission => {
                const employee = employeeFormated.find(emp => emp.id === permission.employee.id);

                permissions.push({
                  ...permission,
                  employee: { ...employee }
                });
              });
              resolve(permissions);
            },
            failureCallback: error => {
              reject(error);
            }
          });
        } else {
          resolve([]);
        }
      });
    };

    Promise.all([handleOvertimeDiscountsMovements(filter), request(filter), handleImportPermissions(filter)]).then(
      data => {
        const [reportData, overtimeData, permissionsData] = data;
        if (
          data &&
          ([overtimeData, permissionsData].some(item => item.length > 0) || reportData?.overtimeMovements.length > 0)
        ) {
          const { overtimeMovements, allDates } = data[0];
          const overtimeMovementsValues = makeOvertimeMovements(data, id);
          const discountsMovements = makeDiscountsMovements(overtimeMovements, id);
          const permissionsMovements = makePermissionsMovements(data, id);
          const overtimeDates = allDates.map(date => {
            const overtimeMovementsAttributes = [];
            overtimeMovementsAttributes.push(
              ...overtimeMovementsValues.filter(overtime => overtime.date === date.date)
            );
            overtimeMovementsAttributes.push(...discountsMovements.filter(discount => discount.date === date.date));
            overtimeMovementsAttributes.push(
              ...permissionsMovements.filter(permission => permission.date === date.date)
            );
            return {
              ...date,
              payroll_process_id: id,
              overtime_movements_attributes: overtimeMovementsAttributes
            };
          });
          setOnRequest(true);
          createOvertimeDiscountsMovementsRequest({
            dispatch,
            params: { overtime_discounts_movements: [...overtimeDates] },
            successCallback: () => handleSuccessImport('Movimientos importados correctamente'),
            failureCallback: error => handleFailureRequest(error, 'HH.EE/Descuentos ya existen'),
            callback: () => {
              setMoreData(!moreData);
              setOnRequest(false);
            }
          });
        } else {
          setSimpleModalShow(false);
          dispatch(sendAlert({ kind: 'error', message: 'No se encontraron descuentos para importar' }));
          setOnRequest(false);
        }
      }
    );
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInfo = <pre>{error?.response?.data?.message}</pre>;
      setModalTitle('Información Relevante');
      setModalShow(true);
      setModalBody(alertInfo);
      setModalAction(() => handleModalClose);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleImport = options => {
    switch (options) {
      case 'attendance':
        setSimpleModalTitle('Importar Horas Extras / Descuentos');
        setSimpleModalBody(
          <ImportOvertimeDiscountsMovements
            formRequest={handleImportOvertimes}
            payroll={payrollProcess}
            withAttendance={attendanceManagement}
          />
        );
        setSimpleModalShow(true);
        setSize('lg');
        break;
      case 'permissions':
        setSimpleModalTitle('Importar Permisos sin Goce');
        setSimpleModalBody(
          <ImportOvertimeDiscountsMovements
            formRequest={handleImportOvertimes}
            payroll={payrollProcess}
            withAttendance={attendanceManagement}
          />
        );
        setSimpleModalShow(true);
        setSize('lg');
        break;
      case 'data-loads':
        setSimpleModalTitle('Importar Horas Extras / Descuentos - Sin Control del Tiempo');
        setSimpleModalBody(
          <ImportModal
            onDropUploaded={preImportOvertimeDiscountsMovementsRequest}
            handleTemplate={importTemplateOvertimeDiscountsMovements}
            onHide={importOvertimeDiscountsMovementsRequest}
            hideModal={() => setSimpleModalShow(false)}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
            params={{
              payroll_process_id: id,
              active_contracts: true,
              active: true,
              company_id: currentCompany.id,
              filter_identification_type: 'rut',
              filter_termination_date_month: `${payrollProcess.month_number}-${payrollProcess.year}`,
              paginate: false
            }}
            service="payroll"
          />
        );
        setSimpleModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleProcess = item => {
    setOnRequest(true);
    processOvertimeDiscountsGroupsRequest({
      dispatch,
      params: {
        filter_id: [item.id]
      },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Movimiento procesado correctamente' }));
        setModalShow(false);
        setOnRequest(false);
        setMoreData(!moreData);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleMassiveProcess = () => {
    const movementIds = selectedRows.map(movement => movement.id);
    setOnRequest(true);
    processOvertimeDiscountsGroupsRequest({
      dispatch,
      params: {
        filter_id: movementIds
      },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Movimientos procesados correctamente' }));
        setClearSelectedRows(!clearSelectedRows);
        setModalShow(false);
        setOnRequest(false);
        setMoreData(!moreData);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleDestroyMovement = item => {
    setOnRequest(true);
    destroyOvertimeDiscountsGroupRequest(item.id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Movimiento eliminado correctamente' }));
        setModalShow(false);
        setMoreData(!moreData);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleMassiveDestroyAttendanceMovements = () => {
    const movementIds = selectedRows.map(movement => movement.id);
    setOnRequest(true);
    massiveDestroyOvertimeDiscountsGroupsRequest({
      dispatch,
      params: {
        filter_id: movementIds,
        payroll_process: id
      },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Movimientos eliminados correctamente' }));
        setClearSelectedRows(!clearSelectedRows);
        setModalShow(false);
        setMoreData(!moreData);
      },
      callback: () => setOnRequest(false)
    });
  };

  const downloadModal = (ids = []) => {
    setModalTitle('Exportar Movimiento de Horas Extras / Descuentos');
    setModalBody('¿Estás seguro que deseas descargar los movimientos de horas extras / descuentos?');
    setModalAction(() => handleExport);
    setModalItem(ids);
    setModalShow(true);
  };

  const handleButtonClick = (item, action) => {
    setModalItem([]);
    switch (action) {
      case 'edit':
        if (attendanceManagement) {
          setSimpleModalTitle('Horas Extras');
          setSimpleModalBody(
            <OvertimeDiscountsMovementsDatesEdit
              id={item.id}
              moreData={moreData}
              setMoreData={setMoreData}
              hideModal={() => setSimpleModalShow(false)}
              payrollDates={payrollDates}
              payroll={payrollProcess}
            />
          );
          setSimpleModalShow(true);
          setSize('xl');
        } else {
          history.push(`/overtime_discounts_movements/${item.id}/edit?returnTo=remuneration_processes&id=${id}`);
        }
        break;
      case 'process':
        setModalItem(item);
        setOption('test');
        setModalTitle('Procesar Movimiento');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas procesar el movimiento?');
        setModalAction(() => handleProcess);
        break;
      case 'download':
        {
          const ids = [item.id];
          downloadModal(ids);
        }
        break;
      case 'destroy':
        setModalTitle('Procesar Movimiento');
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas eliminar el movimiento?');
        setModalAction(() => handleDestroyMovement);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'export':
        {
          const ids = selectedRows.map(row => row.id);
          downloadModal(ids);
        }
        break;
      case 'process':
        setModalTitle('Procesar Movimientos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas procesar los movimientos seleccionados?');
        setModalAction(() => handleMassiveProcess);
        break;
      case 'destroy':
        setModalTitle('Eliminar Movimientos');
        setModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar los movimientos seleccionados?');
        setModalAction(() => handleMassiveDestroyAttendanceMovements);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'hour_value':
        return { sort_hour_value: name };
      default:
        return { sort_column: name };
    }
  };

  useEffect(handleShowRequest, [payrollStartDate]);

  return (
    <>
      <Col md={12}>
        <Row className="d-flex justify-content-end">
          <Col md={2} className="mt-2 mt-md-3">
            <Button variant="primary" block onClick={() => downloadModal()}>
              Exportar
            </Button>
          </Col>
          {!onlyShow && (
            <>
              <Col md={2}>
                <Dropdown alignRight className="attendance-movement-dropdown">
                  <Dropdown.Toggle className="dropdown-no-arrow mt-3 w-100">
                    Importar
                    <Icon style={{ marginLeft: 25 }} width={23} icon="chevron-down" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleImport(importAction)}>
                      {attendanceManagement ? 'Control del Tiempo' : 'Importar Permisos'}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleImport('data-loads')} disabled={attendanceManagement}>
                      Carga de Datos
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={2} className="mt-2 mt-md-3">
                <Button
                  variant="info"
                  block
                  to={`/overtime_discounts_movements/new?returnTo=remuneration_processes&id=${id}`}
                  as={attendanceManagement ? 'button' : Link}
                  disabled={attendanceManagement}
                >
                  Nuevo
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Col>
      <div className="position-relative">
        <Accordion defaultActiveKey="1" className="ml-2 mt-2 mr-2">
          <OvertimeDiscountsMovementsFilter formRequest={handleFilter} />
        </Accordion>
        <ComponentDataTable
          clearSelectedRows={clearSelectedRows}
          columns={ColumnsGroups(handleButtonClick, attendanceManagement, onlyShow)}
          data={overtimes}
          handleSortCase={sortColumnCase}
          massActions={
            <MassiveActionsGroup
              disabled={onlyShow || selectedRows.length === 0}
              onlyShow
              handleClick={handleMassAction}
              custoimClass="mb-4"
            />
          }
          moreData={moreData}
          onRequest={onRequest}
          onSelectedRowsChange={handleSelectedRows}
          resourceRequest={params => {
            handleRequest({ ...params, ...filters });
          }}
          selectableRows={!onlyShow}
          totalRows={amount}
          withMassActions
          withSearch={false}
        />
      </div>
      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        size={size}
        show={simpleModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem, option)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
      />
      <DefaultModal
        title={defaultModalTitle}
        body={defaultModalBody}
        show={defaultModalShow}
        handleClose={() => setDefaultModalShow(false)}
        withConfirm={false}
        titleBtnClose="Cerrar"
      />
    </>
  );
};

export default OvertimeDiscountIndex;
