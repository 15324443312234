import React from 'react';
import { ButtonToolbar, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';
import Icon from '../../Icons';

const InputMask = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  disabled,
  error,
  errorText,
  focused,
  helpText,
  id,
  label,
  margin,
  placeholder,
  readOnlyInput,
  size,
  tooltipText,
  touched,
  mask,
  maskChar,
  pattern,
  toolbarVariant,
  ...props
}) => (
  <Form.Group className={`${disabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}>
    {label && (
      <Form.Label className="d-flex align-items-center">
        {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        {tooltipText && (
          <ButtonToolbar className="ml-2">
            <OverlayTrigger
              key={direction}
              placement={direction}
              delay={{ show: delayShow, hide: delayHide }}
              overlay={<Tooltip>{tooltipText}</Tooltip>}
            >
              <Icon icon="help-circle" height="15px" width="15px" />
            </OverlayTrigger>
          </ButtonToolbar>
        )}
      </Form.Label>
    )}
    <ReactInputMask
      mask={mask}
      placeholder="00:00"
      maskChar={maskChar}
      pattern={pattern}
      title="Ingresa una hora válida entre 00:00 y 23:59"
      {...props}
    >
      {inputProps => <input className="form-control" {...inputProps} type="text" />}
    </ReactInputMask>
    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
);

InputMask.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  margin: PropTypes.string,
  toolbarVariant: PropTypes.string,
  tooltipText: PropTypes.string
};

InputMask.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'bottom',
  margin: '',
  toolbarVariant: '',
  tooltipText: ''
};

export default InputMask;
