import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { legalreportOffdaysReport } from '../../requests/legalreports';
import { ComponentDataTable } from '../index';

const OffdaysFilterDataTable = ({ customParams, moreData, columns }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [offdays, setOffdays] = useState([]);
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    setOffdays(response.data);
    setOnRequest(false);
  };

  const conditionalRowStyles = [
    {
      when: row => row.default_no.includes('Total'),
      style: {
        backgroundColor: '#e6e6e6 !important',
        marginBottom: 15
      }
    }
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '45px'
      }
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        borderLeft: '1px solid rgb(212, 212, 212)',
        borderRight: '1px solid rgb(212, 212, 212)',
        borderTop: '1px solid rgb(212, 212, 212)'
      }
    }
  };

  const handleOffdayReportRequest = params => {
    if (offdays.length > 0) {
      return;
    }
    setOnRequest(true);
    const request = async () =>
      legalreportOffdaysReport({
        dispatch,
        params: { ...params, ...customParams },
        successCallback: handleSuccessIndex
      });
    request();
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'parsed_time':
        return { sort_time: name };
      case 'employee_full_name':
        return { sort_employee_full_name: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        data={offdays}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        resourceRequest={handleOffdayReportRequest}
        pointerOnHover
        preName="offdays"
        noDataComponent="La jornada de este trabajador no incluye domingo y Festivos"
        withMassActions
        withSearch={false}
        expanded
        pagination
        paginationServer={false}
        conditionalRowStyles={conditionalRowStyles}
        customStyles={customStyles}
      />
    </>
  );
};

export default OffdaysFilterDataTable;
