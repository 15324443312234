import React from 'react';
import PropTypes from 'prop-types';
import { BasicTooltip } from '../../components';

const ShiftAssignmentStatus = ({ item, type }) => {
  const text = item?.status;
  const currentType = type ? 'text' : 'dot dot';
  const currentClass = text === 'pending' ? `${currentType}-danger` : `${currentType}-success`;
  const translatedText = text === 'pending' ? 'Pendiente' : 'Ejecutado';

  return (
    <>
      <BasicTooltip text={translatedText}>
        <span className={currentClass} data-tag="allowRowEvents">
          <b>{translatedText}</b>
        </span>
      </BasicTooltip>
    </>
  );
};

ShiftAssignmentStatus.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

ShiftAssignmentStatus.defaultProps = {};

export default ShiftAssignmentStatus;
