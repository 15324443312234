import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import { debounceIndexTerminationReasonsRequest } from '../../requests/terminationReasons';
import { FormikDatePicker, InputSelect } from '../../components';

const ContractTerminationModal = props => {
  const { onHide, isSubmitting, submitVariant, errors, touched, setFieldValue } = props;
  const [fieldNoticeDate, setFieldNoticeDate] = useState('');
  const [fieldEndDate, setFieldEndDate] = useState('');
  const dispatch = useDispatch();

  const fetchTerminationReasons = (inputValue, callback) => {
    debounceIndexTerminationReasonsRequest({
      dispatch,
      params: {
        name: inputValue,
        sort_column: 'name',
        sort_direction: 'asc'
      },
      successCallback: response => callback(response.data.data)
    });
  };

  return (
    <Form>
      <Modal.Body>
        <h4 className="mt-0 mb-4">
          La siguiente acción es irreversible, ¿está seguro que desea poner término al contrato?
        </h4>
        <Row>
          <Col>
            <Field name="contract[terminationReason]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Motivo de Egreso"
                  placeholder="Seleccionar Motivo de Egreso"
                  value={undefined}
                  request={fetchTerminationReasons}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="contract[terminationDate]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  isOutsideRange={day => day.isBefore(fieldNoticeDate)}
                  onDateChange={date => {
                    setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : '');
                    setFieldEndDate(date !== null ? date : '');
                  }}
                  label="Fecha de Término"
                  placeholder="dd/mm/aaaa"
                  tooltipText="Fecha en que concluye la relación laboral"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="contract[paymentDate]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  isOutsideRange={() => false}
                  label="Fecha de Pago"
                  placeholder="dd/mm/aaaa"
                  tooltipText="Fecha en que se realizará el pago del finiquito"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="contract[noticeDate]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  isOutsideRange={day => day.isAfter(fieldEndDate)}
                  onDateChange={date => {
                    setFieldValue(field.name, moment.isMoment(date) ? date.format('DD/MM/YYYY') : '');
                    setFieldNoticeDate(date !== null ? date : '');
                  }}
                  label="Fecha de Aviso"
                  placeholder="dd/mm/aaaa"
                  tooltipText="Fecha en que notifica al trabajador del término de contrato"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" disabled={isSubmitting} variant={submitVariant} block onClick={onHide}>
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { employeeIds } = props;
  return {
    contract: {
      noticeDate: '',
      terminationReason: '',
      terminationDate: ''
    },
    templatePdf: {
      employeeIds,
      documentType: 'work_settlement'
    }
  };
};

const validationSchema = Yup.object().shape({
  contract: Yup.object().shape(
    {
      noticeDate: Yup.date()
        .required('Debes seleccionar una fecha de aviso')
        .formatdate()
        .when(
          'terminationDate',
          (terminationDate, schema) =>
            terminationDate && schema.max(terminationDate, 'Debe ser menor o igual a la fecha de término')
        ),
      terminationDate: Yup.date()
        .required('Debes seleccionar una fecha de término')
        .formatdate()
        .when(
          'noticeDate',
          (noticeDate, schema) => noticeDate && schema.min(noticeDate, 'Debe ser mayor o igual a la fecha de aviso')
        ),
      terminationReason: Yup.string().required('Debes seleccionar un motivo de egreso')
    },
    [['noticeDate', 'terminationDate']]
  )
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(ContractTerminationModal);
