import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner, Row, Col } from 'react-bootstrap';
import { sendAlert } from '../../../actions/utils';
import { LinkBtn, Icon } from '../../../components';
import { showPayrollProcessRequest } from '../../../requests/payrollProcesses';
import OvertimeDiscountsMovementsForm from './OvertimeDiscountsMovementsForm';
import { createNoTimeControllOvertimeDiscountsMovementsRequest } from '../../../requests/overtimeDiscountsMovements';
import { showOvertimeGroupDetailsRequest, updateOvertimeGroupRequest } from '../../../requests/overtimeGroups';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const OvertimeDiscountsMovementsNew = ({ match }) => {
  const [onRequest, setOnRequest] = useState(false);
  const [payroll, setPayroll] = useState({});
  const [overtimeGroup, setOvertimeGroup] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const returnTo = new URLSearchParams(location.search).get('returnTo') || 'absences';
  const id = new URLSearchParams(location.search).get('id');
  const groupId = match?.params?.movement_group_id;
  const editing = !!groupId;

  const handleShowRequest = () => {
    setOnRequest(true);
    showPayrollProcessRequest(id, {
      dispatch,
      service: 'payroll',
      successCallback: res => {
        setPayroll(res.data);
        if (!editing) {
          setOnRequest(false);
        }
      }
    });
  };

  const handleOvertimeGroup = () => {
    if (editing) {
      showOvertimeGroupDetailsRequest(groupId, {
        dispatch,
        successCallback: res => {
          setOvertimeGroup(camelCaseEmptyStringRecursive(res.data));
          setOnRequest(false);
        }
      });
    }
  };

  const handleSuccessCreate = () => {
    const message = 'Movimientos creados con éxito';
    setOnRequest(false);
    history.push(`/${returnTo}/${id}/edit?tab=overtime-discounts`);
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const validateLength = odValues => {
    if (!odValues.overtimeDiscountsMovement.length) {
      dispatch(sendAlert({ kind: 'error', message: 'Debes seleccionar al menos un trabajador' }));
      return true;
    }
    return false;
  };

  const handleFailureRequest = error => {
    history.push(`/${returnTo}/${id}/edit?tab=overtime-discounts`);
    const { response } = error;
    setOnRequest(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleCreate = (odValues, setSubmitting) => {
    if (validateLength(odValues)) {
      setSubmitting(false);
      return;
    }
    const overtimeDiscountsMovementsValues = {
      overtimeDiscountsMovements: odValues.overtimeDiscountsMovement.map(movement => ({
        ...movement,
        payrollProcessId: id,
        date: movement.startDate,
        status: 'approved'
      }))
    };
    setOnRequest(true);
    const params = snakeCaseKeys(overtimeDiscountsMovementsValues);
    createNoTimeControllOvertimeDiscountsMovementsRequest({
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest,
      callback: () => {
        setSubmitting(false);
        setOnRequest(false);
      }
    });
  };

  const handleUpdate = (odValues, setSubmitting) => {
    setSubmitting(true);
    setOnRequest(true);
    const params = {
      overtime_discounts_movements_attributes:
        snakeCaseKeys(odValues.overtimeDiscountsMovement, { exclude: ['_destroy'] }) || []
    };
    updateOvertimeGroupRequest(groupId, {
      dispatch,
      params,
      successCallback: () => {
        setOnRequest(false);
        history.push(`/${returnTo}/${id}/edit?tab=overtime-discounts`);
        dispatch(sendAlert({ kind: 'success', message: 'Movimientos actualizados correctamente' }));
      },
      failureCallback: error => {
        const { response } = error;
        setOnRequest(false);
        dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
      }
    });
  };

  useEffect(handleShowRequest, []);
  useEffect(handleOvertimeGroup, []);

  return (
    <>
      {onRequest && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row className="mt-4 mb-4 align-items-center">
        <Col xs={1}>
          <LinkBtn
            variant="circle-dark"
            className="btn-circle mt-3"
            block
            to={`/${returnTo}/${id}/edit?tab=overtime-discounts&id=${id}`}
          >
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        {editing ? (
          <Col>
            <h2 className="text-uppercase mb-0 mt-3">Editar Horas Extras / Horas de Atraso</h2>
          </Col>
        ) : (
          <Col>
            <h2 className="text-uppercase mb-0 mt-3">Ingreso Horas Extras / Horas de Atraso</h2>
          </Col>
        )}
      </Row>
      {payroll.id && !editing && (
        <OvertimeDiscountsMovementsForm action="new" formRequest={handleCreate} payroll={payroll} />
      )}
      {editing && payroll?.id && overtimeGroup?.id && (
        <OvertimeDiscountsMovementsForm
          action="edit"
          formRequest={handleUpdate}
          payroll={payroll}
          overtimeGroup={overtimeGroup}
        />
      )}
    </>
  );
};

export default OvertimeDiscountsMovementsNew;
