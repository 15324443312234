import memoize from 'memoize-one';
import React from 'react';
import { DatatableEmployeeName } from '../../../components';
import SimpleActions from './SimpleActions';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';

export default memoize((clickHandler, onlyShow) => [
  {
    name: 'Trabajador',
    selector: 'full_name',
    cell: item => (
      <>
        <DatatableEmployeeName
          item={item?.employee}
          withPhoto={false}
          name="custom_name"
          startDateContract={item?.start_date_contract}
        />
      </>
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'Falta Injustificable',
    selector: 'unjustified_absence',
    sortable: true,
    grow: '1'
  },
  {
    name: 'Licencia Médica',
    selector: 'medical_license',
    grow: '1',
    sortable: true
  },
  {
    name: 'Permiso sin Goce',
    selector: 'without_pay',
    grow: '1',
    sortable: true
  },
  {
    name: 'Feriado Renunciable',
    selector: 'essential_holiday',
    grow: '1',
    sortable: true
  },
  {
    name: 'Acciones',
    selector: 'concept',
    cell: item => (
      <SimpleActions handleClick={clickHandler} item={camelCaseEmptyStringRecursive(item)} onlyShow={onlyShow} />
    ),
    grow: '1',
    center: true
  }
]);
