import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import { camelCaseEmptyStringRecursive, downloadFile } from '../../../services/utils';
import { showOvertimeGroupRequest, updateOvertimeGroupRequest } from '../../../requests/overtimeGroups';
import OvertimeDatesForm from './OvertimeDatesForm';
import { sendAlert } from '../../../actions/utils';
import { exportOvertimeDiscountsMovementsRequest } from '../../../requests/overtimeDiscountsMovements';

const OvertimeDiscountsMovementsDatesEdit = ({ id, moreData, setMoreData, hideModal }) => {
  const dispatch = useDispatch();
  const [overtimeGroup, setOvertimeGroup] = useState();
  const [onRequest, setOnRequest] = useState(true);

  const handleUpdateRequest = (benefitValues, setSubmitting) => {
    const myParams = snakecaseKeys(benefitValues);
    setOnRequest(true);
    updateOvertimeGroupRequest(id, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => {
        hideModal();
        setOnRequest(false);
        setMoreData(!moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Movimiento actualizado con éxito' }));
      },
      failureCallback: error => {
        setSubmitting(false);
        const { response } = error;
        setOnRequest(false);
        dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
      },
      callback: () => setMoreData(!moreData)
    });
  };

  const handleExportOvertimeMovementsRequest = action => {
    exportOvertimeDiscountsMovementsRequest({
      format: action,
      dispatch,
      params: { overtime_group_id: id },
      responseType: action !== '.docx' ? 'text' : 'blob',
      successCallback: response => {
        downloadFile(response);
      },
      failureCallback: () => {
        dispatch(sendAlert({ kind: 'error', message: 'No se pudo exportar el archivo' }));
      }
    });
  };

  const handleShowRequest = () => {
    setOnRequest(true);
    showOvertimeGroupRequest(id, {
      dispatch,
      successCallback: res => {
        setOvertimeGroup(camelCaseEmptyStringRecursive(res.data));
        setOnRequest(false);
      }
    });
  };

  useEffect(handleShowRequest, []);

  return (
    <>
      {onRequest && <Spinner className="spinner-modal fix-spinner" animation="border" variant="primary" />}
      <div className={`${onRequest && 'bg-opacity'}`}>
        {overtimeGroup && (
          <OvertimeDatesForm
            formRequest={handleUpdateRequest}
            overtimeGroup={overtimeGroup}
            handleExportMovements={handleExportOvertimeMovementsRequest}
          />
        )}
      </div>
    </>
  );
};

export default OvertimeDiscountsMovementsDatesEdit;
