import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Tab, Nav, Row, Col, Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import moment from 'moment';
import { useSetTab } from '../../../services/hooks';
import VacationCard from '../../Vacation/VacationCard';
import AbsenceDataTable from '../../../screens/Absence/AbsenceDataTable';
import PermissionDataTable from '../../../screens/Shared/Permission/PermissionDataTable';
import VacationDataTable from '../../../screens/Shared/Vacation/VacationDataTable';
import permissionColumns from '../../../screens/Shared/Permission/watchColumns';
import vacationColumns from '../../../screens/Shared/Vacation/watchColumns';
import { AttendanceDataTable, attendanceColumns, AttendanceNew } from '../../Attendance';
import { showEmployeeCardsRequest } from '../../../requests/vacations';
import { showEmployeePermissionsCardsRequest } from '../../../requests/permissions';
import PermissionCard from '../../../screens/Profile/Permission/PermissionCard';
import { AbilityContext } from '../../../config/abilityContext';
import { sendAlert } from '../../../actions/utils';
import AbsenceNew from '../../../screens/Absence/AbsenceNew';
import { SimpleCenteredModal } from '../../index';
import PermissionNew from '../../../screens/Profile/Permission/PermissionNew';
import VacationNew from '../../../screens/Profile/Vacation/VacationNew';

const EmployeeAssistanceTabs = ({ employee, permissions }) => {
  const [additionalVacationCard, setAdditionalVacationCard] = useState({});
  const [administrativeVacationCard, setAdministrativeVacationCard] = useState({});
  const { currentEmployee } = useSelector(state => state.auth);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const [excessAccumulatedPeriods, setExcessAccumulatedPeriods] = useState(false);
  const { id: employeeId } = employee;
  const [legalVacationCard, setLegalVacationCard] = useState({});
  const location = useLocation();
  const [progressiveVacationCard, setProgressiveVacationCard] = useState({});
  const [permissionCard, setPermissionCard] = useState({});
  const [keyModel, setKeyModel] = useState('Attendance');
  const [modalTitle, setModalTitle] = useState('');
  const [modalSubtitle, setModalSubtitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [todayMoment, setTodayMoment] = useState('');
  const [twoMonthsAgo, setTwoMonthsAgo] = useState('');

  const setModal = (title, body) => {
    setModalShow(false);
    setModalTitle(title);
    setModalBody(body);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleFailureRequest = message => {
    dispatch(sendAlert({ kind: 'error', message }));
    setMoreData(!moreData);
    handleModalClose();
  };
  const canAccessTab = index => permissions[index] || employee.id === currentEmployee;

  const defaultTab = () => {
    if (canAccessTab(0)) return 'attendance-datatable';
    if (canAccessTab(1)) return 'absent-datatable';
    if (canAccessTab(2)) return 'vacation-datatable';
    return 'permission-datatable';
  };

  const [key, setKey] = useSetTab(defaultTab || 'attendance-datatable', location, 'assistanceTab');
  const [newKey, setNewKey] = useState('perms-datatable');

  const handleSuccessShow = response => {
    const { data } = response;
    switch (key) {
      case 'attendance-datatable':
        break;
      case 'absent-datatable':
        break;
      case 'vacation-datatable':
        setAdditionalVacationCard(camelCaseRecursive(data.additional_days_vacation_card));
        setExcessAccumulatedPeriods(camelCaseRecursive(data.legal_vacation_card).excessAccumulatedPeriods);
        setLegalVacationCard(camelCaseRecursive(data.legal_vacation_card));
        setProgressiveVacationCard(camelCaseRecursive(data.progressive_vacation_card));
        break;
      case 'permission-datatable':
        setAdministrativeVacationCard(camelCaseRecursive(data.administrative_vacation_card));
        setPermissionCard(camelCaseRecursive(data.permission_card));
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Success Show Assistance tab request no key');
        break;
    }
  };

  const fetchEmployeeCards = () => {
    if (employee) {
      switch (key) {
        case 'vacation-datatable':
          showEmployeeCardsRequest({
            dispatch,
            params: {
              employee_id: employee.id
            },
            successCallback: handleSuccessShow
          });

          break;
        case 'permission-datatable':
          showEmployeePermissionsCardsRequest({
            dispatch,
            params: {
              employee_id: employee.id
            },
            successCallback: handleSuccessShow
          });

          break;
        default:
          // eslint-disable-next-line no-console
          console.warn('fetch card employee Assistance tab request no key');
          break;
      }
    }
  };

  const handleNewElement = () => {
    switch (key) {
      case 'attendance-datatable':
        setKeyModel('Attendance');
        setModal(
          'Agregar Asistencia',
          <AttendanceNew
            employeeId={employeeId}
            handleSuccessAction={handleSuccessAction}
            handleFailureAction={handleFailureRequest}
            handleModalClose={handleModalClose}
          />
        );
        setModalShow(true);
        break;
      case 'absent-datatable':
        setKeyModel('Absence');
        setModal(
          'Razón de Ausencia',
          <AbsenceNew
            employee={employee}
            handleSuccessAction={handleSuccessAction}
            handleFailureAction={handleFailureRequest}
            handleModalClose={handleModalClose}
          />
        );
        setModalShow(true);
        break;
      case 'vacation-datatable':
        setKeyModel('Vacation');
        setModal(
          'Agregar Vacaciones',
          <VacationNew
            employeeId={employeeId}
            onSuccess={handleSuccessAction}
            handleFailureAction={handleFailureRequest}
            handleModalClose={handleModalClose}
          />
        );
        setModalSubtitle(`Se encuentra solicitando vacaciones para: ${employee.fullName}`);
        setModalShow(true);
        break;
      case 'permission-datatable':
        setKeyModel('Permission');
        setModal(
          'Agregar Permisos',
          <PermissionNew
            employeeId={employeeId}
            onSuccess={handleSuccessAction}
            handleFailureAction={handleFailureRequest}
            handleModalClose={handleModalClose}
          />
        );
        setModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        setKeyModel('');
    }
  };

  useEffect(fetchEmployeeCards, [employee, key]);

  useEffect(() => {
    setTodayMoment(moment());
    setTwoMonthsAgo(moment().subtract(2, 'months'));
  }, []);

  return (
    <>
      <Row className="mt-3 mb-4 mr-2">
        {ability.can('create', keyModel) && key !== 'attendance-datatable' && (
          <Col md={{ span: 2, offset: 10 }}>
            <Button variant="primary" block onClick={() => handleNewElement()}>
              Nuevo
            </Button>
          </Col>
        )}
      </Row>
      <Tab.Container id="employee-assistance-tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills" className="ab-pills ml-2">
          {canAccessTab(0) && (
            <Nav.Item>
              <Nav.Link eventKey="attendance-datatable">Asistencia</Nav.Link>
            </Nav.Item>
          )}
          {canAccessTab(1) && (
            <Nav.Item>
              <Nav.Link eventKey="absent-datatable">Ausencia</Nav.Link>
            </Nav.Item>
          )}
          {canAccessTab(3) && (
            <Nav.Item>
              <Nav.Link eventKey="permission-datatable">Permisos</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          {canAccessTab(0) && (
            <Tab.Pane eventKey="attendance-datatable">
              <AttendanceDataTable
                columns={attendanceColumns}
                moreData={moreData}
                setMoreData={setMoreData}
                customParams={{ filter_by_employee: employeeId }}
                defaultStartDate={twoMonthsAgo}
                defaultEndDate={todayMoment}
              />
            </Tab.Pane>
          )}
          {canAccessTab(1) && (
            <Tab.Pane eventKey="absent-datatable">
              <AbsenceDataTable
                customParams={{ filter_by_employee: employeeId }}
                moreData={moreData}
                setMoreData={setMoreData}
                defaultStartDate={twoMonthsAgo}
                defaultEndDate={todayMoment}
              />
            </Tab.Pane>
          )}
          {canAccessTab(2) && (
            <Tab.Pane eventKey="vacation-datatable">
              <div className="d-block d-md-block">
                <Row className="mt-4">
                  <Col md={4} lg={4}>
                    <VacationCard
                      title="Vacaciones"
                      vacationCard={legalVacationCard}
                      excessAccumulatedPeriods={excessAccumulatedPeriods}
                      proportionalVacation
                      rrhh
                    />
                  </Col>
                  <Col md={4} lg={4}>
                    <VacationCard title="Vacaciones Progresivas" vacationCard={progressiveVacationCard} />
                  </Col>
                  <Col md={4} lg={4}>
                    <VacationCard title="Vacaciones Adicionales" vacationCard={additionalVacationCard} />
                  </Col>
                </Row>
              </div>

              <VacationDataTable
                columns={vacationColumns}
                moreData={moreData}
                setMoreData={setMoreData}
                customParams={{
                  employee_vacation: employee.id,
                  filter_vacation_exception: 'administrative_days, administrative_days_openning_balance'
                }}
              />
            </Tab.Pane>
          )}
          {canAccessTab(3) && (
            <Tab.Pane eventKey="permission-datatable">
              <div className="d-block d-md-block">
                <Row className="mt-4">
                  <Col md={6}>
                    <PermissionCard className="flex-fill" permissionCard={permissionCard} />
                  </Col>
                  <Col md={6}>
                    <VacationCard
                      className="flex-fill"
                      title="Días Administrativos"
                      vacationCard={administrativeVacationCard}
                    />
                  </Col>
                </Row>
              </div>
              <Tab.Container
                id="permissions-datatable.tabs"
                activeKey={newKey}
                mountOnEnter
                onSelect={k => setNewKey(k)}
              >
                <Nav variant="pills" className="ab-pills">
                  <Nav.Item>
                    <Nav.Link eventKey="perms-datatable">Permisos</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="administrative-datatable">Días Administrativos</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="perms-datatable">
                    <PermissionDataTable
                      columns={permissionColumns}
                      moreData={moreData}
                      setMoreData={setMoreData}
                      customParams={{ filter_employee: employee.id }}
                      defaultStartDate={twoMonthsAgo}
                      defaultEndDate={todayMoment}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="administrative-datatable">
                    <VacationDataTable
                      columns={permissionColumns}
                      moreData={moreData}
                      setMoreData={setMoreData}
                      preName="administrative_days"
                      customParams={{
                        filter_employee: employee.id,
                        filter_vacation_type: 'administrative_days, administrative_days_openning_balance',
                        sort_status: 'status'
                      }}
                      defaultStartDate={twoMonthsAgo}
                      defaultEndDate={todayMoment}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
      <SimpleCenteredModal
        title={modalTitle}
        subtitle={modalSubtitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
      />
    </>
  );
};

export default EmployeeAssistanceTabs;
