import React from 'react';

const OffdayReportToPrint = React.forwardRef(({ data }, ref) => {
  const arialStyle = {
    fontFamily: 'Arial',
    fontSize: '8px'
  };

  const selectedStyle = {
    backgroundColor: '#F89406',
    color: '#000000',
    fontFamily: 'Arial',
    fontSize: '8px'
  };

  const headerStyle = {
    backgroundColor: '#EBEBEB',
    fontWeight: 'bold',
    fontFamily: 'Arial',
    fontSize: '8px'
  };

  const selectedEndStyle = {
    backgroundColor: '#D3D3D3',
    color: '#000000',
    fontFamily: 'Arial',
    fontSize: '8px'
  };

  return (
    <div className="print_report" ref={ref} style={arialStyle}>
      {data.length > 0 && (
        <>
          {data.map(employee => (
            <>
              <h3>Reporte de días domingo y/o días festivos</h3>
              <p>Empresa: {data[0].company_name}</p>
              <p>RUT: {data[0].company_rut}</p>
              <p>
                Rango seleccionado de fechas: {data[0].dates[0]} - {data[0].dates[1]}
              </p>
              <br />
              <p style={{ marginTop: '12px' }}>{employee.employee_name}</p>
              <p>RUT: {employee.employee_rut}</p>
              <p>Lugar Prestación de Servicio: {employee.branch_offices_name}</p>
              <p>Cargo: {employee.job_title}</p>
              <table className="table-print table-sm table-bordered" style={arialStyle}>
                <tbody>
                  <tr style={headerStyle}>
                    <th className="text-center align-middle">Descanso Dominical Adicional</th>
                    <th className="text-center align-middle">Fecha</th>
                    <th className="text-center align-middle">Asistencia</th>
                    <th className="text-center align-middle">Ausencia</th>
                    <th className="text-center align-middle">Observaciones</th>
                  </tr>
                  {employee.records
                    .filter(record => record.default_no !== 'Total Final: ')
                    .map(record => (
                      <>
                        <tr>
                          {!record.default_no.includes('Total') && (
                            <>
                              <td className="text-center align-middle">{record.default_no}</td>
                              <td className="text-center align-middle">{record.date}</td>
                              <td className="text-center align-middle">{record.attendance}</td>
                              <td className="text-center align-middle">{record.absence}</td>
                              <td
                                className="text-center align-middle"
                                style={record.justification === 'Marcación fuera de turno' ? selectedStyle : null}
                              >
                                {record.justification}
                              </td>
                            </>
                          )}
                          {record.default_no.includes('Total') && (
                            <>
                              <td
                                colSpan="2"
                                className="align-middle"
                                style={{ textAlign: 'right', fontWeight: 'bold' }}
                              >
                                {record.default_no}
                              </td>
                              <td className="text-center align-middle">{record.attendance}</td>
                              <td className="text-center align-middle">{record.absence}</td>
                              <td className="text-center align-middle">&nbsp;</td>
                            </>
                          )}
                        </tr>
                      </>
                    ))}
                  {employee.records
                    .filter(record => record.default_no === 'Total Final: ')
                    .map(record => (
                      <>
                        <tr style={selectedEndStyle}>
                          <td colSpan="2" className="align-middle" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {record.default_no}
                          </td>
                          <td className="text-center align-middle">{record.attendance}</td>
                          <td className="text-center align-middle">{record.absence}</td>
                          <td className="text-center align-middle">&nbsp;</td>
                        </tr>
                      </>
                    ))}
                  {employee.records.length === 0 && (
                    <>
                      <tr>
                        <th className="text-center align-middle" colSpan={5}>
                          La jornada de este trabajador no incluye domingos y festivos
                        </th>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </>
          ))}
        </>
      )}
    </div>
  );
});

export default OffdayReportToPrint;
