import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexOvertimeDiscountsGroupsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  service = 'payroll'
}) => {
  ApiService.request('get', '/overtime_groups', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service
  });
};

export const processOvertimeDiscountsGroupsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  service = 'payroll'
}) =>
  ApiService.request('post', `/overtime_groups/process_payrolls`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service
  });

export const massiveDestroyOvertimeDiscountsGroupsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  service = 'payroll'
}) =>
  ApiService.request('post', `/overtime_groups/massive_destroy`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service
  });

export const updateOvertimeGroupRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, service = 'payroll' }
) =>
  ApiService.request('put', `/overtime_groups/${id}`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    service
  });

export const destroyOvertimeDiscountsGroupRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, service = 'payroll' }
) => {
  ApiService.request('delete', `/overtime_groups/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service
  });
};

export const showOvertimeGroupRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, service = 'payroll' }
) =>
  ApiService.request('get', `/overtime_groups/${id}`, { dispatch, params, failureCallback, successCallback, service });

export const showOvertimeGroupDetailsRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, service = 'payroll' }
) =>
  ApiService.request('get', `/overtime_groups/${id}/details`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service
  });

export const exportOvertimeDiscountsGroupsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  service = 'payroll'
}) => {
  ApiService.request('get', `/overtime_groups/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    service
  });
};

export const debounceIndexOvertimeDiscountsGroupsRequest = AwesomeDebouncePromise(
  indexOvertimeDiscountsGroupsRequest,
  300
);
