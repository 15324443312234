import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormikInput, FormikRangePicker, FormikSelect, InputSelect, SimpleCenteredModal } from '../../../components';
import { debounceIndexEmployeesRequest, debounceIndexSelectEmployeesRequest } from '../../../requests/employees';
import { indexJobTitleRequest } from '../../../requests/jobTitles';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { debounceIndexShiftsRequest } from '../../../requests/shifts';
import { workSchedules } from './Options';

const LegalReportFilter = props => {
  const { errors, touched, setFieldValue, values, filterRRHH = true, filterOffday = true } = props;
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const fetchEmployees = (inputValue, callback) => {
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 100
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchEmployee = (inputValue, callback) => {
    debounceIndexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 100
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchJobTitles = (inputValue, callback) => {
    indexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchShifts = (inputValue, callback) => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
  };

  return (
    <Form>
      <Card>
        {filterRRHH && (
          <>
            <Col md={12} className="mb-n5">
              <Card.Body>
                <Row>
                  <Col>
                    <h5 className="text-uppercase">Busqueda Individual</h5>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button variant="warning" onClick={() => setModalShow(true)}>
                      Glosario de Abreviaciones
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field name="employees">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          label="Nombre y Apellido"
                          placeholder="Seleccione colaboradores"
                          values={values.filter_id}
                          request={fetchEmployee}
                          onChange={data => setFieldValue('filter_id', data ? data.value : '')}
                          error={getIn(errors, 'filter_id')}
                          touched={getIn(touched, 'filter_id')}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="filter_national_id">
                      {({ field }) => (
                        <FormikInput
                          {...field}
                          label="RUT"
                          placeholder="Sin puntos (ej: 11111111-1)"
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Col>
            <Col md={12} className="mb-n5">
              <Card.Body>
                <h5 className="text-uppercase">Busqueda Grupal</h5>
                <Row>
                  <Col md={12}>
                    <Field name="employees">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          label="Nombres y Apellidos"
                          isMulti
                          placeholder="Seleccione colaboradores"
                          values={values.filter_ids}
                          request={fetchEmployees}
                          onChange={data => {
                            handleNewMulti(data || [], 'filter_ids', true);
                            setFieldValue(field.name, data || []);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Col>
            <Col md={12} className="mb-n5">
              <Card.Body>
                {filterOffday && (
                  <Row>
                    <Col>
                      <h5 className="text-uppercase">Periodo Predeterminado</h5>
                      <Col>
                        <Field name="range_date">
                          {({ field }) => (
                            <FormikSelect
                              {...field}
                              label="Periodo"
                              options={workSchedules}
                              onChange={data => setFieldValue(field.name, data ? data.value : '')}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                            />
                          )}
                        </Field>
                      </Col>
                    </Col>
                    <Col>
                      <h5 className="text-uppercase">Rango de Fechas</h5>
                      <Row>
                        <Col>
                          <Field name="range_date">
                            {({ field }) => (
                              <FormikRangePicker
                                {...field}
                                abbr
                                startDateName="date_from"
                                endDateName="date_to"
                                startDate={values.date_from}
                                endDate={values.date_to}
                                showClearDates
                                error={getIn(errors, field.name)}
                                touched={getIn(touched, field.name)}
                              />
                            )}
                          </Field>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Col>
            <Col md={12} className="mb-n5">
              <Card.Body>
                <h5 className="text-uppercase">Busqueda Grupal Listado</h5>
                <Row>
                  <Col>
                    <Field name="shifts">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          label="Turnos"
                          isMulti
                          placeholder="Seleccione Turno"
                          values={values.filter_active_shifts}
                          request={fetchShifts}
                          onChange={data => {
                            handleNewMulti(data || [], 'filter_active_shifts', true);
                            setFieldValue(field.name, data || []);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="branch_offices">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          label="Lugar de Prestacion de Servicios"
                          isMulti
                          placeholder="Seleccione Lugar de Prestación de Servicios"
                          values={values.filter_active_branch_offices}
                          request={fetchBranchOffices}
                          onChange={data => {
                            handleNewMulti(data || [], 'filter_active_branch_offices', true);
                            setFieldValue(field.name, data || []);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Col>
            <Col md={12} className="mb-n5">
              <Card.Body>
                <h5 className="text-uppercase">Cargo o Funcion</h5>
                <Row>
                  <Col>
                    <Field name="job_titles">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          isMulti
                          placeholder="Seleccione cargo"
                          values={values.filter_active_job_titles}
                          request={fetchJobTitles}
                          onChange={data => {
                            handleNewMulti(data || [], 'filter_active_job_titles', true);
                            setFieldValue(field.name, data || []);
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Col>
          </>
        )}
        <Row className="d-flex justify-content-end mt-4">
          <Col md={2}>
            <Button className="btn-block" type="submit">
              Filtrar
            </Button>
          </Col>
        </Row>
        <SimpleCenteredModal
          title="Glosario de Abreviaciones"
          body={
            <Card>
              <Card.Body>
                <Col md={12} className="mb-5">
                  <Row>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">A.I</p> Ausencia
                      Injustificada
                    </Col>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">A.J</p> Ausencia
                      Justificada
                    </Col>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">A.T</p> Atraso
                    </Col>
                    <Col md={4}>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">D.E.J</p>{' '}
                      Distribucion Excepcional de Jornada
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="mb-5">
                  <Row>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">H.E</p> Horas
                      Extraordinarias
                    </Col>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">J.O</p> Jorndada
                      Ordinaria
                    </Col>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">L.M</p> Licencia
                      Medica
                    </Col>
                    <Col md={4}>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">P.G.R</p>{' '}
                      Permiso con goce de Remuneraciones
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="mb-5">
                  <Row>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">C.T</p> Cambio
                      de Turno
                    </Col>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">PREN</p>{' '}
                      Prenatal
                    </Col>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">POSTN</p>{' '}
                      Postnatal
                    </Col>
                    <Col md={4}>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">P.S.G.R</p>{' '}
                      Permiso sin goce de Remuneraciones
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="mb-5">
                  <Row>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">VAC</p>{' '}
                      Vacaciones
                    </Col>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-primary rounded-circle text-white text-center">S.A</p> Salida
                      Anticipada
                    </Col>
                    <Col>
                      <p className="d-inline p-2 w-25 bg-warning rounded-circle text-white text-center">. . .</p>{' '}
                      Marcaciones Agregadas
                    </Col>
                    <Col md={4}>
                      <p className="d-inline p-2 w-25 bg-success rounded-circle text-white text-center">. . .</p>{' '}
                      Marcaciones Modificadas
                    </Col>
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          }
          size="xl"
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Card>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  range_date: Yup.array().when('filter_offday', {
    is: true,
    then: Yup.array().rangedate(true)
  })
});

const setInitialValues = props => {
  const { filterOffday = true } = props;
  return {
    date_from: '',
    date_to: '',
    range_date: [],
    filter_id: '',
    filter_ids: [],
    filter_national_id: '',
    filter_active_job_managements: [],
    filter_active_job_titles: [],
    filter_active_shifts: [],
    filter_active_branch_offices: [],
    filter_work_schedule: '',
    filter_offday: filterOffday
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(LegalReportFilter);
