import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'react-bootstrap';

import '../../../services/yupCustomMethods';
import { FormikInput, FormikRangePicker, FormikSelect, InputSelect } from '../../../components';
import { typeUnavailableVacationRequest, showEmployeeCardsRequest } from '../../../requests/vacations';
import { workdaysBetweenHolidayRequest } from '../../../requests/holidays';
import { indexSelectEmployeesRequest } from '../../../requests/employees';
import vacationTypes from './FormOptions';

const VacationForm = ({ errors, isSubmitting, setFieldTouched, setFieldValue, touched, values, ...props }) => {
  const { action, onHide, rrhh, vacation } = props;
  const [typeUnavailable, setTypeUnavailable] = useState(false);
  const [vacationDays, setVacationDays] = useState(0);
  const [vacationTypeSelected, setVacationTypeSelected] = useState('');
  const [labelVacationTypeSelected, setLegalVacationTypeSelected] = useState('');
  const { advanceSettings } = useSelector(state => state.utils);
  const dispatch = useDispatch();
  const vacationUnavailable = `No tienes vacaciones ${labelVacationTypeSelected.toLowerCase()} disponibles`;
  const { startDate, endDate, middayStart, middayEnd } = values.vacation;
  const { vacationType, employeeId } = vacation;
  const btnMessage = action === 'new' ? 'Solicitar' : 'Guardar';
  const [selectedEmployeeVacations, setSelectedEmployeeVacations] = useState({});
  const [consultingEmployeeId, setConsultingEmployeeId] = useState(employeeId);
  const [disabledButton, setDisabledButton] = useState(true);
  const { quantityDaysAvailable } = values.vacation;

  const calculateDays = () => {
    if (startDate && endDate) {
      workdaysBetweenHolidayRequest({
        dispatch,
        params: {
          start_date: startDate,
          end_date: endDate,
          midday_start: middayStart,
          midday_end: middayEnd,
          employee_id: consultingEmployeeId
        },
        successCallback: response => {
          const daysResults = response.data;
          setDisabledButton(quantityDaysAvailable < daysResults);
          setVacationDays(daysResults);
          setFieldValue('days', daysResults);
          setFieldTouched('days');
        }
      });
    }
  };

  const checkVacationTypeUnavailable = () => {
    typeUnavailableVacationRequest({
      dispatch,
      params: {
        days: vacationDays,
        vacation_type: vacationTypeSelected,
        employee_id: values.vacation.employeeId
      },
      successCallback: response => setTypeUnavailable(response.data)
    });
  };

  const checkUnavailable = () => {
    if (vacationTypeSelected && rrhh) {
      setTypeUnavailable(false);
    } else if (vacationTypeSelected && !rrhh) {
      checkVacationTypeUnavailable();
    }
  };

  const fetchEmployees = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: { active: true, filter_name: inputValue, paginate: false },
      successCallback: response => callback(response.data.data)
    });
  };

  const fetchEmployeeVacations = () => {
    if (typeof consultingEmployeeId !== 'undefined') {
      showEmployeeCardsRequest({
        dispatch,
        params: { employee_id: consultingEmployeeId },
        successCallback: response => setSelectedEmployeeVacations(response.data)
      });
    }
  };

  useEffect(calculateDays, [endDate, middayEnd, middayStart, startDate]);
  useEffect(checkUnavailable, [vacationDays, vacationTypeSelected, values.vacation?.employeeId]);
  useEffect(fetchEmployeeVacations, [consultingEmployeeId]);

  const vacationTypesFiltered = [...vacationTypes];

  const filterVacationByType = (item, code) => {
    const asCheck = advanceSettings.filter(asItem => asItem.code === code)[0].textValue === 'false';
    if (asCheck) vacationTypesFiltered.splice(vacationTypesFiltered.indexOf(item), 1);
  };

  vacationTypes.forEach(item => {
    if (!rrhh) {
      if (item.value === 'administrative_days') filterVacationByType(item, 'administrative_days');
      if (item.value === 'legal') filterVacationByType(item, 'legal_holiday');
      if (item.value === 'progressive') filterVacationByType(item, 'progressive_holiday');
      if (item.value === 'additional_days') filterVacationByType(item, 'additional_days');
    }
  });

  return (
    <Form>
      {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row>
        {rrhh && (
          <Col md={12}>
            <Field name="vacation[employeeId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Trabajador"
                  placeholder="Seleccionar Trabajador"
                  values={values}
                  model={[vacation, 'employee']}
                  request={fetchEmployees}
                  onChange={data => {
                    setConsultingEmployeeId(data.value);
                    setFieldValue(field.name, data ? data.value : '');
                    setFieldValue('vacation[vacationType]', '');
                    setFieldValue('vacation[quantityDaysAvailable]', '-');
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}

        <Col md={8}>
          <Field name="vacation[vacationType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Vacaciones"
                placeholder="Seleccionar Tipo de Vacaciones"
                options={vacationTypesFiltered}
                defaultValue={vacationType}
                onChange={data => {
                  if (data) {
                    const employeeVacationByType = Object.keys(selectedEmployeeVacations).find(objectKey =>
                      objectKey.includes(data.value)
                    );
                    if (typeof employeeVacationByType !== 'undefined') {
                      setFieldValue(
                        'vacation[quantityDaysAvailable]',
                        selectedEmployeeVacations[employeeVacationByType].vacations_available
                      );
                    } else {
                      setFieldValue('vacation[quantityDaysAvailable]', '-');
                    }
                  }
                  setVacationTypeSelected(data ? data.value : '');
                  setLegalVacationTypeSelected(data ? data.label : '');
                  setFieldValue(field.name, data ? data.value : '');
                }}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={
                  getIn(errors, field.name) ||
                  (typeUnavailable && `${vacationUnavailable}, debes seleccionar otro tipo de vacaciones`)
                }
                touched={getIn(touched, field.name) || typeUnavailable}
              />
            )}
          </Field>
        </Col>

        <Col className="align-self-center" md={4}>
          <div className="form-group disabled">
            <p>Cantidad de días acumulados: {quantityDaysAvailable}</p>
          </div>
        </Col>

        <Col md={10}>
          <Field name="rangeDate">
            {({ field }) => (
              <FormikRangePicker
                name={field.name}
                abbr
                minCurrentDate={!rrhh}
                startDateName="vacation[startDate]"
                endDateName="vacation[endDate]"
                startDate={startDate}
                endDate={endDate}
                showClearDates
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="days">
            {({ field }) => (
              <FormikInput
                {...field}
                disabled
                inputType="number"
                label="Total Días"
                value={vacationDays}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mt-3 mb-1">
        <Col md={4}>
          <Button
            type="submit"
            disabled={isSubmitting || (rrhh ? false : typeUnavailable) || disabledButton}
            variant="primary"
            block
            onClick={onHide}
          >
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => ({
  vacation: props.vacation,
  rangeDate: []
});

const validationSchema = Yup.object().shape({
  days: Yup.number()
    .positive('Debe ser mayor a 0')
    .test('takenDayAnalysis', 'La cantidad de días solicitados es mayor a la disponible', function validate(value) {
      return value <= parseFloat(this.parent.vacation.quantityDaysAvailable);
    }),
  vacation: Yup.object().shape({
    employeeId: Yup.string().required('Debes seleccionar un trabajador'),
    endDate: Yup.date().formatdate(),
    middayEnd: Yup.boolean(),
    middayStart: Yup.boolean(),
    startDate: Yup.date().formatdate(),
    vacationType: Yup.string().required('Debes seleccionar un tipo de vacaciones')
  }),
  rangeDate: Yup.array().rangedate(true)
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(VacationForm);
