import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAttendanceMovementsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) => {
  ApiService.request('get', '/attendance_movements', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service
  });
};

export const showAttendanceMovementRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, service = 'payroll' }
) =>
  ApiService.request('get', `/attendance_movements/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service
  });

export const createAttendanceMovementsRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('post', '/attendance_movements', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const updateAttendanceMovementRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, service = 'payroll' }
) =>
  ApiService.request('put', `/attendance_movements/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    service
  });

export const destroyAttendanceMovementsRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, service = 'payroll' }
) => {
  ApiService.request('delete', `/attendance_movements/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    service
  });
};

export const massiveDestroyAttendanceMovementsRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('post', '/attendance_movements/massive_destroy', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const preImportAttendanceMovementRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  service = 'payroll'
}) => {
  ApiService.request('get', '/attendance_movements/pre_import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    service
  });
};

export const importAttendanceMovementRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  service = 'payroll'
}) => {
  ApiService.request('post', '/attendance_movements/import', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    service
  });
};

export const changeStatusAttendanceMovementsRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('post', '/attendance_movements/change_status', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const exportAttendanceMovementsRequest = ({
  dispatch,
  params = {},
  format = '.xlsx',
  failureCallback,
  successCallback,
  responseType,
  service = 'payroll'
}) => {
  ApiService.request('get', `/attendance_movements/export${format}`, {
    dispatch,
    params,
    responseType,
    failureCallback,
    successCallback,
    service
  });
};

export const importTemplateAttendanceMovements = ({ dispatch, params, failureCallback, successCallback }) => {
  ApiService.request('get', '/attendance_movements/import_template', {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    service: 'payroll'
  });
};

export const debounceIndexAttendanceMovementsRequest = AwesomeDebouncePromise(indexAttendanceMovementsRequest, 300);
