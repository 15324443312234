import moment from 'moment';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const makeOvertimeMovements = (data, id) => {
  return data[1].map(overtime => ({
    ...overtime,
    payroll_process_id: id,
    start_date: overtime.date,
    date: moment(overtime.date, 'DD/MM/YYYY').format('YYYY-MM-DD')
  }));
};

const makeDiscountsMovements = (overtimeMovements, id) => {
  return overtimeMovements.map(discount => {
    const attendance = camelCaseEmptyStringRecursive(discount.attendance);
    const { anomaliesPgrDepartureTime } = attendance;
    const { anomaliesPgrArrivalTime } = attendance;
    const { attendanceBreakTime, shiftBreakTime } = attendance;
    const extraTime = attendance.hoursExtraTime;
    const { type } = discount;
    const params = {
      hours: 0,
      minutes: 0,
      hourValue: ''
    };

    if (type === 'HE') {
      params.hours = Math.floor(extraTime / 3600);
      params.minutes = Math.floor((extraTime % 3600) / 60);
      params.hourValue = 3;
    }

    if (anomaliesPgrDepartureTime > 0 && type === 'SA') {
      params.hours = Math.floor(anomaliesPgrDepartureTime / 3600);
      params.minutes = Math.floor((anomaliesPgrDepartureTime % 3600) / 60);
      params.hourValue = 4;
    }

    if (anomaliesPgrArrivalTime > 0 && type === 'AT') {
      params.hours = Math.floor(anomaliesPgrArrivalTime / 3600);
      params.minutes = Math.floor((anomaliesPgrArrivalTime % 3600) / 60);
      params.hourValue = 5;
    }

    if (type === 'BE') {
      const breakTimeExcess = Math.floor(attendanceBreakTime - shiftBreakTime);
      params.hours = Math.floor(breakTimeExcess / 3600);
      params.minutes = Math.floor((breakTimeExcess % 3600) / 60);
      params.hourValue = 7;
    }

    const startDate = discount.attendance.date || '';
    const mappedDiscount = {
      ...discount,
      payroll_process_id: id,
      start_date: startDate,
      date: startDate,
      hours: params.hours,
      minutes: params.minutes,
      hour_value: params.hourValue
    };

    return mappedDiscount;
  });
};

const makePermissionsMovements = (data, id) => {
  return data[2].map(permission => {
    const startDate = permission.start_date || '';
    const startTime = moment(permission.start_time, 'HH:mm:ss');
    const endTime = moment(permission.end_time, 'HH:mm:ss');
    const duration = moment.duration(endTime.diff(startTime));
    const hours = duration.hours();
    const minutes = duration.minutes();

    const mappedPermission = {
      ...permission,
      payroll_process_id: id,
      start_date: startDate,
      date: startDate,
      hours,
      minutes,
      hour_value: 6,
      status: 'approved'
    };

    return mappedPermission;
  });
};

export const prepareDatesTosave = ({ overtimeGroup }) => {
  const overtimeDiscountProp = [
    'beginJourney',
    'endJourney',
    'calculatedOvertime',
    'hoursLate',
    'earlyDeparture',
    'breakTimeExcess'
  ];

  const hourValuesGroup = {
    hoursLate: 'hours_late',
    earlyDeparture: 'early_departure_hours',
    breakTimeExcess: 'break_time_excess',
    calculatedOvertime: 'calculated_overtime'
  };

  const overtimeTypes = {
    '50%': 'fifty',
    '100%': 'hundred',
    '150%': 'hundred_and_fifty'
  };

  const { weekData, employee } = overtimeGroup;
  return weekData
    .map(week => {
      return week
        .map(weekDate => {
          return overtimeDiscountProp
            .map(prop => {
              const time = weekDate[prop];
              const { hours, minutes, id } = time;

              const isCalculated = prop === 'calculatedOvertime';
              const isOvertimeType = ['beginJourney', 'endJourney'].find(overtime => overtime === prop);
              const hourValue = isOvertimeType ? overtimeTypes[time.factor] : hourValuesGroup[prop];
              return {
                hasRegister: hours || minutes || id,
                ...time,
                hourValue,
                employee,
                startDate: time.date,
                calculatedOvertime: isCalculated
              };
            })
            .filter(item => item.hasRegister);
        })
        .filter(item => item.length)
        .flat();
    })
    .flat();
};

export { makeDiscountsMovements, makePermissionsMovements, makeOvertimeMovements };
