import React from 'react';
import { BasicDropdown, Icon } from '../../../../components';

export default function MassiveActions({ handleDownloadAttendanceDetails }) {
  const actions = () => {
    const authorizedActions = [];
    authorizedActions.push(
      {
        icon: <Icon icon="file-earmark-word" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'word',
        props: {
          onClick: () => handleDownloadAttendanceDetails('word')
        },
        title: 'Word'
      },
      {
        icon: <Icon icon="file-earmark-pdf" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'pdf',
        props: {
          onClick: () => handleDownloadAttendanceDetails('pdf')
        },
        title: 'Pdf'
      },
      {
        key: 'Excel',
        title: `Excel`,
        icon: <Icon icon="file-earmark-excel" height="16px" width="16px" style={{ marginRight: 5 }} />,
        props: {
          onClick: () => handleDownloadAttendanceDetails('excel')
        }
      }
    );

    return authorizedActions;
  };

  return (
    <div className="d-flex mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Descargar <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
}
