import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import ability from '../../config/ability';
import { debounceTodayAbsencesReport } from '../../requests/todayAbsences';
import ComponentDataTable from '../Utils/DataTable';
import { DatatableEmployeeNameAttendance } from '..';

const AttendanceDataTableStyled = ({ employeesBelongBoss, filterKey, moreData }) => {
  const unjustifiedColumns = memoizeOne(() => [
    {
      name: 'NOMBRE DEL TRABAJADOR',
      selector: 'employee',
      cell: item => <DatatableEmployeeNameAttendance item={item} />,
      sortable: false,
      grow: '1',
      width: '33%',
      align: 'left'
    },
    {
      name: 'TIPO',
      selector: 'absence',
      cell: item => {
        return item.records[0].justification;
      },
      sortable: false,
      grow: '1',
      width: '33%'
    },
    {
      name: 'TURNO',
      selector: 'shift',
      cell: item => {
        return `${item.records[0].shift_name} (${item.records[0].shift_schedule_type})`;
      },
      sortable: false,
      grow: '1',
      width: '33%'
    }
  ]);

  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(false);
  const today = moment().format('DD/MM/YYYY');
  const [rowsDataPrint, setRowsDataPrint] = useState([]);
  const [amount, setAmount] = useState(0);

  const handleAttendanceReportRequest = params => {
    setOnRequest(true);
    const customParams = {
      filter_by_employee: employeesBelongBoss,
      date_range: [today, today],
      filterKey,
      ...params
    };
    debounceTodayAbsencesReport({
      dispatch,
      params: customParams,
      format: '.datatable',
      successCallback: response => {
        setRowsDataPrint(response.data.data);
        setAmount(response?.data?.metadata?.amount || 0);
      },
      callback: () => setOnRequest(false)
    });
  };

  return (
    <ComponentDataTable
      onRequest={onRequest}
      columns={unjustifiedColumns()}
      data={rowsDataPrint}
      totalRows={amount}
      withSearch={false}
      moreData={moreData}
      resourceRequest={handleAttendanceReportRequest}
      withMassActions={ability.can('index', 'AttendanceReport')}
    />
  );
};
export default AttendanceDataTableStyled;
