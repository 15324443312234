import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { LongCard, SimpleCenteredModal, ComponentDataTable, DefaultModal, Loader } from '../../../components';
import Columns from './Columns';
import {
  debouncePayrollProcessSummaryRequest,
  payrollProcessReloadRequest,
  payrollProcessSummaryExportRequest
} from '../../../requests/payrollProcesses';
import {
  deletePayrollRequest,
  downloadPayrollDocumentsRequest,
  employeesErrorsRequest
} from '../../../requests/payrolls';
import { sendAlert } from '../../../actions/utils';
import { downloadFile } from '../../../services/utils';
import ButtonShow from '../ButtonShow';
import PayrollColumns from './PayrollColumns';
import MassiveEmployeeActions from './MassiveEmployeeActions';

const myCardData = [
  { variant: 'warning', title: '', label: 'Total Haberes', value: 'asset_total', active: false },
  { variant: 'danger', title: '', label: 'Total Descuentos', value: 'discount_total', active: false },
  { variant: 'info', title: '', label: 'Líquido a Pagar', value: 'liquid_total', active: false },
  { variant: 'warning', title: '', label: 'Impuesto Único', value: 'tax_total', active: false }
];

const modalDetailsInit = {
  modalSize: '',
  closeButton: true,
  withClose: true,
  withConfirm: true,
  backdrop: true
};

function SummaryIndex({ payrollProcess, setMoreData, moreData, onlyShow = false }) {
  const location = useLocation();
  const returnPayrollProcess = location.state?.payrollProcess;
  const remunerationProcess = payrollProcess || returnPayrollProcess;
  const [summaryData, setSummaryData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [simpleModalBody, setSimpleModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalItem, setModalItem] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const [modalTitleBtn, setModalTitleBtn] = useState('');
  const [modalDetails, setModalDetails] = useState(modalDetailsInit);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableView, setTableView] = useState('complete_records');

  const deletePayroll = item => {
    setOnRequest(true);
    deletePayrollRequest(item.id, {
      dispatch,
      successCallback: () => {
        setMoreData(!moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Proceso eliminado correctamente' }));
      },
      callback: () => {
        setOnRequest(false);
        setSimpleModalShow(false);
      }
    });
  };

  const downloadPayroll = item => {
    try {
      const fileUrl = item?.fileInfo?.fileUrl;
      window.open(fileUrl, '_blank');
    } catch (error) {
      console.error('Error al abrir la ventana de descarga:', error);
    } finally {
      setSimpleModalShow(false);
    }
  };

  const updatePayroll = ({ ids, type }) => {
    setOnRequest(true);
    const filterIds = ids.length > 0 ? { filter_ids: ids } : {};
    const requestType = type === 'individual' ? {} : { full_update: true };
    setModalDetails(details => ({
      ...details,
      closeButton: false,
      withClose: false,
      withConfirm: false,
      backdrop: 'static'
    }));
    setSimpleModalBody(<Loader message="Estamos actualizando el proceso, por favor espera unos segundos." />);
    payrollProcessReloadRequest(remunerationProcess.id, {
      dispatch,
      params: {
        ...filterIds,
        ...requestType
      },
      successCallback: () => {
        setMoreData(!moreData);
        dispatch(sendAlert({ kind: 'success', message: 'Liquidación procesada correctamente' }));
      },
      callback: () => {
        setOnRequest(false);
        setSimpleModalShow(false);
        setTimeout(() => {
          setModalDetails(modalDetailsInit);
        }, 300);
      }
    });
  };

  const modalErrorMessage = missingFields => {
    return (
      <ul className="list-two-columns">
        {missingFields.map((field, index) => (
          <li key={`missing-field-${index.toString()}`} type="disc" className="font-weight-light text-danger">
            <span className="text-dark">{field}</span>
          </li>
        ))}
      </ul>
    );
  };

  const handleButtonClick = (item, action) => {
    const file = item.fileInfo;
    const downloadBtn = (
      <Button variant="primary" href={file?.fileUrl} target="_blank" rel="noopener noreferrer" className="ml-4">
        Descargar PDF
      </Button>
    );
    const body = (
      <iframe src={file?.fileUrl} title={`${file?.filename}`} style={{ height: '500px' }} className="w-100">
        <p>Este navegador no soporta PDFs. Por favor descarga el PDF para verlo: {downloadBtn}</p>
      </iframe>
    );

    switch (action) {
      case 'show':
        setModalTitle('Detalles de Liquidación');
        setModalTitleBtn(downloadBtn);
        setModalBody(body);
        setModalShow(true);
        break;
      case 'edit':
        history.push(`/payroll_balances/${item.id}/edit`, { payrollProcess: remunerationProcess, payroll: item });
        break;
      case 'destroy':
        setModalTitle('Eliminar Proceso');
        setSimpleModalShow(true);
        setSimpleModalBody('¿Estás seguro que deseas eliminar este trabajdor del proceso?');
        setModalItem(item);
        setModalAction(() => deletePayroll);
        break;
      case 'download':
        setModalTitle('Descargar documento');
        setSimpleModalShow(true);
        setSimpleModalBody('¿Estás seguro que deseas descargar este documento?');
        setModalItem(item);
        setModalAction(() => downloadPayroll);
        break;
      case 'process':
        setModalTitle('Procesar Liquidación');
        setSimpleModalShow(true);
        setSimpleModalBody('¿Está seguro que desea procesar la liquidación seleccionada?');
        setModalItem({ ids: [item.id], type: 'individual' });
        setModalDetails(details => ({ ...details, modalSize: 'lg' }));
        setModalAction(() => updatePayroll);
        break;
      case 'missing_fields':
        setModalTitle('Campos Faltantes');
        setModalTitleBtn('');
        setModalBody(modalErrorMessage(item));
        setModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Acción no encontrada');
    }
  };

  const handleRequest = params => {
    setOnRequest(true);
    debouncePayrollProcessSummaryRequest(remunerationProcess?.id, {
      dispatch,
      params: {
        ...params
      },
      successCallback: ({ data }) => {
        setSummaryData(data.data);
        setAmount(data.metadata.amount);
      },
      callback: () => {
        setOnRequest(false);
      }
    });
  };

  const handleSelectedRows = rows => {
    setSelectedRows([...rows.selectedRows]);
  };

  const handleExport = () => {
    const ids = selectedRows.map(row => row.id);
    payrollProcessSummaryExportRequest({
      dispatch,
      params: {
        id: payrollProcess.id,
        filter_ids: ids
      },
      successCallback: response => {
        setClearSelectedRows(false);
        setMoreData(!moreData);
        downloadFile(response);
      },
      callback: () => {},
      service: 'payroll'
    });
  };

  const handleDownload = () => {
    setOnRequest(true);
    const ids = selectedRows.map(item => item.id);
    downloadPayrollDocumentsRequest({
      dispatch,
      params: { filter_ids: ids },
      format: '.zip',
      responseType: 'blob',
      successCallback: downloadFile,
      callback: () => {
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleProcess = () => {
    const ids = selectedRows.map(row => row.id);
    setModalTitle('Procesar Liquidación');
    setSimpleModalShow(true);
    setModalDetails(details => ({ ...details, modalSize: 'lg' }));
    setSimpleModalBody('¿Está seguro que desea procesar la(s) liquidación(es) seleccionada(s)?');
    setModalItem({ ids, type: 'massive' });
    setModalAction(() => updatePayroll);
  };

  const handleSuccessRequest = params => {
    setOnRequest(true);
    employeesErrorsRequest({
      dispatch,
      params: {
        ...params,
        filter_payroll_process_id: payrollProcess.id,
        paginate: true
      },
      successCallback: ({ data }) => {
        setSummaryData(data.data);
        setAmount(data.metadata.amount);
      },
      callback: () => {
        setOnRequest(false);
      }
    });
  };

  const handleClick = action => {
    switch (action) {
      case 'massive_export':
        handleExport();
        break;
      case 'massive_document_export':
        handleDownload();
        break;
      case 'massive_process':
        handleProcess();
        break;
      case 'complete_records':
        setTableView('complete_records');
        break;
      case 'incomplete_records':
        setTableView('incomplete_records');
        break;
      default:
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'worked_days':
        return { sort_worked_days: name };
      case 'cost_center':
        return { sort_cost_center: name };
      case 'full_name':
        return { sort_name: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <div className="mt-3">
      <Row>
        {payrollProcess &&
          myCardData.map((card, index) => (
            <Col key={(index + 2001002).toString()} md={4} xs={12} xl={3}>
              <LongCard {...card} title={payrollProcess[card.value] || '$ 0'} customSize="font-25" />
            </Col>
          ))}
      </Row>
      {payrollProcess?.id && tableView === 'complete_records' && (
        <ComponentDataTable
          onRequest={onRequest}
          columns={Columns(handleButtonClick, onlyShow)}
          data={summaryData}
          totalRows={amount}
          handleSortCase={sortColumnCase}
          moreData={moreData}
          selectableRows={!onlyShow}
          clearSelectedRows={clearSelectedRows}
          resourceRequest={handleRequest}
          pointerOnHover
          withMassActions
          massActions={
            <>
              <MassiveEmployeeActions handleClick={handleClick} onlyShow={onlyShow} />
              {!onlyShow && <ButtonShow handleClick={handleClick} tableView={tableView} />}
            </>
          }
          onSelectedRowsChange={handleSelectedRows}
        />
      )}

      {tableView === 'incomplete_records' && (
        <ComponentDataTable
          onRequest={onRequest}
          columns={PayrollColumns(handleButtonClick)}
          data={summaryData}
          totalRows={amount}
          handleSortCase={sortColumnCase}
          moreData={moreData}
          clearSelectedRows={clearSelectedRows}
          resourceRequest={handleSuccessRequest}
          pointerOnHover
          withMassActions
          massActions={<ButtonShow handleClick={handleClick} tableView={tableView} />}
        />
      )}
      <SimpleCenteredModal
        title={modalTitle}
        titleBtn={modalTitleBtn}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
        bodyClass="mx-3 mb-3"
      />
      <DefaultModal
        title={modalTitle}
        body={simpleModalBody}
        show={simpleModalShow}
        handleClose={() => setSimpleModalShow(false)}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        variantBtnClose="outline-info"
        variantBtnSave="primary"
        {...modalDetails}
      />
    </div>
  );
}

export default SummaryIndex;
