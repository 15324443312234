import React from 'react';
import { BasicDropdown, Icon } from '../../../../components';

const MovementDatesActions = ({ handleClick, disabled }) => {
  const actions = () => {
    const authorizedActions = [];

    authorizedActions.push({
      key: '.docx',
      title: `Word`,
      icon: (
        <Icon icon="file-earmark-word" className="text-danger" height="16px" width="16px" style={{ marginRight: 5 }} />
      ),
      props: {
        onClick: () => handleClick('.docx'),
        disabled
      }
    });

    authorizedActions.push({
      key: '.xlsx',
      title: `Excel`,
      icon: <Icon icon="file-earmark-excel" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => handleClick('.xlsx'),
        disabled
      }
    });

    authorizedActions.push({
      key: '.pdf',
      title: `Pdf`,
      icon: <Icon icon="file-earmark-pdf" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => handleClick('.pdf')
      }
    });

    return authorizedActions;
  };

  return (
    <div className="d-flex mb-2">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Descargar
            <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default MovementDatesActions;
