import React, { useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { FormikCheckBox, FormikInput, RegionCityCommune, PhoneWithCode, GoogleApiWrapper } from '../../components';

const BranchOfficeForm = props => {
  const { onHide, submitVariant, errors, touched, action, setFieldValue, values, isSubmitting } = props;

  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const {
    branchOffice: { phoneCountryCode, fullAddress, searchByAddress, coordinates, lat, lng }
  } = values;

  const [lati, setLat] = useState(lat || -33.449045);
  const [lngi, setLng] = useState(lng || -70.670374);

  const findInArrays = (array, name) =>
    array?.address_components?.find(element => element.types.find(type => type === name))?.long_name;

  const selectedPlace = place => {
    const route = findInArrays(place, 'route');
    const number = findInArrays(place, 'street_number');
    setFieldValue('branchOffice[address]', route || '');
    setFieldValue('branchOffice[addressNumber]', number || '');
    setFieldValue('branchOffice[fullAddress]', place.formatted_address);
    setFieldValue('branchOffice[lat]', place.geometry?.location.lat());
    setFieldValue('branchOffice[lng]', place.geometry?.location.lng());
    setLat(place.geometry?.location.lat());
    setLng(place.geometry?.location.lng());
  };

  const setCoordinates = value => {
    if (value) {
      const stringWithoutSpaces = value.replace(/\s+/g, '');
      const arrayOfWords = stringWithoutSpaces.split(',');
      if (arrayOfWords[1]) {
        setFieldValue('branchOffice[lat]', arrayOfWords[0]);
        setLat(arrayOfWords[0]);
      }
      if (arrayOfWords[1]) {
        setFieldValue('branchOffice[lng]', arrayOfWords[1]);
        setLng(arrayOfWords[1]);
      }
    }
  };

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={10}>
            <Field name="branchOffice[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="branchOffice[code]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Código"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="branchOffice[searchByAddress]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Buscar por Dirección"
                  custom
                  onClick={() => {
                    setFieldValue(field.name, field.value);
                    setFieldValue('branchOffice[searchByCoordinates]', field.value);
                  }}
                  tooltipClass="ml-2 mt-2"
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="branchOffice[searchByCoordinates]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Buscar por Coordenadas"
                  custom
                  onClick={() => {
                    setFieldValue(field.name, field.value);
                    setFieldValue('branchOffice[searchByAddress]', field.value);
                  }}
                  tooltipClass="ml-2 mt-2"
                />
              )}
            </Field>
          </Col>
          {!searchByAddress && (
            <Col md={12}>
              <Field name="branchOffice[coordinates]">
                {({ field }) => (
                  <GoogleApiWrapper
                    {...field}
                    abbr
                    label="Coordenadas"
                    defaultValue={coordinates}
                    onChange={e => {
                      setFieldValue(e.target.name, e.target.value);
                      setCoordinates(e?.target?.value);
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    coordenates={{ lat: lati, lng: lngi }}
                    radius={0}
                  />
                )}
              </Field>
            </Col>
          )}

          {searchByAddress && (
            <Col md={12}>
              <Field name="branchOffice[fullAddress]">
                {({ field }) => (
                  <GoogleApiWrapper
                    {...field}
                    abbr
                    label="Dirección Completa"
                    selectedPlace={val => selectedPlace(val)}
                    defaultValue={fullAddress}
                    formType="addressAutocomplete"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    coordenates={{ lat: lati, lng: lngi }}
                    radius={0}
                  />
                )}
              </Field>
            </Col>
          )}

          <Col md={6}>
            <Field name="branchOffice[address]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Dirección"
                  disabled={searchByAddress}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>

          <Col md={2}>
            <Field name="branchOffice[addressNumber]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Número"
                  disabled={searchByAddress}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="branchOffice[addressFloor]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="number"
                  label="Piso"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="branchOffice[addressOffice]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Oficina"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <RegionCityCommune regionAbbr communeAbbr cityAbbr modelKey="branchOffice" />
          <Col md={6}>
            <PhoneWithCode
              label="Teléfono"
              countryCodeField="branchOffice[phoneCountryCode]"
              phoneField="branchOffice[phone]"
              workPhoneCountryCode={phoneCountryCode}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </Col>
          <Col md={6}>
            <Field name="branchOffice[email]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Email"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Field name="branchOffice[active]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" />}
        </Field>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
          {btnMessage}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { branchOffice } = props;
  return { branchOffice };
};

const validationSchema = Yup.object().shape({
  branchOffice: Yup.object().shape(
    {
      active: Yup.boolean(),
      fullAddress: Yup.string().when('searchByAddress', {
        is: val => val === true,
        then: Yup.string()
          .required('Debes ingresar una dirección')
          .max(120, 'Deben ser menos que 200 caracteres')
      }),
      coordinates: Yup.string().when('searchByAddress', {
        is: val => val === false,
        then: Yup.string()
          .matches(
            /^-?\d+(\.\d+)?,\s?-?\d+(\.\d+)?$/,
            'La coordenada debe estar en el formato correcto (latitud, longitud)'
          )
          .required('Debes ingresar las coordenadas')
      }),
      address: Yup.string()
        .required('Debes ingresar una dirección')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      addressFloor: Yup.number().nullable(true),
      addressNumber: Yup.string().required('Debes ingresar el número de la dirección'),
      addressOffice: Yup.string()
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(true),
      code: Yup.string()
        .required('Debes ingresar un código')
        .max(120, 'Deben ser menos que 120 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      communeId: Yup.string().required('Debes seleccionar una comuna'),
      cityId: Yup.string().required('Debes selecionar una ciudad'),
      email: Yup.string()
        .email('Debes ingresar un e-mail válido')
        .nullable(),
      name: Yup.string()
        .required('Debes ingresar un nombre')
        .max(30, 'Deben ser menos que 30 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      phone: Yup.string()
        .when('phoneCountryCode', {
          is: val => val === '+56',
          then: Yup.string().length(9, 'Debe ser de 9 dígitos'),
          otherwise: Yup.string().min(3, 'Deben ser al menos 3 dígitos')
        })
        .nullable(true),
      phoneCountryCode: Yup.string().when('phone', {
        is: val => val,
        then: Yup.string().required('Debes seleccionar un código'),
        otherwise: Yup.string().nullable()
      }),
      regionId: Yup.string().required('Debes seleccionar una región')
    },
    [['phone', 'phoneCountryCode']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(BranchOfficeForm);
