import React from 'react';
import { Table } from 'react-bootstrap';
import DocumentPreview from '../DocumentPreview/DocumentPreview';
import { ButtonTooltip } from '../../components';
import Icon from '../../components/Icons';

const OvertimeRecordReportFilterDataTable = ({
  attendances,
  setDocumentModalBody,
  setDocumentModalShow,
  attendancesTotal
}) => {
  const styleButtonTooltip = {
    width: 'auto',
    height: 'auto',
    borderRadius: '10%',
    display: 'initial'
  };

  const renderResult = attendance => {
    return (
      <tr>
        <td className="text-center align-middle">{attendance.date_formatted}</td>
        <td className="text-center align-middle">{attendance.shift}</td>
        <td className="text-center align-middle">{attendance.attendance_day}</td>
        <td className="text-center align-middle">{attendance.overtime_begin_journey}</td>
        <td className="text-center align-middle">{attendance.extra_hours_begin}</td>
        <td className="text-center align-middle">{attendance.overtime_end_journey}</td>
        <td className="text-center align-middle">{attendance.extra_hours_end}</td>
        <td className="text-center align-middle">
          <>
            {attendance?.document && (
              <ButtonTooltip
                onClick={() => {
                  setDocumentModalBody(
                    <DocumentPreview
                      documentId={attendance?.document?.id}
                      employeeId={attendance?.document?.employee_id}
                    />
                  );
                  setDocumentModalShow(true);
                }}
                variant="circle-info"
                className="btn-circle"
                style={styleButtonTooltip}
                toolbarVariant="justify-content-center"
                size="sm"
                text="Ver Pacto H.E"
                disabledTooltip
              >
                <Icon icon="profile" /> Ver Pacto H.E
              </ButtonTooltip>
            )}
            {attendance.observation}
          </>
        </td>
      </tr>
    );
  };

  const renderData = () => {
    return (
      <>
        <Table className="custom-data-table" bordered size="sm" style={{ fontWeight: 400 }}>
          <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
            <tr className="rdt_TableCol">
              <th className="text-center align-middle">Fecha</th>
              <th className="text-center align-middle">Jornada Ordinaria</th>
              <th className="text-center align-middle">Marcas Reales</th>
              <th className="text-center align-middle">H.H.E.E Autorizadas Inicio Jornada</th>
              <th className="text-center align-middle">Tiempo Extra al Inicio Jornada</th>
              <th className="text-center align-middle">H.H.E.E Autorizadas Termino Jornada</th>
              <th className="text-center align-middle">Tiempo Extra al Termino Jornada</th>
              <th className="text-center align-middle">Comentarios</th>
            </tr>
          </thead>
          <tbody>{attendances && attendances.map(attendance => renderResult(attendance))}</tbody>
          <tfoot style={{ backgroundColor: '#EBEBEB' }}>
            <tr>
              <td className="text-center align-middle"> Total </td>
              <td className="text-center align-middle"> {attendancesTotal.parsed_shift_time} </td>
              <td className="text-center align-middle"> {attendancesTotal.parsed_attendance_day_time} </td>
              <td className="text-center align-middle"> {attendancesTotal.total_overtime_begin} </td>
              <td className="text-center align-middle"> {attendancesTotal.total_extra_hours_begin} </td>
              <td className="text-center align-middle"> {attendancesTotal.total_overtime_end}</td>
              <td className="text-center align-middle"> {attendancesTotal.total_extra_hours_end} </td>
              <td className="text-center align-middle"> </td>
            </tr>
            <tr>
              <td />
              <td className="text-center align-middle"> Total de días trabajados </td>
              <td className="text-center align-middle"> {attendancesTotal.total_attendances} </td>
              <td className="text-center align-middle"> Total de días no trabajados </td>
              <td className="text-center align-middle"> {attendancesTotal.total_absences} </td>
              <td />
              <td />
              <td />
            </tr>
          </tfoot>
        </Table>
      </>
    );
  };

  return <>{renderData()}</>;
};

export default OvertimeRecordReportFilterDataTable;
