import { Field, getIn, useFormikContext } from 'formik';
import React from 'react';
import { Col, Table } from 'react-bootstrap';
import styles from './attendanceMovements.module.scss';
import { FormikSelect } from '../../../../components';
import { absenceTypes } from '../FormOptions';

export default function AttendanceMovementsExpander({ setSimpleModalShow, ...props }) {
  const { data } = props;
  const { attendanceMovementsAttributes = [] } = data;
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const currentGroup = values.attendanceGroups.find(group => group.id === data.id);
  const currentGroupIndex = values.attendanceGroups.findIndex(group => group.id === data.id);
  return (
    <>
      <Table className={`custom-data-table ${styles.table_at__details}`} bordered size="sm" style={{ fontWeight: 400 }}>
        <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
          <tr className="rdt_TableCol">
            <th className="text-center align-middle">Fecha Desde</th>
            <th className="text-center align-middle">Fecha Hasta</th>
            <th className="text-center align-middle">Dias</th>
            <th className="text-center align-middle">Movimiento</th>
            <th className="text-center align-middle">Descontar</th>
          </tr>
        </thead>
        <tbody>
          {attendanceMovementsAttributes.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center align-middle">{item.startDate}</td>
              <td className="text-center align-middle">{item.endDate}</td>
              <td className="text-center align-middle">{item.days}</td>
              <td className="text-center align-middle" style={{ width: '47%' }}>
                <Col className="col-12 align-center p-0">
                  <Field
                    name={`attendanceGroups[${currentGroupIndex}][attendanceMovementsAttributes][${index}][absenceType]`}
                  >
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        placeholder="Seleccionar"
                        options={absenceTypes}
                        defaultValue={currentGroup.attendanceMovementsAttributes[index].absenceType}
                        onChange={absenceTypeValue => {
                          setFieldValue(field.name, absenceTypeValue ? absenceTypeValue.value : '');
                        }}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        margin="mb-0"
                      />
                    )}
                  </Field>
                </Col>
              </td>
              <td className="text-center align-middle">
                <Col className="d-flex justify-content-center">
                  <Col className="col-12 align-center pr-0 pl-0">
                    <Field
                      name={`attendanceGroups[${currentGroupIndex}][attendanceMovementsAttributes][${index}][status]`}
                    >
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          placeholder="Seleccionar"
                          options={[
                            { label: 'Si', value: 'approved' },
                            { label: 'No', value: 'pending' }
                          ]}
                          defaultValue={currentGroup.attendanceMovementsAttributes[index].status}
                          onChange={_data => {
                            setFieldValue(field.name, _data ? _data.value : '');
                          }}
                          setFieldTouched={() => setFieldTouched(field.name)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          margin="mb-0"
                        />
                      )}
                    </Field>
                  </Col>
                </Col>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
