import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Tab, Nav, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Icon } from '../../components';
import LoansCreditsIndex from './LoansCredits/LoansCreditsIndex';
import AdvancePaymentIndex from './AdvancePayments/AdvancePaymentIndex';
import AttendanceMovementsIndex from './AttendanceMovements/AttendanceMovementsIndex';
import SummaryIndex from './Summary/SummaryIndex';
import { PayrollProcessContext } from './context/PayrollProcessProvider';
import BalancesIndex from './Balances/BalancesIndex';
import { useSetTab } from '../../services/hooks';
import OvertimeDiscountIndex from './OvertimeDiscounts/OvertimeDiscountIndex';
import { showPayrollProcessRequest } from '../../requests/payrollProcesses';

const RemunerationProcesses = props => {
  const { location, match } = props;
  const { id } = match.params;
  const { setPayrollProcess, payrollProcess } = useContext(PayrollProcessContext);
  const [moreData, setMoreData] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const setTab = () => {
    return 'summary';
  };
  const { onlyShow } = location.state ?? {};
  const vOnlyShow = onlyShow || false;

  const [key, setKey] = useSetTab(setTab, location);
  const handlePayrollProcess = () => {
    showPayrollProcessRequest(id, {
      dispatch,
      service: 'payroll',
      successCallback: response => {
        setPayrollProcess(response.data);
      }
    });
  };
  const monthName = payrollProcess ? `${payrollProcess.month} ${payrollProcess.year}` : '';
  const ProcessStatus = () => {
    if (payrollProcess?.status === 'open') {
      return <h4 className="text-uppercase text-secondary my-0">Mes Abierto</h4>;
    }
    if (payrollProcess?.status === 'closed') {
      return <h4 className="text-uppercase text-danger my-0">Mes Cerrado</h4>;
    }
    return <></>;
  };

  useEffect(handlePayrollProcess, [moreData]);
  return (
    <>
      <Row className="mt-4">
        <Col
          md={1}
          xs={2}
          style={{
            maxWidth: '3rem'
          }}
        >
          <Col className="pl-1 pr-1">
            <Button
              variant="circle-dark"
              className="btn-circle mt-3"
              block
              onClick={() => {
                history.push(`/remuneration_processes`);
              }}
            >
              <Icon icon="chevron-back" />
            </Button>
          </Col>
        </Col>
        <Col md={11} xs={10}>
          <Col xs={12}>
            <h2 className="mb-2 mt-2">Procesos de Remuneraciones {monthName}</h2>
          </Col>
          <Col className="ml-4">
            <ProcessStatus />
          </Col>
        </Col>
      </Row>
      <Tab.Container
        id="remunerations-processes-index.tabs"
        activeKey={key}
        onSelect={k => setKey(k)}
        mountOnEnter
        unmountOnExit
      >
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="summary">
              <Icon icon="location" />
              Resumen
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="attendance-movement">
              <Icon icon="location" />
              Mov. Asistencias
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="overtime-discounts">
              <Icon icon="location" />
              HH.EE / Descuentos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="advance-payment">
              <Icon icon="location" />
              Anticipo de sueldo
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="loan-credits">
              <Icon icon="location" />
              PRÉSTAMOS / CRÉDITOS
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className="mb-1" eventKey="assets-discounts">
              <Icon icon="location" />
              HABERES Y DESCUENTOS
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="summary">
            <SummaryIndex
              payrollProcess={payrollProcess}
              setMoreData={setMoreData}
              moreData={moreData}
              onlyShow={vOnlyShow}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="advance-payment">
            <AdvancePaymentIndex onlyShow={vOnlyShow} />
          </Tab.Pane>
          <Tab.Pane eventKey="loan-credits">
            <LoansCreditsIndex payrollProcess={payrollProcess} onlyShow={vOnlyShow} />
          </Tab.Pane>
          <Tab.Pane eventKey="attendance-movement">
            <AttendanceMovementsIndex id={id} onlyShow={vOnlyShow} />
          </Tab.Pane>
          <Tab.Pane eventKey="overtime-discounts">
            <OvertimeDiscountIndex id={id} payrollProcess={payrollProcess} onlyShow={vOnlyShow} />
          </Tab.Pane>
          <Tab.Pane eventKey="assets-discounts">
            <BalancesIndex onlyShow={vOnlyShow} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default RemunerationProcesses;
