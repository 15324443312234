import React from 'react';
import { Table } from 'react-bootstrap';
import { ButtonTooltip } from '../../components/Utils/Tooltips';
import DocumentPreview from '../DocumentPreview/DocumentPreview';
import Icon from '../../components/Icons';

const AttendanceDetailReportFilterDataTable = ({ attendances, setDocumentModalBody, setDocumentModalShow }) => {
  const renderResult = attendance => {
    const renderObservation = observation => (
      <div dangerouslySetInnerHTML={{ __html: observation.replace(/\n/g, '<br>') }} />
    );

    const styleButtonTooltip = {
      width: 'auto',
      height: 'auto',
      borderRadius: '10%',
      display: 'initial'
    };

    return (
      <tr>
        <td className="text-center align-middle">{attendance.date_formatted}</td>
        <td className="text-center align-middle">{attendance.shift}</td>
        <td className="text-center align-middle">{attendance.attendance_day}</td>
        <td className="text-center align-middle">{attendance.late_workday}</td>
        <td className="text-center align-middle">{attendance.early_departure}</td>
        <td className="text-center align-middle">{attendance.hours_extra}</td>
        <td className="text-center align-middle">
          <>
            {attendance?.document && (
              <ButtonTooltip
                onClick={() => {
                  setDocumentModalBody(
                    <DocumentPreview
                      documentId={attendance?.overtime_ag_document?.id}
                      employeeId={attendance?.overtime_ag_document?.employee_id}
                    />
                  );
                  setDocumentModalShow(true);
                }}
                variant="circle-info"
                className="btn-circle"
                style={styleButtonTooltip}
                toolbarVariant="justify-content-center"
                size="sm"
                text="Ver Pacto H.E"
                disabledTooltip
              >
                <Icon icon="profile" /> Ver Pacto H.E
              </ButtonTooltip>
            )}

            {attendance?.permission_document && (
              <ButtonTooltip
                onClick={() => {
                  setDocumentModalBody(
                    <DocumentPreview
                      documentId={attendance?.permission_document?.id}
                      employeeId={attendance?.permission_document?.employee_id}
                    />
                  );
                  setDocumentModalShow(true);
                }}
                variant="circle-info"
                className="btn-circle"
                style={styleButtonTooltip}
                toolbarVariant="justify-content-center"
                size="sm"
                text="Ver Permiso"
                disabledTooltip
              >
                <Icon icon="profile" /> Ver Permiso
              </ButtonTooltip>
            )}

            {attendance?.vacation_docuemnt && (
              <ButtonTooltip
                onClick={() => {
                  setDocumentModalBody(
                    <DocumentPreview
                      documentId={attendance?.vacation_docuemnt?.id}
                      employeeId={attendance?.vacation_docuemnt?.employee_id}
                    />
                  );
                  setDocumentModalShow(true);
                }}
                variant="circle-info"
                className="btn-circle"
                style={styleButtonTooltip}
                toolbarVariant="justify-content-center"
                size="sm"
                text="Ver Permiso"
                disabledTooltip
              >
                <Icon icon="profile" /> Ver Vacación
              </ButtonTooltip>
            )}

            {renderObservation(attendance.observation)}
          </>
        </td>
      </tr>
    );
  };

  const renderData = () => {
    return (
      <>
        <Table className="custom-data-table" bordered size="sm" style={{ fontWeight: 400 }}>
          <thead className="rdt_TableHeadRow" style={{ backgroundColor: '#EBEBEB', textTransform: 'uppercase' }}>
            <tr className="rdt_TableCol">
              <th className="text-center align-middle">Fecha</th>
              <th className="text-center align-middle">Jornada Ordinaria</th>
              <th className="text-center align-middle">Marcas Reales</th>
              <th className="text-center align-middle">Atrasos</th>
              <th className="text-center align-middle">Salida Anticipada</th>
              <th className="text-center align-middle">Horas Extras</th>
              <th className="text-center align-middle">Comentarios</th>
            </tr>
          </thead>
          <tbody>{attendances && attendances.map(attendance => renderResult(attendance))}</tbody>
        </Table>
      </>
    );
  };

  return <>{renderData()}</>;
};

export default AttendanceDetailReportFilterDataTable;
