import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AttendanceReportDataTable from './AttendanceReport';

const AttendanceReportIndex = ({ rrhh }) => {
  const { currentEmployee } = useSelector(state => state.auth);
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <AttendanceReportDataTable currentEmployee={currentEmployee} rrhh={rrhh} />
    </>
  );
};

export default AttendanceReportIndex;
