import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tab, Nav, Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import moment from 'moment';
import snakeCaseKeys from 'snakecase-keys';
import { AbilityContext } from '../../config/abilityContext';

import { sendAlert } from '../../actions/utils';
import { useSetTab } from '../../services/hooks';
import { createLoanRequest } from '../../requests/loans';
import { Icon, SimpleCenteredModal } from '../../components';
import LoanIndex from './LoanIndex';
import AdvancePaymentIndex from './AdvancePaymentIndex';
import LoanForm from '../Shared/Loan/LoanForm';
import AdvancePaymentNew from '../Profile/AdvancePayment/AdvancePaymentNew';
import VacationNew from '../Profile/Vacation/VacationNew';
import PermissionNew from '../Profile/Permission/PermissionNew';
import ShiftChangeDashboard from '../ShiftChange/ShiftChangeDashboard';
import VacationIndex from '../Vacation/VacationIndex';
import PermissionIndex from '../Vacation/PermissionIndex';
import ShiftChangeNew from '../Profile/ShiftChange/ShiftChangeNew';
import OvertimeNew from '../Profile/Overtime/OvertimeNew';
import OvertimeIndex from '../Profile/Overtime/OvertimeIndex';
import useCreateOvertimeHook from '../Profile/Overtime/useCreateOvertimeHook';

const basicLoan = {
  amount: 0,
  bookkeeperId: '',
  employeeId: '',
  grantDate: '',
  installments: 0,
  name: '',
  parsedAmount: '',
  reason: '',
  startDate: '',
  status: ''
};

const rrhh = true;

const RequestsDashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [moreData, setMoreData] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const todayMoment = useMemo(() => moment(), []);
  const twoMonthsAgo = useMemo(() => moment().subtract(2, 'months'), []);
  const { advanceSettings } = useSelector(state => state.utils);
  const reqAmountLimit = advanceSettings?.filter(asItem => asItem.code === 'loan_amount_limit')[0]?.integerValue;

  const ability = useAbility(AbilityContext);

  const defaultKeyModel = () => 'advance_payments';

  const defaultKey = () => 'advance_payments';

  const checkTab = () => {
    const tab = new URLSearchParams(location.search).get('tab');
    if (['advance_payments', 'loans', 'vacations', 'permissions', 'shift_change_requests', 'overtimes'].includes(tab)) {
      return tab;
    }
    return defaultKey();
  };

  const [key, setKey] = useSetTab(defaultKey(), location);
  const [keyModel, setKeyModel] = useState(defaultKeyModel);

  const updateDefaultKey = () => setKey(checkTab());

  useEffect(updateDefaultKey, [advanceSettings]);

  useEffect(() => {
    switch (key) {
      case 'loans':
        setKeyModel('Loan');
        break;
      case 'advance_payments':
        setKeyModel('AdvancePayment');
        break;
      case 'vacations':
        setKeyModel('Vacation');
        break;
      case 'permissions':
        setKeyModel('Permission');
        break;
      case 'shift_change_requests':
        setKeyModel('ShiftChangeRequest');
        break;
      case 'overtimes':
        setKeyModel('Overtime');
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        setKeyModel('');
        break;
    }
  }, [key]);

  const handleSuccessAction = message => {
    setModalShow(false);
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleCreateLoan = (loan, setSubmitting) => {
    const snakeCaseLoan = snakeCaseKeys(loan);

    createLoanRequest({
      dispatch,
      params: {
        ...snakeCaseLoan,
        rrhh
      },
      successCallback: () => handleSuccessAction('Préstamo creado correctamente'),
      callback: () => setSubmitting(false)
    });
  };

  const { handleCreateOvertime } = useCreateOvertimeHook();

  const newLoan = () => {
    setModalTitle('Nuevo Préstamo');
    setModalShow(true);
    setModalBody(
      <LoanForm
        rrhh
        newLoan
        loan={basicLoan}
        formRequest={handleCreateLoan}
        handleModalClose={() => setModalShow(false)}
        reqAmountLimit={reqAmountLimit}
      />
    );
  };

  const newAdvancePayment = () => {
    setModalTitle('Asignar Anticipo');
    setModalShow(true);
    setModalBody(
      <AdvancePaymentNew
        onSuccess={() => {
          setModalShow(false);
          setMoreData(!moreData);
        }}
        rrhh
      />
    );
  };

  const newVacation = () => {
    setModalTitle('Asignar Vacaciones');
    setModalShow(true);
    setModalBody(
      <VacationNew
        onSuccess={() => {
          setModalShow(false);
          setMoreData(!moreData);
        }}
        rrhh
      />
    );
  };

  const newPermission = () => {
    setModalTitle('Asignar Permisos');
    setModalShow(true);
    setModalBody(
      <PermissionNew
        onSuccess={() => {
          setModalShow(false);
          setMoreData(!moreData);
        }}
        rrhh
      />
    );
  };

  const newShiftChange = () => {
    setModalTitle('Asignar cambio de turno');
    setModalShow(true);
    setModalBody(
      <ShiftChangeNew
        action="new"
        onSuccess={() => {
          setModalShow(false);
          setMoreData(!moreData);
        }}
        rrhh
      />
    );
  };

  const newOvertimeRequest = () => {
    setModalTitle('Nueva Solicitud de Horas Extras');
    setModalShow(true);
    setModalBody(
      <OvertimeNew
        rrhh
        overtime={{}}
        formRequest={(overtime, setSubmitting) => {
          handleCreateOvertime(overtime, setSubmitting, setModalShow, setMoreData);
        }}
      />
    );
  };

  const handleClick = () => {
    switch (key) {
      case 'loans':
        return newLoan();
      case 'advance_payments':
        return newAdvancePayment();
      case 'vacations':
        return newVacation();
      case 'permissions':
        return newPermission();
      case 'shift_change_requests':
        return newShiftChange();
      case 'overtimes':
        return newOvertimeRequest();
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
        return '';
    }
  };

  useEffect(() => window.scrollTo(0, 0), []);

  const title = `${key === 'advance_payments' ? 'Anticipos' : ''}
                 ${key === 'loans' ? 'Préstamos' : ''}
                 ${key === 'vacations' ? 'Vacaciones' : ''}
                 ${key === 'permissions' ? 'Permisos' : ''}
                 ${key === 'shift_change_requests' ? 'Cambio de Turno' : ''}
                 ${key === 'overtimes' ? 'Horas Extras' : ''}`;

  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h2 className="mt-3 mb-3">{title}</h2>
        </Col>
        {ability.can('create', keyModel) && (
          <Col md={3}>
            <Button className="mt-3" variant="primary" block onClick={handleClick}>
              Nuevo
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link className="mb-1" eventKey="advance_payments">
                  <Icon icon="user-check" />
                  Anticipos
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="mb-1" eventKey="loans">
                  <Icon icon="wallet" />
                  Préstamos
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="mb-1" eventKey="vacations">
                  <Icon icon="sunny" />
                  Vacaciones
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="mb-1" eventKey="permissions">
                  <Icon icon="user-check" />
                  Permisos
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="mb-1" eventKey="shift_change_requests">
                  <Icon icon="calendar" />
                  Cambio de Turno
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="mb-1" eventKey="overtimes">
                  <Icon icon="clock2" />
                  Horas Extras
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="advance_payments">
                <AdvancePaymentIndex
                  moreData={moreData}
                  setMoreData={setMoreData}
                  todayMoment={todayMoment}
                  twoMonthsAgo={twoMonthsAgo}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="loans">
                <LoanIndex
                  moreData={moreData}
                  setMoreData={setMoreData}
                  todayMoment={todayMoment}
                  twoMonthsAgo={twoMonthsAgo}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="vacations">
                <VacationIndex
                  moreData={moreData}
                  setMoreData={setMoreData}
                  todayMoment={todayMoment}
                  twoMonthsAgo={twoMonthsAgo}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="permissions">
                <PermissionIndex
                  moreData={moreData}
                  setMoreData={setMoreData}
                  todayMoment={todayMoment}
                  twoMonthsAgo={twoMonthsAgo}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="shift_change_requests">
                <ShiftChangeDashboard
                  moreData={moreData}
                  setMoreData={setMoreData}
                  title
                  todayMoment={todayMoment}
                  twoMonthsAgo={twoMonthsAgo}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="overtimes">
                <OvertimeIndex
                  rrhh
                  moreData={moreData}
                  setMoreData={setMoreData}
                  todayMoment={todayMoment}
                  twoMonthsAgo={twoMonthsAgo}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default RequestsDashboard;
