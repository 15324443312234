import memoize from 'memoize-one';
import React from 'react';
import { DatatableEmployeeName } from '../../../../components';

export default memoize(() => [
  {
    name: 'Trabajador',
    selector: 'employee',
    cell: item => (
      <>
        <DatatableEmployeeName
          name="fullName"
          item={item?.employee}
          withPhoto={false}
          startDateContract={item?.start_date_contract}
        />
      </>
    ),
    sortable: true,
    grow: '2'
  }
]);
