import React, { useState, useEffect } from 'react';
import { Row, Col, FormControl, Form, Spinner, InputGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import moment from 'moment';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { SingleDatePicker } from 'react-dates';
import { ICON_AFTER_POSITION } from 'react-dates/constants';
import PropTypes from 'prop-types';
import RenderMonthElement from '../Input/RenderMonthElement';
import Icon from '../../Icons/Icon';
import './style.scss';

// https://www.npmjs.com/package/react-data-table-component

const ComponentDataTable = ({
  RowsPage,
  defaultEndDate,
  defaultStartDate,
  handleSortCase,
  headers,
  moreData,
  nameRangePicker,
  preName,
  query,
  rangePicker,
  resourceRequest,
  withSearch,
  withDateSelect,
  searchFirstStart,
  paginationRowsPerPageOptions,
  customPagination,
  customSpinnerClass,
  paginationServer,
  sortServer,
  ...props
}) => {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [focusStartDate, setFocusStartDate] = useState(false);
  const [focusEndDate, setFocusEndDate] = useState(false);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [textInput, setTextInput] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPage, setRowsPage] = useState(RowsPage);
  const [column, setColumn] = useState('created_at');
  const [direction, setDirection] = useState('desc');
  const [dateRangeName, setDateRangeName] = useState('date_range');
  const debounceRequest = AwesomeDebouncePromise(resourceRequest, 500);

  const setRequest = params => {
    let sortColumn = { sort_column: column };
    let dateFilter = {
      date_from: moment.isMoment(startDate) ? startDate.format('DD/MM/YYYY') : '',
      date_to: moment.isMoment(endDate) ? endDate.format('DD/MM/YYYY') : ''
    };
    if (rangePicker && startDate && endDate) {
      dateFilter = { [dateRangeName]: [startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY')] };
    }
    if (handleSortCase) {
      sortColumn = handleSortCase(column);
    }
    if (searchFirstStart === true) {
      debounceRequest({
        [query]: textInput,

        display_start: page * rowsPage,
        display_length: rowsPage,
        sort_direction: direction,
        ...dateFilter,
        ...sortColumn,
        ...moreData,
        ...params
      });
    }
  };

  const onChangePage = pageValue => {
    setPage(pageValue - 1);
  };

  const onChangeRowsPage = RowsPageValue => {
    setRowsPage(RowsPageValue);
  };

  const handleSort = (colValue, dirValue) => {
    setDirection(dirValue);
    setPage(0);
    setColumn(colValue.selector);
  };

  const handleRowSelection = (selection, handleSelect) => {
    if (!handleSelect) {
      return;
    }
    handleSelect(selection.selectedRows);
  };

  // const CustomButtonPagination = ({ handleClick, textButton, disabled }) => {
  //   return (
  //     <Button
  //       variant="outline-info"
  //       disabled={disabled}
  //       style={{
  //         height: 40,
  //         marginRight: 5,
  //         marginBottom: 5
  //       }}
  //       onClick={handleClick}
  //     >
  //       {textButton}
  //     </Button>
  //   );
  // };

  // const CustomPaginationComponent = ({ count }) => {
  //   const getNumberOfPages = (rowCount, rowsPag) => Math.ceil(rowCount / rowsPag);

  //   const handleFirstPageButtonClick = () => {
  //     onChangePage(1);
  //   };

  //   const handleBackButtonClick = () => {
  //     if (page === 0) {
  //       handleFirstPageButtonClick();
  //     }
  //     if (page !== 0) {
  //       onChangePage(page);
  //     }
  //   };

  //   const handleNextButtonClick = () => {
  //     onChangePage(page + 2);
  //   };

  //   const handleLastPageButtonClick = () => {
  //     onChangePage(getNumberOfPages(count, rowsPage));
  //   };

  //   return (
  //     <Row className="p-4">
  //       <Col xs={2} md={2} xl={2} />
  //       <Col xs={4} md={4} xl={4}>
  //         <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
  //           <span style={{ marginRight: 10, marginBottom: 10 }}>Filas por página:</span>
  //           <select
  //             value={rowsPage}
  //             onChange={e => {
  //               if (page >= getNumberOfPages(count, e.target.value)) {
  //                 handleFirstPageButtonClick();
  //                 onChangeRowsPage(Number(e.target.value));
  //               } else {
  //                 onChangeRowsPage(Number(e.target.value));
  //               }
  //             }}
  //             style={{
  //               height: 40,
  //               alignItems: 'center',
  //               padding: 5,
  //               borderRadius: 10,
  //               borderWidth: 1,
  //               marginBottom: 10
  //             }}
  //           >
  //             {paginationRowsPerPageOptions.map(pSize => (
  //               <option key={pSize} value={pSize}>
  //                 {pSize}
  //               </option>
  //             ))}
  //           </select>
  //           <span style={{ marginRight: 10, marginLeft: 10, marginBottom: 10 }}>
  //             {page + 1} - {getNumberOfPages(count, rowsPage)} de {count}
  //           </span>
  //         </Row>
  //       </Col>
  //       <Col xs={5} md={5} xl={5}>
  //         <span>
  //           <CustomButtonPagination handleClick={handleFirstPageButtonClick} textButton="<<" disabled={page === 0} />
  //           <CustomButtonPagination handleClick={handleBackButtonClick} textButton="Anterior" disabled={page === 0} />
  //           <CustomButtonPagination
  //             handleClick={handleNextButtonClick}
  //             textButton="Siguiente"
  //             disabled={page >= getNumberOfPages(count, rowsPage) - 1}
  //           />
  //           <CustomButtonPagination
  //             handleClick={handleLastPageButtonClick}
  //             textButton=">>"
  //             disabled={page >= getNumberOfPages(count, rowsPage) - 1}
  //           />
  //         </span>
  //       </Col>
  //     </Row>
  //   );
  // };

  // const CustomPag = ({ rowCount, onChangeRowsPerPage, currentPage }) => (
  //   <CustomPaginationComponent
  //     component="nav"
  //     currentPage={currentPage}
  //     count={rowCount}
  //     rowsPerPage={rowsPage}
  //     page={currentPage - 1}
  //     onChangePage={onChangePage}
  //     onChangeRowsPerPage={({ target }) => onChangeRowsPerPage(Number(target.value))}
  //     ActionsComponent={CustomPaginationComponent}
  //   />
  // );

  const StartDateInput = () => (
    <SingleDatePicker
      block
      id={`${preName}-start-date-input`}
      date={startDate}
      placeholder="Desde"
      focused={focusStartDate}
      onFocusChange={({ focused }) => {
        setFocusEndDate(false);
        setFocusStartDate(focused);
      }}
      numberOfMonths={1}
      onDateChange={date => {
        setPage(0);
        setStartDate(date);
      }}
      showClearDate
      customCloseIcon={<Icon icon="close" width={18} />}
      customInputIcon={<Icon icon="calendar" width={18} />}
      hideKeyboardShortcutsPanel
      inputIconPosition={ICON_AFTER_POSITION}
      firstDayOfWeek={1}
      renderMonthElement={RenderMonthElement}
      isOutsideRange={day => day.isAfter(endDate)}
    />
  );

  const EndDateInput = () => (
    <SingleDatePicker
      block
      id={`${preName}-end-date-input`}
      date={endDate}
      placeholder="Hasta"
      focused={focusEndDate}
      onFocusChange={({ focused }) => {
        setFocusStartDate(false);
        setFocusEndDate(focused);
      }}
      numberOfMonths={1}
      onDateChange={date => {
        setPage(0);
        setEndDate(date);
      }}
      showClearDate
      customCloseIcon={<Icon icon="close" width={18} />}
      customInputIcon={<Icon icon="calendar" width={18} />}
      hideKeyboardShortcutsPanel
      inputIconPosition={ICON_AFTER_POSITION}
      firstDayOfWeek={1}
      renderMonthElement={RenderMonthElement}
      isOutsideRange={day => day.isBefore(startDate)}
    />
  );

  const handleDateRange = () => {
    if (nameRangePicker) setDateRangeName(nameRangePicker);
    else setDateRangeName('date_range');
  };

  useEffect(handleDateRange, [nameRangePicker]);
  useEffect(() => setStartDate(defaultStartDate), [defaultStartDate]);
  useEffect(() => setEndDate(defaultEndDate), [defaultEndDate]);
  useEffect(setRequest, [moreData, startDate, endDate, textInput, direction, page, column, rowsPage, dateRangeName]);
  const {
    onRequest,
    data,
    totalRows,
    withStartDate,
    withEndDate,
    handleSelect,
    withMassActions,
    massActions,
    customHeaderCenter,
    customDivStyles = {},
    customClassModule = '',
    noDataComponentText
  } = props;

  return (
    <div className="div-data__table position-relative" style={{ ...customDivStyles }}>
      {onRequest && (
        <div className={customSpinnerClass}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <DataTable
        className={`custom-data-table ${customClassModule}`}
        responsive
        overflowY
        fixedHeader
        pagination
        noDataComponent={onRequest ? '' : <div style={{ paddingBottom: 30 }}>{noDataComponentText}</div>}
        paginationServer={paginationServer}
        paginationTotalRows={totalRows}
        paginationPerPage={rowsPage}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPage}
        paginationComponentOptions={{ rowsPerPageText: 'Filas por pagina:', rangeSeparatorText: 'de' }}
        sortServer={sortServer}
        onRowSelected={memoize(selection => handleRowSelection(selection, handleSelect))}
        onSort={(colValue, dirValue) => handleSort(colValue, dirValue)}
        noHeader
        noContextMenu
        subHeader
        disabled={onRequest}
        data={data}
        // se comentará de manera provicional
        // paginationComponent={customPagination ? CustomPag : undefined}
        subHeaderComponent={
          <>
            {withMassActions && massActions}
            <Row className="justify-content-end">
              {withDateSelect && (
                <>
                  {withStartDate && (
                    <Col xs={6} md={3} xl={2}>
                      <Form.Group>
                        <InputGroup className="margin-sm">
                          <StartDateInput />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  )}
                  {withEndDate && (
                    <Col xs={6} md={3} xl={2}>
                      <Form.Group>
                        <InputGroup>
                          <EndDateInput />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  )}
                  {customHeaderCenter && (
                    <Col xs={12} md={3} xl={3}>
                      {customHeaderCenter}
                    </Col>
                  )}
                  {withSearch && (
                    <Col className="search" xs={12} sm={6} md={4} xl={3}>
                      <Form.Group>
                        <FormControl
                          placeholder="Buscar"
                          name="textInput"
                          value={textInput}
                          onChange={e => {
                            setPage(0);
                            setTextInput(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  {headers}
                </>
              )}
            </Row>
          </>
        }
        {...props}
      />
    </div>
  );
};

ComponentDataTable.propTypes = {
  RowsPage: PropTypes.number,
  defaultEndDate: PropTypes.instanceOf(moment),
  defaultStartDate: PropTypes.instanceOf(moment),
  moreData: PropTypes.bool,
  onRequest: PropTypes.bool,
  preName: PropTypes.string,
  query: PropTypes.string,
  resourceRequest: PropTypes.func,
  totalRows: PropTypes.number,
  withDateSelect: PropTypes.bool,
  withSearch: PropTypes.bool,
  searchFirstStart: PropTypes.bool,
  customSpinnerClass: PropTypes.string,
  paginationServer: PropTypes.bool,
  sortServer: PropTypes.bool,
  customPagination: PropTypes.bool,
  paginationRowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  noDataComponentText: PropTypes.string
};

ComponentDataTable.defaultProps = {
  RowsPage: 15,
  defaultEndDate: null,
  defaultStartDate: null,
  moreData: false,
  onRequest: true,
  preName: 'datatable',
  query: 'query',
  resourceRequest: () => null,
  totalRows: 0,
  withDateSelect: true,
  withSearch: true,
  searchFirstStart: true,
  customSpinnerClass: 'containerSpinnerLoad position-absolute h-100 w-100',
  paginationServer: true,
  sortServer: true,
  paginationRowsPerPageOptions: [5, 10, 15, 50],
  customPagination: true,
  noDataComponentText: 'No se encontraron datos.'
};

export default ComponentDataTable;
