import { Field, getIn } from 'formik';
import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { FormikCheckBox, FormikDatePicker, FormikInput, FormikSelect } from '../../../components';
import { shiftExtensions } from '../FormOptions';

const GeneralData = ({
  currentTab,
  disabled,
  errors,
  exceptionalWorkday,
  extensionDefaultValue,
  onChangeTab,
  setFieldValue,
  shiftType,
  touched
}) => {
  return (
    <Card className="overflow-visible">
      <Col className={`${currentTab === 'general' ? 'top-header-green' : 'top-header-light-gray'}`}>
        <Accordion.Toggle as={Col} eventKey="0" className="card-header-title-dt" onClick={() => onChangeTab('general')}>
          Datos Generales
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="0">
        <Card.Body className="div-content">
          <Row>
            <Col xs={12} md={6}>
              <Field name="shift[name]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Nombre"
                    placeholder="Nombre de turno"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={disabled}
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={3}>
              <Field name="shift[acronym]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Siglas"
                    placeholder="Siglas del turno"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={disabled}
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={3}>
              <Field name="shift[shiftExtension]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Extensión"
                    placeholder="Seleccionar"
                    options={shiftExtensions}
                    defaultValue={extensionDefaultValue}
                    isDisabled={disabled || shiftType !== 'rotative'}
                    onChange={data => {
                      setFieldValue(field.name, data ? data.value : '');
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={9}>
              <Field name="shift[description]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Descripción"
                    placeholder="Descripción del turno"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    disabled={disabled}
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} md={3} className="mt-4 d-flex align-items-center">
              <Field name="shift[workHolidays]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    custom
                    field={field}
                    label="Trabaja festivos"
                    tooltipSize="23"
                    tooltipText="Turno con días festivos incluídos"
                    disabled={disabled}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={3} className="mt-4 d-flex align-items-center">
              <Field name="shift[exceptionalWorkday]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    custom
                    field={field}
                    label="Jornada Excepcional"
                    tooltipSize="23"
                    disabled={disabled}
                  />
                )}
              </Field>
            </Col>
            {exceptionalWorkday && (
              <>
                <Col xs={6} md={3}>
                  <Field name="shift[workdayNumber]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Nro. Jornada"
                        placeholder="Número de jornada"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={6} md={3}>
                  <Field name="shift[beginExceptionalWorkday]">
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        isOutsideRange={() => false}
                        label="Inicio"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={6} md={3}>
                  <Field name="shift[expirationExceptionalWorkday]">
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        isOutsideRange={() => false}
                        label="Vencimiento"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
export default GeneralData;
