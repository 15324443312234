import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAttendanceGroupsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) => {
  ApiService.request('get', '/attendance_groups', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service
  });
};

export const updateAttendanceGroupRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('put', `/attendance_groups`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const updateMassiveAttendanceGroupRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('put', '/attendance_groups/massive', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const exportAttendanceGroupRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('get', `/attendance_groups/export.xlsx`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const destroyAttendanceGroupRequest = (
  id,
  { dispatch, failureCallback, successCallback, callback, service = 'payroll' }
) =>
  ApiService.request('delete', `/attendance_groups/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const massiveDestroyAttendanceGroupRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('post', '/attendance_groups/massive_destroy', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const processPayrollsRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('post', '/attendance_groups/process_payrolls', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    service
  });

export const debounceIndexAttendanceGroupsRequest = AwesomeDebouncePromise(indexAttendanceGroupsRequest, 300);
