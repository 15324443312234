import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import moment from 'moment';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormikRutSuggest, FormikRangePicker, FormikSelect, InputSelect } from '../../../components';
import { debounceIndexSelectEmployeesRequest } from '../../../requests/employees';
import { indexJobTitleRequest } from '../../../requests/jobTitles';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { debounceIndexShiftsRequest } from '../../../requests/shifts';
import { informesWorkSchedules } from './Options';

const InformesReportFilters = props => {
  const {
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    values,
    filterRRHH,
    isSubmit,
    isArial,
    onlyPreview = false
  } = props;
  const dispatch = useDispatch();
  const ALL_SELECTED_LABEL = 'Todos';
  const [jobTitles, setJobTitles] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesSingle, setEmployeesSingle] = useState([]);
  const [ruts, setRuts] = useState(['']);
  const [suggestions, setSuggestions] = useState(['']);
  const [dataRange, setDataRange] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);

  const fetchEmployees = () => {
    setFieldValue('employee_ids', []);
    setFieldValue('employee_id', '');
    setEmployees([]);
    setEmployeesSingle([]);
    setIsFiltering(true);
    debounceIndexSelectEmployeesRequest({
      dispatch,
      params: {
        sort_column: 'name',
        paginate: false,
        is_dt: false,
        filter_branch_offices_id_only: branchOffices,
        filter_active_shifts: shifts,
        filter_job_titles_id_only: jobTitles,
        filter_by_contract_date_range: dataRange
      },
      successCallback: ({ data: { data: arrayEmployees } }) => {
        const dataToGroupSearch = [{ id: '', label: 'Todos', value: '' }, ...arrayEmployees];
        setEmployees(arrayEmployees.length > 0 ? dataToGroupSearch : []);
        setEmployeesSingle(arrayEmployees);
        const employeeIds = arrayEmployees.flatMap(employee => employee.id);
        setFieldValue('all_employee_ids', employeeIds);
        setIsFiltering(false);
        if (!ruts[0]) {
          setRuts(arrayEmployees.map(r => r.national_identification));
          setSuggestions(arrayEmployees.map(r => r.national_identification));
        }
      }
    });
  };

  const fetchJobTitles = (inputValue, callback) => {
    indexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: response => {
        callback([{ id: '', label: 'Todos', value: '' }, ...response.data.data]);
      }
    });
  };

  const fetchShifts = (inputValue, callback) => {
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => {
        callback([{ id: '', label: 'Todos', value: '' }, ...response.data.data]);
      }
    });
  };

  const fetchBranchOffices = (inputValue, callback) => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: response => {
        callback([{ id: '', label: 'Todos', value: '' }, ...response.data.data]);
      }
    });
  };

  const handleEmployeesSearch = (field, data) => {
    switch (field) {
      case 'filter_branch_offices_id_only':
        setBranchOffices(data);
        break;
      case 'filter_active_shifts':
        setShifts(data);
        break;
      case 'filter_job_titles_id_only':
        setJobTitles(data);
        break;
      default:
        break;
    }
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData);
    handleEmployeesSearch(field, newData);
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.label === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.label === ALL_SELECTED_LABEL;
  };

  useEffect(() => {
    if (dataRange.length === 2) {
      fetchEmployees();
    }
    // eslint-disable-next-line
  }, [branchOffices, shifts, jobTitles, dataRange]);

  return (
    <Form>
      <Card>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className={`top-header-green-dt card-header-title-dt ${isArial ? 'arial-dt' : ''}`}>
                Rango de Fechas o Periodo Predeterminado
              </Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="range_date_workday">
                      {({ field }) => (
                        <FormikRangePicker
                          {...field}
                          abbr
                          startDateName="date_from_workday"
                          endDateName="date_to_workday"
                          startDate={values.date_from_workday}
                          endDate={values.date_to_workday}
                          showClearDates
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isArial={isArial}
                          onDatesChange={({ startDate: startDateV, endDate: endDateV }) => {
                            const startValue = moment.isMoment(startDateV) ? startDateV.format('DD/MM/YYYY') : '';
                            const endValue = moment.isMoment(endDateV) ? endDateV.format('DD/MM/YYYY') : '';

                            setFieldValue('employee', '');
                            setFieldValue('employees', []);

                            if (startValue && endValue) setDataRange([startValue, endValue]);

                            setFieldValue('date_from_workday', startValue);
                            setFieldValue('date_to_workday', endValue);
                            setFieldTouched(field.name);
                            setFieldValue(field.name, [startValue, endValue]); // Important to be the last one
                          }}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6} xs={12}>
                    <Field name="range_date_workday">
                      {({ field }) => (
                        <FormikSelect
                          {...field}
                          label="Periodo"
                          options={informesWorkSchedules}
                          onChange={data => {
                            setFieldValue(field.name, data ? data.value : '');
                            setDataRange(data ? data.value : '');
                          }}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                          isArial={isArial}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {filterRRHH && (
          <>
            <Row>
              <Col md={6}>
                <Card className="card-dt">
                  <Col className={`top-header-green-dt card-header-title ${isArial ? 'arial-dt' : ''}`}>
                    Búsqueda Individual
                  </Col>
                  <Card.Body className="div-content card-body-padding">
                    <Row>
                      <Col sm={12} md={6} xs={12} className="mt-2">
                        <Field name="employee">
                          {({ field }) => (
                            <FormikSelect
                              {...field}
                              label="Nombre y Apellido"
                              placeholder="Seleccione colaborador"
                              value={values.employee}
                              isClearable
                              options={employeesSingle}
                              isLoading={isFiltering}
                              onChange={data => {
                                setFieldValue(field.name, data || '');
                                setFieldValue('employee_id', data?.value || '');
                                setFieldValue('employees', []);
                                setFieldValue('employee_ids', []);
                              }}
                              isOptionDisabled={handleDisabledSelection}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                      <Col sm={12} md={6} xs={12} className="mt-2">
                        <Field name="filter_national_id">
                          {({ field }) => (
                            <FormikRutSuggest
                              {...field}
                              suggestions={suggestions}
                              setSuggestions={setSuggestions}
                              ruts={ruts}
                              label="RUT"
                              placeholder="Sin puntos (ej: 11111111-1)"
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              setFieldValue={setFieldValue}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="card-dt">
                  <Col className={`top-header-green-dt card-header-title ${isArial ? 'arial-dt' : ''}`}>
                    Búsqueda Grupal
                  </Col>
                  <Card.Body className="div-content card-body-padding">
                    <Row>
                      <Col className="mt-2">
                        <Field name="employees">
                          {({ field }) => (
                            <FormikSelect
                              {...field}
                              label="Nombres y Apellidos"
                              isMulti
                              options={employees}
                              placeholder="Seleccione colaboradores"
                              isLoading={isFiltering}
                              onChange={data => {
                                handleNewMulti(data || [], 'employee_ids', true);
                                setFieldValue(field.name, data || []);
                                setFieldValue('employee', '');
                                setFieldValue('employee_id', '');
                              }}
                              isOptionDisabled={handleDisabledSelection}
                              isClearable
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card-dt">
                  <Col className={`top-header-green-dt card-header-title ${isArial ? 'arial-dt' : ''}`}>
                    Búsqueda Grupal Listado
                  </Col>
                  <Card.Body className="div-content card-body-padding">
                    <Row>
                      <Col sm={12} md={4} xs={12} className="mt-2">
                        <Field name="branch_offices">
                          {({ field }) => (
                            <InputSelect
                              {...field}
                              label="Lugar de Prestación de Servicios"
                              isMulti
                              menuPlacement="top"
                              isOptionDisabled={handleDisabledSelection}
                              placeholder="Seleccione Lugar de Prestación de Servicios"
                              values={values.filter_branch_offices_id_only}
                              request={fetchBranchOffices}
                              onChange={data => {
                                handleNewMulti(data || [], 'filter_branch_offices_id_only', true);
                                setFieldValue(field.name, data || []);
                              }}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                      <Col sm={12} md={4} xs={12} className="mt-2">
                        <Field name="shifts">
                          {({ field }) => (
                            <InputSelect
                              {...field}
                              label="Turnos"
                              isMulti
                              isOptionDisabled={handleDisabledSelection}
                              menuPlacement="top"
                              placeholder="Seleccione Turno"
                              values={values.filter_active_shifts}
                              request={fetchShifts}
                              onChange={data => {
                                handleNewMulti(data || [], 'filter_active_shifts', true);
                                setFieldValue(field.name, data || []);
                              }}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                      <Col sm={12} md={4} xs={12} className="mt-2">
                        <Field name="job_titles">
                          {({ field }) => (
                            <InputSelect
                              {...field}
                              isMulti
                              isOptionDisabled={handleDisabledSelection}
                              menuPlacement="top"
                              placeholder="Seleccione cargo"
                              label="Cargo o Función"
                              values={values.filter_job_titles_id_only}
                              request={fetchJobTitles}
                              onChange={data => {
                                handleNewMulti(data || [], 'filter_job_titles_id_only', true);
                                setFieldValue(field.name, data || []);
                              }}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                              isArial={isArial}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Card>
      <Row className={`${onlyPreview ? 'd-flex justify-content-end' : 'd-flex'}`}>
        {onlyPreview === false && (
          <>
            <Col md={2}>
              <Button
                className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                disabled={isSubmit}
                type="submit"
                onClick={() => {
                  setFieldValue('type', '.docx');
                }}
              >
                Descargar Word
              </Button>
            </Col>
            <Col md={2}>
              <Button
                className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                disabled={isSubmit}
                type="submit"
                onClick={() => {
                  setFieldValue('type', '.xlsx');
                }}
              >
                Descargar Excel
              </Button>
            </Col>
            <Col md={2}>
              <Button
                className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                disabled={isSubmit}
                type="submit"
                onClick={() => {
                  setFieldValue('type', '.pdf');
                }}
              >
                Descargar PDF
              </Button>
            </Col>
            <Col md={2}>
              <Button
                className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
                disabled={isSubmit}
                type="submit"
                onClick={() => {
                  setFieldValue('type', '.print');
                }}
              >
                Imprimir
              </Button>
            </Col>
          </>
        )}
        <Col md={2}>
          <Button
            className={`btn-block ellipsis ${isArial ? 'arial-dt' : ''}`}
            disabled={isSubmit}
            type="submit"
            onClick={() => {
              setFieldValue('type', null);
            }}
          >
            PreVisualizar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  range_date_workday: Yup.array().rangedate(true)
});

const setInitialValues = () => {
  return {
    date_from_workday: '',
    date_to_workday: '',
    range_date_workday: [],
    employee_id: '',
    employee_ids: [],
    filter_national_id: '',
    filter_active_job_managements: [],
    filter_job_titles_id_only: [],
    filter_active_shifts: [],
    filter_branch_offices_id_only: [],
    filter_work_schedule: '',
    type: null,
    all_employee_ids: []
  };
};

const handleSubmit = (values, { props }) => {
  const allEmployeeIds = values.all_employee_ids.filter(id => id !== '');
  const { formRequest } = props;
  const filterIsds = values.employee_ids.filter(id => id !== '');
  const {
    all_employee_ids: allEmployeeIdsNoFilter,
    employee,
    employees,
    date_from: dateFrom,
    date_to: dateTo,
    ...valuesWithoutUnwantedFields
  } = values;

  const hasSelectEmployee = filterIsds.length > 0 || values.employee_id;
  formRequest({
    ...valuesWithoutUnwantedFields,
    employee_ids: hasSelectEmployee ? values.employee_ids : allEmployeeIds,
    filter_id: ''
  });
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: false
})(InformesReportFilters);
