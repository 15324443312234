import React from 'react';
import { BasicDropdown, Icon } from '../../../components';

const MassiveActionsGroup = ({ handleClick, disabled }) => {
  const actions = () => {
    const authorizedActions = [];
    /*     authorizedActions.push({
          icon: <Icon icon="pencil" className="text-warning" height="16px" width="16px" style={{ marginRight: 5 }} />,
          key: 'edit',
          props: {
            onClick: () => handleClick('edit'),
            disabled
          },
          title: 'Editar'
        }); */

    authorizedActions.push({
      key: 'destroy',
      title: `Eliminar`,
      icon: <Icon icon="trash" className="text-danger" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => handleClick('destroy'),
        disabled
      }
    });

    authorizedActions.push({
      key: 'process',
      title: `Procesar`,
      icon: <Icon icon="refresh" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => handleClick('process'),
        disabled
      }
    });

    authorizedActions.push({
      key: 'export',
      title: `Descargar`,
      icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => handleClick('export')
      }
    });

    return authorizedActions;
  };

  return (
    <div className="d-flex mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas
            <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default MassiveActionsGroup;
