import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { LongCard } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { HRAdvancePaymentsRequest } from '../../requests/dashboards';
import AdvancePaymentDataTable from '../Shared/AdvancePayment/AdvancePaymentDataTable';
import columns from '../Shared/AdvancePayment/approveColumns';

const AdvancePaymentIndex = ({ moreData, setMoreData, todayMoment = null, twoMonthsAgo = null }) => {
  const [cards, setCards] = useState({});
  const [customParams, setCustomParams] = useState({});
  const [activeCard, setActiveCard] = useState('all');
  const [defaultStartDate, setDefaultStartDate] = useState(twoMonthsAgo);
  const [defaultEndDate, setDefaultEndDate] = useState(todayMoment);
  const dispatch = useDispatch();

  const fetchDashboardCards = () => {
    const request = async () => {
      HRAdvancePaymentsRequest({
        dispatch,
        successCallback: response => setCards(camelCaseEmptyStringRecursive(response.data))
      });
    };
    request();
  };

  const handleClick = card => {
    switch (card) {
      case 'pending':
        setCustomParams({ status: 'pending' });
        setActiveCard('pending');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        setMoreData(!moreData);
        break;
      case 'year':
        setCustomParams({ status: 'approved' });
        setActiveCard('year');
        setDefaultStartDate(moment().startOf('year'));
        setDefaultEndDate(moment().endOf('year'));
        break;
      case 'rejected':
        setCustomParams({ status: 'rejected' });
        setActiveCard('rejected');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        setMoreData(!moreData);
        break;
      case 'month':
        setCustomParams({ status: 'approved' });
        setActiveCard('month');
        setDefaultStartDate(moment().startOf('month'));
        setDefaultEndDate(moment().endOf('month'));
        break;
      default:
        setCustomParams({});
        setActiveCard('all');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        setMoreData(!moreData);
        break;
    }
  };

  useEffect(fetchDashboardCards, [moreData]);

  return (
    <>
      <Row className="mt-3 mb-3">
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.all}
            label="Anticipos Solicitados"
            active={activeCard === 'all'}
            onClick={() => handleClick('all')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="warning"
            title={cards.pending}
            label="Anticipos por Autorizar"
            active={activeCard === 'pending'}
            onClick={() => handleClick('pending')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="danger"
            title={cards.rejected}
            label="Rechazados"
            active={activeCard === 'rejected'}
            onClick={() => handleClick('rejected')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.amountThisYear}
            label="Monto de Anticipos Aprobados en el Año"
            active={activeCard === 'year'}
            onClick={() => handleClick('year')}
          />
        </Col>
        <Col md={4} xs={12}>
          <LongCard
            variant="info"
            title={cards.amountThisMonth}
            label="Monto de Anticipos Aprobados en el Mes"
            active={activeCard === 'month'}
            onClick={() => handleClick('month')}
          />
        </Col>
      </Row>
      <AdvancePaymentDataTable
        columns={columns}
        customParams={customParams}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        moreData={moreData}
        setMoreData={setMoreData}
        withImport
      />
    </>
  );
};

export default AdvancePaymentIndex;
